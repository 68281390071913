import { createSlice } from "@reduxjs/toolkit";
import axios from 'axios';
import fnc from "../mymod/commFunction";

const svrUrl = process.env.REACT_APP_SERVER_URL;

export const reduxSt = createSlice({
    name: 'managementSt',//변경해도 의미 없네 (내부적으로 식별 용도인 듯)
    initialState: {
        selectedClassCode: null,
        selectedTab: 0,
        selectedDate: new Date().toISOString(),  // ISO 문자열로 저장
        isRefresh: false,
        isCoaching: null,
        attendReason: [],
       
      
    },
    
    reducers: {
        setSelectedClassCode: (state, action) => {
            state.selectedClassCode = action.payload;
        },
        setSelectedTab: (state, action) => {
            state.selectedTab = action.payload;
        },
        setSelectedDate: (state, action) => {
            state.selectedDate = action.payload;
        },
        setIsrefresh: (state, action) => {
            state.isRefresh = action.payload;
        },
        setIsCoaching: (state, action) => {
            state.isCoaching = action.payload;
        },
        setAttendReason: (state, action) => {
            state.attendReason = action.payload;
        },
      


        
     
}


});


export const { 
    setSelectedClassCode, 
    setSelectedTab,
    setSelectedDate,
    setIsrefresh,
    setIsCoaching,
    setAttendReason,
  
} = reduxSt.actions;

export default reduxSt.reducer;

