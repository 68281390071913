import React, { useRef, useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { createEraserCircle, updateEraserCircle, removeEraserCircle } from './drawFnc';
import './DrawNormal.scss';
// import './drawNormal.css';
import { useGesture } from '@use-gesture/react';

import { useSelector, useDispatch } from 'react-redux';
import { setUndo, setRedo, setClear, setSelectedRow, setSelectedRowOld, setKind, setIsCommBoxVisible,
          setIsActiveQueSolDap, setIsShowOX, fncResetSlide} from "../reduxToolkit/redux";

import fnc from "../mymod/commFunction";
import indexDb from '../indexedDB/query_autogen';

const DRAW_MODE = 'draw';
const ERASE_MODE = 'erase';
let scaleLet = 1;

const getMidPoint = (point1, point2) => ({
  x: (point1.x + point2.x) / 2,
  y: (point1.y + point2.y) / 2,
});

const DrawNormal = forwardRef(
  (
    {
      imgUrlQue,
      setAllowTouchMove,
      // isClear,
      height,
      imgSize,
      isLoadImgComplete, setIsLoadImgComplete,
     
    }, ref ) => {

    const stCode = fnc.getCookie('stCode');
    const svgRefs = useRef(null);
    const points = useRef([]);
    const colorRef = useRef("black");
    const highlightModeRef = useRef(false);
    const drawnPaths = useRef([]); // 그려진 경로를 저장할 배열
    const removedPaths = useRef([]); // 지워진 경로를 저장하기 위한 배열

    const [imgWidth, setImgWidth] = useState(600);
    const [viewBoxHeight, setViewBoxHeight] = useState(1500);
    const [scale, setScale] = useState(1 * 1);
    const scaleRef = useRef(1);
    const [device, setDevice] = useState('');
    const [animationClass, setAnimationClass] = useState('');

    const dispatch = useDispatch();

    const info = useSelector(state => state.reduxStore.info);
    const kind = useSelector(state => state.reduxStore.kind);
    // const list = useSelector(state => state.reduxStore.list);
    const selectedRow = useSelector(state => state.reduxStore.selectedRow);
    const selectedRowOld = useSelector(state => state.reduxStore.selectedRowOld);

    const currentColor = useSelector(state => state.reduxStore.currentColor);
    const highlightMode = useSelector(state => state.reduxStore.highlightMode);
    const mode = useSelector(state => state.reduxStore.mode);
    const undo = useSelector(state => state.reduxStore.undo);
    const redo = useSelector(state => state.reduxStore.redo);
    const clear = useSelector(state => state.reduxStore.clear);

    const isCommBoxVisible = useSelector(state => state.reduxStore.isCommBoxVisible);

    const isPenUsed = useRef(false);
    let currentPath;

    const deletePath = (x, y) => {
      const paths = svgRefs.current.getElementsByTagName("path");
      for (const path of paths) {
        const pathData = path.getAttribute("d");
        const pathDataArray = pathData.split(" ");

        for (let i = 1; i < pathDataArray.length - 1; i++) {
          const lineData = pathDataArray[i].split(",");
          const lineX = parseFloat(lineData[0]);
          const lineY = parseFloat(lineData[1]);

          if (Math.abs(lineX - x) < 15 && Math.abs(lineY - y) < 15) {
            removedPaths.current.push(path);
            svgRefs.current.removeChild(path);
            return;
          }
        }
      }
    };

    const handleDrawing = (clientX, clientY, type) => {
      const svg = svgRefs.current;
      const pt = svg.createSVGPoint();
      pt.x = clientX;
      pt.y = clientY;
      const svgP = pt.matrixTransform(svg.getScreenCTM().inverse());

      scaleLet = 1;
      const { x: x2, y: y2 } = svgP;
      const x = x2 / scaleLet;
      const y = y2 / scaleLet;

      if (mode === ERASE_MODE) {
        updateEraserCircle(x, y);
        deletePath(x, y);
        if (type === "start") {
          createEraserCircle(x, y, svg);
        }
        return;
      }

      if (type === "start") {
        points.current = [{ x, y }];
        currentPath = document.createElementNS("http://www.w3.org/2000/svg", "path");
        let strokeWidth = 2;
        let strokeOpacity = 1;
        if (highlightModeRef.current) {
          strokeWidth = 15;
          strokeOpacity = 0.5;
        }
        currentPath.setAttribute("stroke-width", strokeWidth);
        currentPath.setAttribute("stroke-opacity", strokeOpacity);
        currentPath.setAttribute("stroke", colorRef.current);
        currentPath.setAttribute("fill", "none");
        currentPath.setAttribute("d", `M ${x},${y}`);
        currentPath.setAttribute("stroke-linecap", "round");

        if (
          colorRef.current === "yellow" ||
          colorRef.current === "greenyellow"
        ) {
          currentPath.style.mixBlendMode = "multiply";
        }

        svg.appendChild(currentPath);
        drawnPaths.current.push(currentPath);

      } else if (type === "move") {
        points.current.push({ x, y });
        if (points.current.length < 3) {
          return;
        }

        const [point1, point2, point3] = points.current.slice(-3);
        const midPoint1Raw = getMidPoint(point1, point2);
        const midPoint2Raw = getMidPoint(point2, point3);

        const midPoint1 = {
          x: midPoint1Raw.x,
          y: midPoint1Raw.y,
        };

        const midPoint2 = {
          x: midPoint2Raw.x,
          y: midPoint2Raw.y,
        };

        const d = currentPath.getAttribute("d") || "";
        const curve = ` C ${midPoint1.x},${midPoint1.y} ${point2.x},${point2.y} ${midPoint2.x},${midPoint2.y}`;
        currentPath.setAttribute("d", `${d} ${curve}`);
      }
    };

    useEffect(() => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/android/i.test(userAgent)) {
        setDevice('Android');
      } else if (/iPad|iPhone|iPod|Mac/.test(userAgent) && !window.MSStream) {
        setDevice('iPad');
      } else {
        setDevice('Unknown');
      }
    }, []);

    useEffect(() => {
      colorRef.current = currentColor;
      highlightModeRef.current = highlightMode;
    }, [currentColor, highlightMode]);

    useEffect(() => {
      const touchStart = (e) => {
        removeEraserCircle(svgRefs.current);
        if (
          !e.touches ||
          e.touches.length === 0 ||
          e.touches[0].radiusX !== 0
        ) {
          if (e.touches.length === 1) {
            setAllowTouchMove(true);
            isPenUsed.current = false;
          } else {
            setAllowTouchMove(false);
            isPenUsed.current = true;
          }
          return;
        }

        if (e.touches[0].radiusX === 0) {
          setAllowTouchMove(false);
          isPenUsed.current = true;
          e.preventDefault();
          const { clientX, clientY } = e.touches[0];
          handleDrawing(clientX, clientY, "start");
        }
      };

      const touchMove = (e) => {
        if (!e.touches || e.touches.length === 0 || e.touches[0].radiusX !== 0) {
          return;
        }

        if (e.touches[0].radiusX === 0) {
          e.preventDefault();
          const { clientX, clientY } = e.touches[0];
          handleDrawing(clientX, clientY, "move");
        }
      };

      const touchEnd = () => {
        points.current = [];
        removeEraserCircle(svgRefs.current);

        if (isPenUsed.current) {
          fncSetSvgRedux();
        }
        isPenUsed.current = false;
      };

      const svg = svgRefs.current;
      svg.addEventListener("touchstart", touchStart);
      svg.addEventListener("touchmove", touchMove);
      svg.addEventListener("touchend", touchEnd);

      return () => {
        svg.removeEventListener("touchstart", touchStart);
        svg.removeEventListener("touchmove", touchMove);
        svg.removeEventListener("touchend", touchEnd);
      };
    }, [mode, kind]);

    useEffect(() => {
      let isDrawing = false;

      const mouseDown = (e) => {
        isDrawing = true;
        removeEraserCircle(svgRefs.current);
        handleDrawing(e.clientX, e.clientY, "start");
      };

      const mouseMove = (e) => {
        if (!isDrawing) return;
        handleDrawing(e.clientX, e.clientY, "move");
      };

      const mouseUp = () => {
        isDrawing = false;
        points.current = [];
        removeEraserCircle(svgRefs.current);
        fncSetSvgRedux();
      };

      const svg = svgRefs.current;
      svg.addEventListener("mousedown", mouseDown);
      svg.addEventListener("mousemove", mouseMove);
      svg.addEventListener("mouseup", mouseUp);

      return () => {
        svg.removeEventListener("mousedown", mouseDown);
        svg.removeEventListener("mousemove", mouseMove);
        svg.removeEventListener("mouseup", mouseUp);
      };
    }, [mode, kind]);

    const saveSVG = () => {
      const svg = svgRefs.current;
      if (!svg) return null;
      return svg.innerHTML;
    };

    useImperativeHandle(ref, () => ({
      zoomIn: () => {
        const svg = svgRefs.current;
        const currentWidth = svg.clientWidth * scale;
        if (currentWidth < window.innerWidth) {
          const newScale = Math.min(scale + 0.1, 3);
          setScale(newScale);
        }
      },
      zoomOut: () => {
        const newScale = Math.max(scale - 0.1, 0.5);
        setScale(newScale);
      },
    }));

    const handleClick = () => {};

    useEffect(() => {
      const svg = svgRefs.current;
      svg.innerHTML = "";


      if (imgSize) {
        const imgSizeX = imgSize.split(',')[0];
        const imgSizeY = imgSize.split(',')[1];
        if (imgSizeY) {

          setViewBoxHeight(imgSizeY * imgWidth / imgSizeX + 1000);
          // setViewBoxHeight('11400');
        }
      } else {
        setViewBoxHeight('1400');
      }

      if (!imgUrlQue || !imgUrlQue.imgPathQue) return;
      const imgUrlPath = imgUrlQue.imgPathQue;
      const no = imgUrlQue.selectedList['no'];
      const testCode = imgUrlQue.selectedList['시험지코드'];
      const stCode = fnc.getCookie('stCode');
      const fileName_comm = imgUrlQue.selectedList['공통지문'];
      let munCode = imgUrlQue.selectedList['문항번호'];
      if (kind === 'comm') {//@국어_테스트_01_00001.hwp (공통지문은 모두 동기화 돼야)
        munCode = fileName_comm;
      }

      indexDb.selectSvg({ stCode, testCode, munCode, kind }, (err, res) => {
        if (res && res.length > 0 && res[0].svgData) {
          svg.innerHTML = res[0].svgData;
          setIsLoadImgComplete(true);
          return null;
        }
      });

      const img = document.createElementNS("http://www.w3.org/2000/svg", "image");
      img.setAttributeNS(null, "x", "0");
      img.setAttributeNS(null, "y", "20");
      img.setAttributeNS(null, "width", `${imgWidth}`);
      img.setAttributeNS("http://www.w3.org/1999/xlink", "xlink:href", imgUrlPath);
      svg.appendChild(img);
      setIsLoadImgComplete(true);
    }, []);

    function fncSetSvgRedux() {
      const no = imgUrlQue.selectedList['no'];
      const testCode = imgUrlQue.selectedList['시험지코드'];
      const stCode = fnc.getCookie('stCode');
      const fileName_comm = imgUrlQue.selectedList['공통지문'];
      const svgData = saveSVG();

      let munCode = imgUrlQue.selectedList['문항번호'];

      if (kind === 'comm') {
        munCode = fileName_comm;
      }

      if ((svgData && stCode && kind)) {
        indexDb.upsertSvg({ testCode, stCode, munCode, kind, svgData, no });
      }
    }

    const [startX, setStartX] = useState(null);

    const handleTouchStart = (e) => {
      setStartX(e.touches[0].clientX);
    }

    const handleTouchEnd = (e) => {
      const touch = e.changedTouches[0];
      if (touch && touch.radiusX === 0) return;

      const endX = touch.clientX;
      if (startX - endX > 100) {
        slideToRightFunction();
      } else if (endX - startX > 100) {
        slideToLeftFunction();
      }
    }

    //-----------------------------------------------------문제 슬라이드
    useEffect(() => {
      // console.log(selectedRowOld, selectedRow,'selectedRowOld - selectedRow');
      handlePrevNext(selectedRowOld - selectedRow);
      // console.log(isClear, 'isClear');
    }, []);//isClear 삭제 함

    const slideToRightFunction = () => {//오른쪽으로 이동
      dispatch(fncResetSlide());//공통지문에서 문제로 초기화
      const maxRows = info.munCnt;
      if (selectedRow === maxRows) {
        alert('마지막입니다.');
        return;
      }
      if (!isLoadImgComplete) return;

      dispatch(setSelectedRowOld(selectedRow));
      dispatch(setSelectedRow(selectedRow + 1));
    }

    const slideToLeftFunction = () => {//왼쪽으로 이동
      dispatch(fncResetSlide());//공통지문에서 문제로 초기화

      if (selectedRow === 1) {
        alert('처음입니다.');
        return;
      }
      if (!isLoadImgComplete) return;
      dispatch(setSelectedRowOld(selectedRow));
      dispatch(setSelectedRow(selectedRow - 1));
    }

    // function fncResetSlide() {//redux로 옮김?
    //   dispatch(setKind('que'));
    //   dispatch(setIsActiveQueSolDap('que'));
    //   dispatch(setIsCommBoxVisible(false));
    //   dispatch(setIsShowOX(false));
    // }

    const handlePrevNext = (direction) => {
      if (!direction) return;
      setAnimationClass(direction > 0 ? 'slide-in-left2' : 'slide-in-right2');
      setTimeout(() => setAnimationClass(''), 500);
    };

    const vw = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    );
    const maxScale = vw / imgWidth;

    const bind = useGesture({
      onPinch: ({ offset: [d, a] }) => {
        const clampedScale = Math.max(1, Math.min(d, maxScale));
        setScale(clampedScale);
      },
      onDoubleClick: () => {
        if (scale === maxScale) {
          setScale(1);
        } else {
          setScale(maxScale);
        }
      },
    });

    const style = {
      height: height || 'auto',
    };

    const style2 = {
      overflow: 'auto',
      WebkitOverflowScrolling: 'touch'
    };

    function fncUndo() {
      const paths = svgRefs.current.querySelectorAll("path");
      if (paths.length > 0) {
        const latestPath = paths[paths.length - 1];
        removedPaths.current.push(latestPath);
        svgRefs.current.removeChild(latestPath);
      }
    }

    function fncRedo() {
      if (removedPaths.current.length > 0) {
        const latestRemovedPath = removedPaths.current.pop();
        svgRefs.current.appendChild(latestRemovedPath);
      }
    }

    function fncClear() {
      const rtn = window.confirm(
        "메모 전체를 지우시겠습니까? \n(배경 내용은 유지됩니다.)"
      );
      if (!rtn) return;
      const paths = svgRefs.current.getElementsByTagName("path");
      while (paths.length > 0) {
        svgRefs.current.removeChild(paths[0]);
      }
    }

    if (undo) {
      fncUndo();
      dispatch(setUndo(false));
    }

    if (redo) {
      fncRedo();
      dispatch(setRedo(false));
    }

    if (clear) {
      fncDeleteAllSvg();
      dispatch(setClear(false));
    }

    function fncDeleteAllSvg() {
      const no = imgUrlQue.selectedList['no'];
   
      const testCode = imgUrlQue.selectedList['시험지코드'];
      const stCode = fnc.getCookie('stCode');
      const fileName_comm = imgUrlQue.selectedList['공통지문'];
      let munCode = imgUrlQue.selectedList['문항번호'];
      if (kind === 'comm') {//공통지문은 모두 같이 동기화 돼야
        munCode = fileName_comm;
      }

      if (!munCode || !testCode || !stCode) return;

      indexDb.deleteSvg({ stCode, testCode, munCode, kind }, (response, err) => {
        if (response.result > 0) {
          fncClear();
        } else {
          console.error("Error in callback:", err);
        }
      });
    }
    //=====================================================================================================
    return (
      <div className="drawNormal" style={style} onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
        <div className={`main_inner ${animationClass}`}>
          <div className="box-svg" style={style2}>
            <svg
              {...bind()}
              onClick={handleClick}
              style={{
                position: 'absolute',
                left: '50%',
                transform: 'translateX(-50%) scale(' + scale + ')',
                transformOrigin: '50% 0',
                width: imgWidth,
                height: viewBoxHeight,
              }}
              ref={svgRefs}
              viewBox={`0 0 ${imgWidth} ${viewBoxHeight}`}
            ></svg>
          </div>
        </div>
      </div>
    );
  }
);

export default React.memo(DrawNormal);
