import { createSlice } from "@reduxjs/toolkit";

export const reduxVideo = createSlice({
    name: 'name_reduxVideo',//변경해도 의미 없네 (내부적으로 식별 용도인 듯)
    initialState: {
        selectedVideoGroupList: {},
        selectedVideoList: {},
        selectedKwamokCode: '',
        selectedStudyStepCode: '',
        version: '',
        boardTitle:{},
        boardKind: '',
        selectedList: {},
        isAdmin: false,
        authority: null,
        isGangsa: false,

        initialPage: 0,
        isAccorddionExpend: true,
        isCurrentItemRefresh: false,
        // isCurrentItemRefresh_faq: false,
        isAnoymous: false,

    },
    
    reducers: {    
        setSelectedVideoGroupList: (state, action) => {
            state.selectedVideoGroupList = action.payload;
        },
        setSelectedVideoList: (state, action) => {
            state.selectedVideoList = action.payload;
        },
        setSelectedKwamokCode: (state, action) => {
            state.selectedKwamokCode = action.payload;
        },
        setSelectedStudyStepCode: (state, action) => {
            state.selectedStudyStepCode = action.payload;
        },
        setVersion: (state, action) => {
            state.version = action.payload;
        },
        setBoardTitle: (state, action) => {
            state.boardTitle = action.payload;
        },
        setBoardKind: (state, action) => {
            state.boardKind = action.payload;
        },
        setSelectedList: (state, action) => {
            state.selectedList = action.payload;
        },
        setIsAdmin: (state, action) => {
            state.isAdmin = action.payload;
        },
        setInitialPage: (state, action) => {
            state.initialPage = action.payload;
        },
        setIsAccorddionExpend: (state, action) => {
            state.isAccorddionExpend = action.payload;
        },
        setIsCurrentItemRefresh: (state, action) => {
            state.isCurrentItemRefresh = action.payload;
        },
        // setIsCurrentItemRefresh_faq: (state, action) => {//사용 안 해 하나로 한다. 마운트 해제돼서 상관 없을 듯
        //     state.isCurrentItemRefresh_faq = action.payload;
        // },
        setIsAnonymous: (state, action) => {
            state.isAnoymous = action.payload;
        },
        setAuthority: (state, action) => {
            state.authority = action.payload;
        },
        setIsGangsa: (state, action) => {
            state.isGangsa = action.payload;
        },
    }


});




export const { 
    setSelectedVideoGroupList,
    setSelectedVideoList,
    setSelectedKwamokCode,setSelectedStudyStepCode,
    setVersion,
    setBoardTitle, 
    setBoardKind,
    setSelectedList,
    setIsAdmin, setAuthority, setIsGangsa,
    setInitialPage,
    setIsAccorddionExpend,
    setIsCurrentItemRefresh,
    // setIsCurrentItemRefresh_faq,//본문 update(수정)하면 부모의 부모 새로 list 불러오기
    setIsAnonymous,
    
       
} = reduxVideo.actions;

export default reduxVideo.reducer;

