import styles from "./markingTable.scss"; 
import axios from "axios";
import Button from "react-bootstrap/Button";
import React, { useEffect, useState, memo} from "react";
import { useLocation } from "react-router-dom";
// import { animated, useSpring } from "react-spring";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';


const svrUrl = process.env.REACT_APP_SERVER_URL;

const MarkingTable = (({ 
  isShowList, 
  list, 
  markingList, 
  selectedRow,
  setList, 
  setSelectedRow, 
  setNo, 
  setInfo,
  markingNum, setMarkingNum, }) => {

  const location = useLocation();
  const testCodeMun = location.state?.testCodeMun;
  const testCode = location.state?.testCode;
  const [listTable, setListTable] = useState(null);

  console.log("2-----------------MarkingTable" );

  useEffect(() => {
    fncSelectList(); 
    // setIsShowList=false;
   
  }, []);

  function fncSelectList() {

    sendAxios("/markingStList", { testCodeMun, testCode }, (response) => {
      if (response) {  
        setList(response); 
      } else {
        console.log("속성이 obj 내에 없습니다.");
      }
    });
  }


  

function fncSelectRow(item) {
  if (!item) return false;
    setSelectedRow(item.no);
    setNo(item.no + '번');
    setInfo(markingList[item.no]);
    setMarkingNum(markingList[item.no]);

}
  
  const sendAxios = async (url, data, callback) => {
    try {
      const response = await axios.post(`${svrUrl}${url}`, data);
      let jsonObj = response.data;
      callback(jsonObj);
    } catch (error) {
      console.error("Axios 요청 중 오류가 발생했습니다:", error);
    }
  };


//========================================================================= return
return (
  <div>
    {isShowList && (
      <div className="MarkingTable">
        <div className="wrap-list-markingSt">
          <table>
            <thead>
              <tr>
                <th style={{ width: "5rem" }}>No</th>
                <th style={{ width: "auto" }}>마킹</th>
              </tr>
            </thead>
            <tbody>
              {list &&
                list.map((item, index) => (

                  <tr
                  className={`row-list ${selectedRow === item.no ? "selected activeRow" : ""}`}
                  key={item.no}
                  onClick={() => fncSelectRow(item)}
                >
                  <td>{item.no}</td>
                  <td style={{textAlign: 'left', paddingLeft: '1rem'}}>{markingList[item.no] || ""}</td>
                </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    )}
  </div>
);
});

export default MarkingTable;