import './markingTitleBar.scss';
import axios from "axios";
import Button from "react-bootstrap/Button";
import React, { useEffect, useState, useCallback } from "react";
import MarkingMenuSpecial from "./MarkingMenuSpecial";
import { useNavigate } from "react-router-dom";
import MemoCanvasApp from "../drawCanvas/MemoCanvasApp";
import { useRef } from "react";

function MarkingTitleBar({ 
  no, 
  info,
  selectedList,
  testNm,
  showModal,setShowModal,
  fncPrev, 
  fncNext,
  slideHandlePrevNext, setSlideHandlePrevNext,
  setIsShowList,
  selectedRow,
  onMarkingNumber,
  onMarkingInput,
  isStudy,
  markingNum, setMarkingNum,

}) 
{
  // const navigate = useNavigate();

  const [kind, setKind] = useState('que');
  const [save, setSave] = useState(null);
  const [isActiveQue, setIsActiveQue] = useState(false);
  const [isActiveSol, setIsActiveSol] = useState(false);
  const [isBtnCloseDisabled, setIsBtnCloseDisabled] = useState(null);
  const [inputValue, setInputValue] = useState(''); 
  const [isShowSpecial, setIsShowSpecial] = useState(false);
  const [OX, setOX] = useState();
  const memoCanvasAppRef = useRef();
  
  //------------------------------------------------------------------

  useEffect(() => {
    if (selectedList) { // selectedList가 존재하는 경우에만 setOX를 호출
      setOX(selectedList['OX']);
    } else {
    
      setOX(null); // 예시: selectedList가 없을 때 OX를 null로 설정
    }
  }, [selectedList]);


  useEffect(() => {//이거 필요한 가?
 
    setInputValue(markingNum);
   
  }, [markingNum]);



  // function fncShowMemo() {
  //     setShowModal(true);
  // }

  // function fncKindQue() {
  //   fncSelectQueSol('que');
  // }

  // function fncKindSol() {
  //   fncSelectQueSol('sol');
  // }

  // function fncSave() {
  //   setSave(true);
  //   setIsBtnCloseDisabled(true);//닫기가 렉걸려서

  // }

  

  // function fncPrevMemo() {
  //   fncPrev();
  // }


  // function fncNextMemo() {
  //   fncNext();
  // }


  // function fncOnMarkingInput() {
  //   onMarkingInput(selectedRow, inputValue);
  //   setMarkingNum(inputValue);
  //   // setInputValue(''); 왜 2번 클릭해야 지워지나?
  //   setTimeout(() => {
  //     setInputValue('');
  //   }, 100)
  // }

//   const fncInputValueChange = useCallback((e) => {
//     setInputValue(e.target.value);
// }, []); // 의존성 배열에 아무것도 넣지 않아서 함수는 한 번만 생성되고 메모이제이션됩니다.



// const fncSelectQueSol = (kindValue) => {
//   memoCanvasAppRef.current.fncSelectQueSol(kindValue);
//   setKind(kindValue);//이걸 안해서 문풀 버튼 클릭 시 꼬였다 (고생 함)
// }


// function fncClose() {
//   fncSelectQueSol(kind);//닫기 전에 저장
//   setShowModal(false);
// }

// const toggleSpecial = () => {
//   setIsShowSpecial(prevState => !prevState);
// };

const fncNoBgColorClassName = (OX) => {
  if (OX === 'O') return 'bg-green';
  if (OX === 'X') return 'bg-red';
  return '';
};

  return (
    <div className="MarkingTitleBar">
      <div className="section_number">
        <div className="article_number">
          <span className={`lbl_number ${fncNoBgColorClassName(OX)}`}>
            {no}
          </span>
          <span className="lbl_marking_info">{markingNum}</span>
        </div>
      </div>
    </div>
  );
}

export default MarkingTitleBar;
