import './ManagementSt_sms_report_exam.scss';

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import fnc from '../mymod/commFunction';
import { useDispatch, useSelector } from 'react-redux';
import ComboboxMui from '../comm/ComboboxMui';
import { Button } from '@mui/material';

import CustomSpinner from '../comm/CustomSpinner';
import { toPng } from 'html-to-image';

const ManagementSt_sms_report_exam = ({selectedItem, lessonDate}) => {

    const [list, setList] = useState([]);
    const [startDate, setStartDate] = useState(null); 
    const [endDate, setEndDate] = useState(null); 

    const selectedClassCode = useSelector(state => state.reduxStore_st.selectedClassCode);
    const classCode = selectedClassCode ? selectedClassCode.classCode : '';//selectedClassCode 이거 객체로 사용
    const stCode = selectedItem.stCode;
      
    useEffect(() => {
        if(lessonDate){
          const { startDate, endDate } = fnc.getStartAndEndDateOfWeekObj(lessonDate);
          setStartDate(startDate);
          setEndDate(endDate);
        }
    }, [classCode, lessonDate]); // startDate가 변경될 때만 호출

    useEffect(() => {
      if (stCode && startDate && endDate) {
        fncSelect_stList(stCode, startDate, endDate);
      }
    }, [stCode, startDate, endDate]);


    function fncSelect_stList( stCode, startDate, endDate) {
    
      startDate = fnc.formatDate(startDate);
      endDate = fnc.formatDate(endDate);

      fnc.sendAxios('/select/manageMentSt/smsReport/examResult', { stCode, startDate, endDate }, (res) => {
        if (res) {  
              setList(getRecords_stList(res));
        } else {
              console.log(res,"managementStRoutes/select/manageMentSt/smsReport/examResult");
        }
      });
    }

    function getRecords_stList(res) {
      let records = [];
      res.forEach(item => {
          const classCode = item["반코드"];
          const className = item["반명"];
          const examName = item["시험지명"];
          const examKind = item["시험종류"];
          const gangsaName = item["담임"];
          const stCode = item["학생고유번호"];
          const stName = item["성명"];
          const munCnt = item["문항수"];
          const score = item["점수"];
          const avg = item["평균"];
          const rank = item["석차"];
          const SD = item["SD"];
          const Ocnt = item["맞음"];
          const Tcnt = item["문항수"];
          const stCnt = item["총원"];
          const videoFileName = item["videoFileName"];
          const listenCnt = item["listenCnt"];
          const examData = item["시험일자"];
          const listenResult = !videoFileName ? '없음' : listenCnt ? '완료' : '미완료';
          
          records.push({
            classCode, className, examName, examKind, gangsaName, stCode, stName, munCnt, score, avg,
            rank, SD, Ocnt, Tcnt, stCnt, videoFileName, listenCnt, examData, listenResult
          });
          
      });
      return records;
  }
  
  

  //===================================================
  return (
      <div className="ManagementSt_sms_report_exam">
          {list.length > 0 &&  <p className='title'>진단평가</p> }
          {list.length > 0 && <div className='wrap-notice'>
       
          {list && list.map((item, index) => (
              <section className='section-list'>

                  <article className='article-homework'>
                        <div className='jindo row'><span>시험명</span> <p>{item.examName}</p>  </div>
                  </article>

                  <article className='article-title row'>
                        <div className='lessonDate'><span>시험일</span> <p>{item.examData}</p></div>
                        <div className='className'><span>반명</span> <p>{item.className}</p> </div>
                  </article>

                  <article className='article-title row'>
                        <div className='lessonDate'><span>문항수</span> <p>{item.Tcnt}문항</p></div>
                        <div className='className'><span>영상시청</span> <p>{item.listenResult}</p> </div>
                  </article>

                 

                  <article className='article-title row'>
                        <div className='lessonDate'><span>점수</span> <p>{item.score}</p></div>
                        <div className='className'><span>석차</span> <p>{item.rank}/{item.stCnt}</p> </div>
                        <div className='className'><span>평균</span> <p>{item.avg}</p> </div>
                        <div className='className'><span>SD</span> <p>{item.SD}</p> </div>
                  </article>

                 

              </section>

          ))}
        </div>}
      </div>
  );

//===========================================================================
  
}

export default ManagementSt_sms_report_exam;
