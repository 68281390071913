import './ReservationApp.scss';
import * as S from '../scss/theme';
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";

import Checkbox from '@mui/material/Checkbox';

import Listbox from '../comm/Listbox';
import fnc from '../mymod/commFunction';
import Header from '../Header';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import ReservationMain from './ReservationMain';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedClassCode, setSelectedTab} from  '../reduxToolkit/reduxSt';
import TableMui from '../comm/TableMui';
import { Button } from '@mui/material';

const ReservationApp = () => {
    const navigate = useNavigate(); 
    const dispatch = useDispatch();
    const [list, setList] = useState([]);
    const [classList, setClassList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    
    const selectedTab = useSelector(state => state.reduxStore_st.selectedTab);

    useEffect(() => {
      dispatch(setSelectedTab(0));//초기화
      const stCode = fnc.getCookie('stCode');
      fncSelect_classList(stCode)
    }, []);

  

  const handleSelect_class = (item) => {
    // dispatch(setSelectedClassCode(item.key));
    dispatch(setSelectedClassCode(item));
    // console.log(item, 'item');
  };


  
  
  function fncSelect_classList(stCode) {
    fnc.sendAxios('/select/managementSt_reservation/classListBySt', { stCode }, (res) => {
        if (res) {  
            setClassList(getRecords_classList(res));
      } else {
            console.log(res,"select/managementSt_reservation/classListBySt");
      }
    });
}


function getRecords_classList(res) {
    let records = [];
    for (let i = 0; i < res.length; i++) {    
        const key = res[i]["classCode"];  
        let name= res[i]["className"];    
        const gangsaCode= res[i]["gangsaCode"];  
        const gangsaName= res[i]["gangsaName"];  
        const type= res[i]["type"];    

        const classCode= res[i]["classCode"]; 
        const className= res[i]["className"]; 
         
        name = `${name} - ${gangsaName}`; 
        records.push({ key, name, classCode, className, gangsaCode, gangsaName, type });
    }
    return records;
  }

  
function fncTestClose() {

  navigate(-1);

  // const confirmed = window.confirm('학생관리를 종료하시겠습니까?');
  // if (!confirmed) return

  // setTimeout(() => {
  //   navigate(-1);
  // }, 200); 


}

  //===================================================
  return (
    <div className='ReservationApp'>
<S.Section>
      <div className="header-container">
        <Header onBackClick={() => fncTestClose() } title={'코칭 예약'} />
      </div>
        
      <section className='section-gangsa-class'>

        <article className='article-class'>
          <p className='listTitle'>수강반 (코칭)</p>
          <Listbox items={classList} onSelect={handleSelect_class} /> 
        </article>

      </section>
 
      <section className='section-main'>

          <Tabs selectedIndex={selectedTab} onSelect={(index) => dispatch(setSelectedTab(index))}>

              <TabList className='tabList'>
                  <Tab>코칭 예약</Tab>
              </TabList>

              <TabPanel className='panel-reservationMain'>
                  <ReservationMain />          
              </TabPanel>

          </Tabs>

      </section>

      </S.Section>

    </div>  
  );


}

export default ReservationApp;
