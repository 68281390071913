
import './BoardNotify.scss';
import React, {useEffect, useState} from 'react';
import fnc from '../mymod/commFunction';
import Header from '../Header';
import { Button } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
// import { index } from 'd3';


const BoardNotify = ({setIsModal, item}) => {
    
    const isAnoymous = useSelector(state => state.reduxStore_video.isAnoymous);
    const [listNotify, setListNotify] = useState([]);
    const [selectedNotifyIdx, setSelectedNotifyIdx] = useState('');

    useEffect(() => {
        fncSelect_Board_comment_notify_table();
        // console.log(item,'----------------------------');
    }, []);


    function fncSelect_Board_comment_notify_table() {
        fnc.sendAxios('/select_board_comment_notify_table', { }, (res) => {
            if (res) {
                setListNotify(res);
                // console.log(res,'----------------------------');
            }
        });
    }

    function handleReport(notify, index) {
        setSelectedNotifyIdx(index); // 선택된 신고 사유의 sno를 저장
        // console.log(`Reported for: ${notify.code} - ${notify.content}`);
        // 여기에 신고 처리 로직을 추가할 수 있습니다.
        // console.log(item,'----------------------------');
        // const stCode = item.stCode;
        // const parentCode = item.code;
        // const notifyCode = notify.code;
        // const notifyContent = notify.content;

        // fncInsertNotify(stCode, parentCode, notifyCode, notifyContent)
    }

    function fncPre() {
        setIsModal(false);
    }
    
    function fncInsertNotify(){
        const stCode = item.stCode;
        const parentCode = item.code;
        const notifyCode = listNotify[selectedNotifyIdx].code;
        const notifyContent = listNotify[selectedNotifyIdx].content;

        const rtn = window.confirm('게시글을 신고하시겠습니까?');
        if(!rtn) return false;
        
        fnc.sendAxios('/insert_board_comment_notify',{stCode, parentCode, notifyCode, notifyContent}, (res) => {
            if (res) {
                if (res.success === 'exist') {
                    alert('이미 신고된 글입니다.');
                } else if (res.success === true) {
                    alert('완료되었습니다.');
                    fncPre();
                }
                // console.log(res,'----------------------------');
            }
        });
    }
    //====================================
    return (
        <div className='BoardNotify'>
             <Header onBackClick={fncPre} title='신고하기' drection={1} />
            <div className='inner-boardNotify'>
                <div className='box-content'>
                    <p><label>작성자</label><span> { isAnoymous ? '****' : item.stName}</span> </p>
                    <p><label>내  용</label><span>{item.content}</span></p>
                </div>

                <div className='options'>
                    <ul>
                        {listNotify.map((notify, index) => (
                            <li key={index} className={selectedNotifyIdx === index ? 'selected' : ''} onClick={() => handleReport(notify, index)}>
                                <span className="checkmark">
                                    {selectedNotifyIdx === index ? <i className="fa fa-check"></i> : ''}
                                </span>
                                {notify.content}
                            </li>
                        ))}
                    </ul>

                    <div className='box-menu-notify'>
                        <Button onClick={fncInsertNotify}>제출</Button>
                    </div>
                
                </div>

               
            </div>

        </div>
    );
}

export default BoardNotify;