import './ManagementSt_sms_report_homework.scss';

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import fnc from '../mymod/commFunction';
import { useDispatch, useSelector } from 'react-redux';
import ComboboxMui from '../comm/ComboboxMui';
import { Button } from '@mui/material';

import CustomSpinner from '../comm/CustomSpinner';
import { toPng } from 'html-to-image';

const ManagementSt_sms_report_homework = ({selectedItem, lessonDate, kind}) => {

    const [list, setList] = useState([]);
    const [startDate, setStartDate] = useState(null); 
    const [endDate, setEndDate] = useState(null); 

    const selectedClassCode = useSelector(state => state.reduxStore_st.selectedClassCode);
    const classCode = selectedClassCode ? selectedClassCode.classCode : '';//selectedClassCode 이거 객체로 사용
    const stCode = selectedItem.stCode;
      
    useEffect(() => {
        if(lessonDate){
          const { startDate, endDate } = fnc.getStartAndEndDateOfWeekObj(lessonDate);
          setStartDate(startDate);
          setEndDate(endDate);
        }
    }, [classCode, lessonDate]); // startDate가 변경될 때만 호출

    useEffect(() => {
      if (stCode && startDate && endDate) {
        fncSelect_stList(stCode, startDate, endDate);
      }
    }, [stCode, startDate, endDate]);


    function fncSelect_stList( stCode, startDate, endDate) {
    
      startDate = fnc.formatDate(startDate);
      endDate = fnc.formatDate(endDate);

      fnc.sendAxios('/select/manageMentSt/smsReport/homeworkResult', { stCode, startDate, endDate, kind }, (res) => {
        if (res) {  

          console.log(res, '평가');
              setList(getRecords_stList(res));
        } else {
              console.log(res,"managementStRoutes/select/manageMentSt/smsReport/homeworkResult");
        }
      });
    }

    function getRecords_stList(res) {
      let records = [];
      res.forEach(item => {
          const classCode = item["classCode"];
          const className = item["className"];
          const gangsaCode = item["gangsaCode"];
          const gangsaName = item["gangsaName"];
          const stCode = item["stCode"];
          const stName = item["stName"];
          const school = item["school"];
          const hp = item["hp"];
          const parentHp = item["parentHp"];
          const jindo = item["jindo"];
          const homework = item["homework"];

          const lessonDate = item["lessonDate"];
          const evaluation = item["evaluation"];
          const evaluationYn = item["evaluationYn"];
          
          records.push({ classCode, className, gangsaCode, gangsaName, stCode, stName, school, hp, parentHp,
            lessonDate, jindo, homework, evaluation, evaluationYn });
      });
      return records;
  }
  
  

  //===================================================
  return (
      <div className="ManagementSt_sms_report_homework">
         {list.length > 0 && <p className='title'>{`진도 및 과제 (${kind} 수업) `}</p> }
         {list.length > 0 && <div className='wrap-notice'>
          {list && list.map((item, index) => (
              <section className='section-list'>

                  <article className='article-title row'>
                        <div className='lessonDate'><span>수업일</span> <p>{item.lessonDate}</p></div>
                        <div className='className'><span>반명</span> <p>{item.className}</p> </div>
                  </article>

                  <article className='article-homework'>
                        <div className='jindo row'><span>진도</span> <p>{item.jindo}</p>  </div>
                      {item.homework &&  <div className='homework row'><span>과제</span> <p>{item.homework}</p>  </div> }
                      {item.homework &&  <div className='evaluation row'><span>과제수행</span> <p>{item.evaluation}</p></div> }
                  </article>
              </section>

          ))}
        </div>}
      </div>
  );

//===========================================================================
  
}

export default ManagementSt_sms_report_homework;
