import './ManagementSt_sms_report_notice.scss';

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import fnc from '../mymod/commFunction';
import { useDispatch, useSelector } from 'react-redux';
import ComboboxMui from '../comm/ComboboxMui';
import { Button } from '@mui/material';
import { setIsrefresh } from  '../reduxToolkit/reduxSt';


const ManagementSt_sms_report_notice = ({lessonWeek, classGrade, kind}) => {//kind:학년별, 반별, 학생별

    const isRefresh = useSelector(state => state.reduxStore_st.isRefresh);
    const [list, setList] = useState([]);

    useEffect(() => {
      
      if (lessonWeek && classGrade) {
        fncSelect_list_bygrade(lessonWeek, classGrade);
      }
  
    }, [lessonWeek, classGrade, isRefresh]);


    function fncSelect_list_bygrade( lessonWeek, code) {

      fnc.sendAxios('/select/managementSt/allimjang_notice', { lessonWeek, code }, (res) => {
        if (res) {  
              setList(getRecords_list(res));
        } else {
              console.log(res,"managementStRoutes/select/managementSt/allimjang_notice");
        }
      });
    }

    function getRecords_list(res) {
      let records = [];
      if (!Array.isArray(res)) return records;

      res.forEach(item => {
          const sno = item["sno"];
          const lessonWeek = item["lessonWeek"];
          const code = item["code"];
          const codeName = item["codeName"];
          const title = item["title"];
          const content = item["content"];
          const eDate = item["eDate"];
         
          records.push({
            sno, lessonWeek, code, codeName, title, content,  eDate
          });
          
      });
      return records;
  }
  
  

  //===================================================
  return (
   <div className="ManagementSt_sms_report_notice">
      
      {list.length > 0 && <div className='wrap-notice'>
            <p className='title'>{`${kind} 공지사항`}</p>

            {list && list.map((item, index) => (
                  
            <section className='section-notice'>
                <article className='article-notice'>
                      <div className='title'> <p>{item.title}</p>  </div>
                      <div className='content'> <p>{item.content}</p>  </div>
                </article>
            </section>

        ))}
      </div>}

        
      </div>
   
  );

//===========================================================================
  
}

export default ManagementSt_sms_report_notice;
