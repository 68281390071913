
import './Login.scss';
import Button from '@mui/material/Button';
// import Checkbox from '@mui/material/Checkbox';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import { Box, FormControlLabel, Checkbox } from '@mui/material';
// import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';


import axios from 'axios';
import React, { useState, useEffect  } from 'react';
import { useNavigate } from 'react-router-dom';
import imgLogo from '../images/gamja/logo-kr-traspent2.png';
import fnc from '../mymod/commFunction';
import { useSelector, useDispatch } from 'react-redux';
import { setIsAdmin, setAuthority, setIsGangsa } from '../reduxToolkit/reduxVideo';
// import MyBtn from '../mymod/Button';

const svrUrl = process.env.REACT_APP_SERVER_URL;


let acaDbName;


// console.log(svrUrl,'-------------------------------svrUrl');
function Login() {
    const [certCodeSt, setCertCodeSt] = useState('');
    const [id, setId] = useState('');
    const [pw, setPw] = useState('');
    const [appVersion, setAppVersion] = useState(''); // 앱 버전 상태

    const [isAutoLoginChecked, setAutoLoginChecked] = useState(false);
    const [isIdChecked, setIdChecked] = useState(false);
    const navigate = useNavigate();  // 여기서 useNavigate hook을 사용합니다.

    const version = useSelector(state => state.reduxStore_video.version);
    const dispatch = useDispatch();


    useEffect(() => {
      restoreCookie();//왜 2번 했었지?
    //window.addEventListener("sendData", handleDataEvent); 이방식 포기(안 됨)
      setAppVersion(version);//초기값 공백으로 해야하고, [version] 붙여야함 안드로이드에서 못 읽어와
    }, [version]);

   
    //----------------------------------------------------------
    function loginClick() {
      if (!certCodeSt) return alert('인증코드를 입력하세요!');
      if (!id) return alert('아이디를 입력하세요!');
      if (!pw) return alert('패쓰워드를 입력하세요!');

      fncInfoAca();
    }

    function loginClickTec() {
      if (!certCodeSt) return alert('인증코드를 입력하세요!');
      if (!id) return alert('아이디를 입력하세요!');
      if (!pw) return alert('패쓰워드를 입력하세요!');

      fncInfoAcaTec();
    }


    function fncInfoAca() {

      sendAxios('/api/infoAca', { certCodeSt }, (response) => {

        if (response && Array.isArray(response) && response[0] && response[0].acaDbName) {
         
          acaDbName = response[0].acaDbName;
          let acaCode = response[0].acaCode;
          let imageWebPath = response[0].imageWebPath;
    
          fnc.setCookie('acaDbName', acaDbName, 1000);
          fnc.setCookie('acaCode', acaCode, 1000);
          fnc.setCookie('imageWebPath', imageWebPath, 1000);
    
    
          fncLogin();//로그인 실행

        } else {
          alert('학원코드 정보가 올바르지 않습니다.');
        }
      });
    }

    
    function fncInfoAcaTec() {

      sendAxios('/api/infoAca', { certCodeSt }, (response) => {

        if (response && Array.isArray(response) && response[0] && response[0].acaDbName) {
         
          acaDbName = response[0].acaDbName;
          let acaCode = response[0].acaCode;
          let imageWebPath = response[0].imageWebPath;
    
          fnc.setCookie('acaDbName', acaDbName, 1000);
          fnc.setCookie('acaCode', acaCode, 1000);
          fnc.setCookie('imageWebPath', imageWebPath, 1000);
    
    
          fncLoginTec();//로그인 실행 (선생님)

        } else {
          alert('학원코드 정보가 올바르지 않습니다.');
        }
      });
    }


    function fncLogin() {
      sendAxios('/api/login', { id, pw }, (response) => {
        const parsedData = JSON.parse(response.data);//이걸 해야하네

        if (parsedData && Array.isArray(parsedData) && parsedData[0].stCode) {
          const stCode = parsedData[0].stCode;
          const stName = parsedData[0].stName;
          const token = response.token;
          saveCookie(id, pw, stCode, stName);
          fnc.setCookie('token', token, 1);
          if(id === '01000000000') {
            dispatch(setIsAdmin(true));
          }else{
            dispatch(setIsAdmin(false));//기본이 false인데 이상하게 이거 안 하면  true로 된다.
          }
         
          dispatch(setAuthority(null));
          dispatch(setIsGangsa(false));

          navigate("/menu")
        } else {
          alert('아이디 또는 패쓰워드 정보가 올바르지 않습니다.');
        }
      });
    }

    
    function fncLoginTec() {
      sendAxios('/api/loginTec', { id, pw }, (response) => {
        const parsedData = JSON.parse(response.data);//이걸 해야하네

        if (parsedData && Array.isArray(parsedData) && parsedData[0].stCode) {

          const stCode = parsedData[0].stCode+'';//문자열로 변경해야 하네
          const authority = parsedData[0].authority+'';
          const token = response.token;
   
          saveCookie(id, pw, stCode);
          fnc.setCookie('token', token, 1);

          dispatch(setAuthority(authority));
          dispatch(setIsGangsa(true));
         
          navigate("/menu")
        } else {
          alert('아이디 또는 패쓰워드 정보가 올바르지 않습니다.');
        }
      });
    }
    
    
    const sendAxios = async (url, data, callback) => {
      try {
        const response = await axios.post(`${svrUrl}${url}`, data);
        let jsonObj = response.data;
        callback(jsonObj);
      } catch (error) {
        // callback('err');
        console.error("Axios 요청 중 오류가 발생했습니다2:", error);
      }
  };

    
  axios.interceptors.request.use(config => {
    //login보다 먼저 실행돼야 한다.

    let acaDbName = fnc.getCookie('acaDbName');
    // 모든 POST 요청에 데이터 추가
    if (config.method === 'post' && config.data) {
      config.data.db  = acaDbName;// 'Autogen0000';//acaDbName;
      config.data.name3 = '홍길동33';
    }
  
    // 모든 GET 요청에 쿼리 파라미터 추가
    if (config.method === 'get') {
      config.params = config.params || {};
      config.params.name3 = '홍길동2';
    }
  
    return config;
  }, error => {
    // 요청 오류 처리
    console.error('Axios 요청 인터셉터에서 오류 발생:', error);
    return Promise.reject(error);
  });
  
  


    const handleIdClick = () => {
      if (isIdChecked) {
          setAutoLoginChecked(false);
          fnc.setCookie('isAutoLoginChecked', 'false', 1000);
      }
      setIdChecked(prev => !prev);
      fnc.setCookie('isIdChecked', String(!isIdChecked), 1000);
  }
  
  const handleAutoLoginClick = () => {
    if (!isAutoLoginChecked) {
        setIdChecked(true);
        fnc.setCookie('isIdChecked', 'true', 1000);
    }
    setAutoLoginChecked(prev => !prev);
    fnc.setCookie('isAutoLoginChecked', String(!isAutoLoginChecked), 1000);
}

     
  const saveCookie = (id, pw, stCode, stName) => {
    fnc.setCookie('certCodeSt', certCodeSt, 1000);
    fnc.setCookie('id', id, 1000);
    fnc.setCookie('pw', pw, 1000);
    fnc.setCookie('stCode', stCode, 1);
    fnc.setCookie('stName', stName, 1);
}


  function restoreCookie() {
    const certCodeSt= fnc.getCookie('certCodeSt');
    const id=fnc.getCookie('id');
    const pw=fnc.getCookie('pw');
    const isIdChecked = fnc.getCookie('isIdChecked') === 'true';
    const isAutoLoginChecked = fnc.getCookie('isAutoLoginChecked') === 'true';
 
    setIdChecked(isIdChecked);
    setAutoLoginChecked(isAutoLoginChecked);

    if (certCodeSt) {
      setCertCodeSt(certCodeSt);
    }

    if (id && isIdChecked) {
      setId(id);
    }

    if (pw && isAutoLoginChecked) {
      setPw(pw);
    }


  }

  function onUpdate_ios() {
    if (typeof window.webkit !== 'undefined' && window.webkit.messageHandlers && window.webkit.messageHandlers.callWebSite) {
      window.webkit.messageHandlers.callWebSite.postMessage('https://naver.com');
    } else {
        console.error("This feature is not available on your platform.");
    }


    if (typeof window.webkit !== 'undefined' && window.webkit.messageHandlers && window.webkit.messageHandlers.callbackHandlerOpenForm) {
      window.webkit.messageHandlers.callbackHandlerOpenForm.postMessage('https://naver.com');
    } else {
        console.error("This feature is not available on your platform.");
    }
  }
  
  
  
  function onUpdate() {
    if (typeof window.myJSInterface !== 'undefined') {
      // // 첫 번째 통신
      // if (window.myJSInterface.callWebSite) {
      //   window.myJSInterface.callWebSite('https://naver.com');
      // } else {
      //   console.error("callWebSite feature is not available on your platform.");
      // }
  
    //   // 두 번째 통신
    //   if (window.myJSInterface.callbackHandlerOpenForm) {
    //     window.myJSInterface.callbackHandlerOpenForm('https://naver.com');
    //   } else {
    //     console.error("callbackHandlerOpenForm feature is not available on your platform.");
    //   }
    // } else {
    //   console.error("Android Interface is not available on this platform.");
    // }

    //세 번째 통신
    if (window.myJSInterface.callbackHandlerOpenForm) {
      window.myJSInterface.closeApp('');
    }
    

   }
}
  
  function onPrivacy() {
    navigate('/PrivacyPolicy');
  }

//======================================================================================

      return (
        <div className='Login'>
          <div className='box-title'>
            {/* <h1 className='font-weight-bold mb-4'>감자국어</h1> */}
            <h3 className='sub_title'>합격을 위한 지름길!</h3>
            {/* <h3>감자국어</h3> */}
          </div>

        <div className="login-container fs-5">
              <input type="text" className='chkbox' id="acaCode" value={certCodeSt} onChange={(e) => setCertCodeSt(e.target.value)} placeholder="인증코드"  required /> 
              <input type="text" className='chkbox' id="id" value={id} onChange={(e) => setId(e.target.value)} placeholder="아이디" required/> 
              <input type="password" className='chkbox' id="password" value={pw} onChange={(e) => setPw(e.target.value)} placeholder="패쓰워드" required />
              <Button className="btn_login" onClick={() => loginClick(id, pw)}>학생 로그인</Button>
              <Button className="btn_loginTec" onClick={() => loginClickTec(id, pw)}>선생님 로그인</Button>
              {false && <Button className="btn_update" onClick={onUpdate}>업데이트</Button>}
          
            <div className="box-chkbox">
                <label className="form-check-label lblChk">
                    <Checkbox className="form-check-input" type="checkbox" 
                    checked={isIdChecked} onChange={handleIdClick} /> &nbsp;아이디저장 </label>
                <label className="form-check-label lblChk">
                    <Checkbox className="form-check-input" type="checkbox"
                    checked={isAutoLoginChecked}  onChange={handleAutoLoginClick} /> &nbsp;자동로그인 </label>
            </div>
{/* 
<Checkbox defaultChecked  />
<Checkbox defaultChecked  /> */}

{/* 

<Box className="box-chkbox">
            <FormControlLabel
                control={
                    <Checkbox
                        checked={isIdChecked}
                        onChange={handleIdClick}
                    />
                }
                label="아이디저장"
                // className="lblChk"
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={isAutoLoginChecked}
                        onChange={handleAutoLoginClick}
                        // className="me-3"
                    />
                }
                label="자동로그인"
                // className="lblChk px-5"
            />
        </Box> */}
        

            <div className='wrap_logo hide-on-logo'>
                      <div className='box_logo'>
                        <img className="img_logo" src={imgLogo} alt="logo" />   {/* 상대경로 못 읽어 */}
                      </div>
            </div>
         

        </div>
          <div className='ver-info'>
          <Button className="btn_privacy" onClick={onPrivacy}>개인정보처리방침</Button>
           {appVersion && <div>ver {appVersion}</div>} 
           {!appVersion && <div>Aplication ver 1.0.1</div>}
         
          </div>
         
         {/* <MyBtn className='btnTest red lg' onClick={() => alert('버튼이 클릭되었습니다.')} >클릭하세요!</MyBtn> */}
        </div>
      );
}


export default Login;
