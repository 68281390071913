import './ManagementSt_sms_report_attend.scss';

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import fnc from '../mymod/commFunction';
import { useDispatch, useSelector } from 'react-redux';
import ComboboxMui from '../comm/ComboboxMui';
import { Button } from '@mui/material';

import CustomSpinner from '../comm/CustomSpinner';
import { toPng } from 'html-to-image';

const ManagementSt_sms_report_attend = ({selectedItem, lessonDate, kind}) => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate(); 
    const [list, setList] = useState([]);
    const [lessonWeek, setLessonWeek] = useState(null);
    const [startDate, setStartDate] = useState(null); 
    const [endDate, setEndDate] = useState(null); 

    const selectedClassCode = useSelector(state => state.reduxStore_st.selectedClassCode);

    const selectedDateString = useSelector(state => state.reduxStore_st.selectedDate);
    const classCode = selectedClassCode ? selectedClassCode.classCode : '';//selectedClassCode 이거 객체로 사용
    const stCode = selectedItem.stCode;
      
    useEffect(() => {
        if(lessonDate){
          const { startDate, endDate } = fnc.getStartAndEndDateOfWeekObj(lessonDate);
          setStartDate(startDate);
          setEndDate(endDate);
        }
    }, [classCode, lessonDate]); // startDate가 변경될 때만 호출

    useEffect(() => {
      if (stCode && startDate && endDate) {
        fncSelect_stList(classCode, stCode, startDate, endDate);
      }
    }, [stCode, startDate, endDate]);


    function fncSelect_stList(classCode, stCode, startDate, endDate) {
    
      startDate = fnc.formatDate(startDate);
      endDate = fnc.formatDate(endDate);

      fnc.sendAxios('/select/manageMentSt/smsReport/attendResult', { classCode, stCode, startDate, endDate, kind }, (res) => {
        if (res) {  
          console.log(res, '결석사유');
              setList(getRecords_stList(res));
        } else {
              console.log(res,"managementStRoutes/select/manageMentSt/smsReport/attendResult");
        }
      });
    }


    function getRecords_stList(res) {
        let records = [];
        res.forEach(item => {
          const classCode = item["classCode"];  
          const className = item["className"];  
          const gangsaCode = item["gangsaCode"]; 
          const gangsaName = item["gangsaName"]; 
          const stCode = item["stCode"];  
          const stName = item["stName"];     
          const school = item["school"];  
          const hp = item["hp"];      
          const parentHp = item["parentHp"];  
          const attend = item["attend"];      
          const reason = item["reason"];
     
          const achievement = item["achievement"] ? item["achievement"] + '%' : '';
          const diligence = item["diligence"] ? item["diligence"] + '%' : '';
          const progress = item["progress"] ? item["progress"] + '%' : '';
 
          const lessonDate = item["lessonDate"];                
            records.push({ classCode, className, gangsaCode, gangsaName, stCode, stName, school, hp, parentHp, 
              attend, reason, lessonDate, achievement, diligence, progress});
        });
        return records;
      }

  //===================================================
  return (
   <div className="ManagementSt_sms_report_attend">
         {list.length > 0 && <p className='title'>{`출결 (${kind} 수업)`} </p> }
         {list.length > 0 &&  <table className="table-hong table-main-attend">
              <thead>
                <tr>
                  <th></th>
                  <th>수업일</th>
                  <th>반명</th>
                  <th>출결</th>
                  <th>사유</th>
                </tr>
              </thead>

              <tbody>
                  {list && list.map((item, index) => (
                      <tr key={index} >  
                        <td>{index+1}</td>
                        <td>{item.lessonDate}</td>
                        <td>{item.className}</td>
                        <td>{item.attend} </td>
                        <td>{item.reason} </td>
                      </tr>
                  ))}
            
              </tbody>

              
            </table> }

      </div>
  );

//===========================================================================
  
}

export default ManagementSt_sms_report_attend;
