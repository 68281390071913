import './BoardListQna.scss';
import * as S from '../scss/theme';
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedList, setInitialPage, setIsAccorddionExpend } from "../reduxToolkit/reduxVideo";
import * as Accordion from '@radix-ui/react-accordion';
import LikeButton from './LikeButton';
import Views from './Views';
import Comment from './Comment';
import fnc from '../mymod/commFunction';
import Header from '../Header';
import BoardUpdateDeleteText from './BoardUpdateDeleteText';

const BoardListQna = ({currentItems, setModalOpen, setSelectedItem}) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const stCode = fnc.getCookie('stCode');
    const boardTitle = useSelector(state => state.reduxStore_video.boardTitle);
    const isAdmin = useSelector(state => state.reduxStore_video.isAdmin);
    const isAccorddionExpend = useSelector(state => state.reduxStore_video.isAccorddionExpend);
    const isAnoymous = useSelector(state => state.reduxStore_video.isAnoymous);
    const [viewCnt, setViewCnt] = useState(0);
    const [expandedValue, setExpandedValue] = useState(null);//펼쳐졌는지 검사
    const [isModal, setIsModal] = useState(false);

    const onBoardWriteText = () => {
        navigate('/boardWriteText'); 
      };

      const onDeleteClick = (item) => {
        dispatch(setSelectedList({ 
            sno:item.sno, 
            title:item.title, 
            category:item.category,
            content:item.content, 
            date:item.date,
            kind:item.kind
          }));
          setIsModal(true);
        // navigate('/boardUpdateDeleteText'); 
      };


      function onClickHeader(value, index){//value=item.sno임, 접힐 때는 null이 나옴
        if (value && value !== expandedValue) {
          setViewCnt(currentItems[index].viewCnt + 1);
          setExpandedValue(value);
          fncInsert_views(value, index);
        } else if(value && value == expandedValue) {//그냥 else로해야 닫힌다.
          setExpandedValue(null); //일단 죽여논다 다른 것 클릭하면 접혀
        }
      }

      function fncInsert_views(parentCode, index) {
        fnc.sendAxios('/insert_board_view',{parentCode},(res) => {
          if(res){
            currentItems[index].viewCnt = (currentItems[index].viewCnt || 0) + 1;
          }
        
        });
      }

      useEffect(() => {
        if(!isAccorddionExpend){
          setExpandedValue(null);
          dispatch(setIsAccorddionExpend(true));
        }

      }, [isAccorddionExpend]);

      // console.log(currentItems,'---------currentItems-------------------');

    //=====================================================================================
    return (
      <div className="BoardListQna">

        {
          <div className="BoardList">
            {true && (//글쓰기 버튼 isAdmin
              <div className={boardTitle.value ==='qna' ? 'box_write_video':'box_write'}>
                <Button
                  variant=""
                  endIcon={<i className="fas fa-edit"></i>}
                  onClick={onBoardWriteText}
                >
                  {/* <span className='write'>글쓰기</span> */}
                  
                </Button>
              </div>
            )}

            <Accordion.Root 
            type="single" 
             className="AccordionRoot" 
             value={expandedValue}   // 현재 확장된 아이템을 지정
             onValueChange={setExpandedValue}  // 아이템 선택 변경 시 expandedValue 업데이트
             > 
              {currentItems.map((item, index) => (
                <Accordion.Item
                  key={item.sno}
                  value={item.code}
                  className="AccordionItem"
                  data-value={expandedValue === item.code ? 'selectedValue' : undefined}
            
                  onClick={() => onClickHeader(item.code, index)}
                >
                  <Accordion.Header className="AccordionHeader">
                    <Accordion.Trigger
                     
                      className="AccordionTrigger"
                    >
                    <article className='box-header-title'>

                      <div className="title-with-notice">
                          {item.category.includes('공지') && (
                            <span className="notice-label">공지</span>
                          )}
                        <p className='header-title'>{item.title}</p>
                      </div>

                        <p className='box-date-name'>
                          <span>{item.date.replaceAll('-','.')} </span> 
                          {/* <span>{fnc.obscureName(item.stName)}</span> */}
                          <span> { item.kind ==='qna'? item.stName : '****'}</span> 
                        </p>
                    
                    </article>
                      
                      <i className="fas fa-chevron-up AccordionIcon"></i>
                    </Accordion.Trigger>
                  </Accordion.Header>
                  <Accordion.Content className="AccordionContent"
                  onClick={e => e.stopPropagation()}  // content클릭시 해더에 영향 안 가게 이벤트 버블링 중단
                   >
                    <div className="box-content">
                      <div className="box_topMenu">
                        <div className="box_date">
                          <div className="date">{item.date.replaceAll('-','.')}</div>
                          <span className="name"> {isAnoymous ? '****' : item.stName}</span> 
                          <div className="viewCnt">조회수 {viewCnt}</div>
                        </div>
                        <div className="menu">
                          {(stCode === item.stCode || isAdmin) && (
                            <button
                              onClick={() => onDeleteClick(item)}
                              className="btnDelete"
                            >
                              수정 | 삭제
                            </button>
                          )}
                        </div>
                      </div>

                      <div className="content">
                        {item.content}
                        <LikeButton
                          code={item.code}
                          totalCnt={item.likeCnt}
                          stLikeCnt={item.likeCnt}
                        />
            
                        <Comment parentCode={item.code} />
                      </div>
                    </div>
                  </Accordion.Content>
                </Accordion.Item>
              ))}
            </Accordion.Root>
          </div>
        }

        {isModal && <div className='box_modal'>
                          <BoardUpdateDeleteText setIsModal={setIsModal}/>
        </div> } 

      </div>
    );
  };
  
export default BoardListQna;
