import './ManagementSt_smsApp.scss';
import * as S from '../scss/theme';
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";

import Checkbox from '@mui/material/Checkbox';

import Listbox from '../comm/Listbox';
import fnc from '../mymod/commFunction';
import Header from '../Header';
import ManagementSt_sms from './ManagementSt_sms';

import { useSelector, useDispatch } from 'react-redux';
import { setSelectedClassCode, setSelectedTab} from  '../reduxToolkit/reduxSt';


const ManagementSt_smsApp = () => {
    const navigate = useNavigate(); 
    const dispatch = useDispatch();
    const [list, setList] = useState([]);
    const [classList, setClassList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isCoaching, setIsCoaching] = useState(false);
    const [selectedClass, setSelectedClass] = useState(null);
    
    const selectedTab = useSelector(state => state.reduxStore_st.selectedTab);



    useEffect(() => {
        fncSelect_gangsa();
        dispatch(setSelectedClassCode({}));//하단 table 초기화
    }, []);

  const handleSelect = (item) => {//강사
    // console.log(item, 'item');
    setSelectedItem(item);
    fncSelect_classList(item.key);
    dispatch(setSelectedClassCode({}));//하단 table 초기화
  };

  const handleSelect_class = (item) => {
    dispatch(setSelectedClassCode(item));

    setSelectedClass(item);

    item.type == '코칭' ? setIsCoaching(true) : setIsCoaching(false);
  };


  function fncSelect_gangsa() {
    fnc.sendAxios('/select/manageMentSt/gangsa', { }, (res) => {
        if (res) {  
            setList(getRecords(res));
      } else {
            console.log(res,"select/manageMentSt/gangsa");
      }
    });
}


function getRecords(res) {
    let records = [];
    res.forEach(item => {    
      const key = item["codeTec"];  
      const name = item["nameTec"];           
      records.push({ key, name });
    });
   
    return records;
  }

  
  function fncSelect_classList(gangsaCode) {
    fnc.sendAxios('/select/manageMentSt/classList', { gangsaCode }, (res) => {
        if (res) {  
            setClassList(getRecords_classList(res));
      } else {
            console.log(res,"select/manageMentSt/classList");
      }
    });
}


function getRecords_classList(res) {
    let records = [];
    res.forEach(item => {
      const key = item["classCode"];  
      let name = item["className"];    
      const classCode = item["classCode"];  
      const className = item["className"];  
      const gangsaCode = item["gangsaCode"];  
      const gangsaName = item["gangsaName"];  
      const type = item["type"];    
       
      name = `(${type}) ${name}`; 
      records.push({ key, name, classCode, className, gangsaCode, gangsaName, type });
    });
    return records;
  }

  
function fncTestClose() {

  const confirmed = window.confirm('학생관리를 종료하시겠습니까?');
  if (!confirmed) return

  setTimeout(() => {
    navigate(-1);
  }, 200); 


}

  //===================================================
  return (
    <div className='ManagementSt_smsApp'>
<S.Section>
      <div className="header-container">
        <Header onBackClick={() => fncTestClose() } title={selectedClass?.className} />
      </div>
        
     <div className='main-containar'>
      
      <section className='section-gangsa-class'>
        <article className='article-gangsa'>
          <p className='listTitle'>담임</p>
          <Listbox items={list} onSelect={handleSelect} /> 
        </article>

        <article className='article-class'>
          <p className='listTitle'>반명</p>
          <Listbox items={classList} onSelect={handleSelect_class} /> 
        </article>
      </section>
 
      <section className='section-main'>

        <article className='article-report'>
            <ManagementSt_sms />
            
        </article>
         
      </section>

      </div> 
      </S.Section>
    </div>  
  );


}

export default ManagementSt_smsApp;
