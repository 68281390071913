import './VideoPlay.css';
import React, { useState, useEffect, useRef } from "react";
import ReactPlayer from 'react-player';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedVideoList } from "../reduxToolkit/reduxVideo";
import RcSlider from 'rc-slider';
import 'rc-slider/assets/index.css'; // rc-slider의 기본 스타일


const VideoPlay = () => {
  const videoUrl = process.env.REACT_APP_VIDEO_URL;
  const [duration, setDuration] = useState(0);
  const [playedSecondsSet, setPlayedSecondsSet] = useState(new Set());
  const [progress, setProgress] = useState(0);
  const [fullUrl, setFullUrl] = useState('');
  const selectedVideoList = useSelector(state => state.reduxStore_video.selectedVideoList);
  const [played, setPlayed] = useState(0); // 추가된 상태
  const playerRef = useRef(); // ReactPlayer 참조를 위해 추가

  const [volume, setVolume] = useState(0.8); // 볼륨 상태 초기값은 0.8 (80%)

  useEffect(() => {
  const fileName = selectedVideoList.fileName;
  setFullUrl(`${videoUrl}/${fileName}`)
  }, [selectedVideoList]);

  useEffect(() => {
    setPlayedSecondsSet(new Set());
  }, [duration]);

  useEffect(() => {
    // playedSecondsSet가 변경될 때마다 진행률을 재계산합니다.
    const watchedSeconds = playedSecondsSet.size;
    const newProgress = (watchedSeconds / Math.floor(duration)) * 100;
    setProgress(newProgress);
  }, [playedSecondsSet, duration]);

  const handleEnded = () => {
    setProgress(100); // 비디오 재생이 끝나면 진행률을 100%로 설정합니다.
  };

  // const handleProgress = ({ playedSeconds, state }) => {
  //   // 재생된 초를 Set에 추가하여 중복을 방지하고 시청한 구간을 추적합니다.
  //   setPlayedSecondsSet(prevSet => new Set(prevSet).add(Math.floor(playedSeconds)));
  //   setPlayed(state.played); // played 상태 업데이트
  // };
  const handleProgress = ({ played, playedSeconds }) => {
    // 재생된 초를 Set에 추가하여 중복을 방지하고 시청한 구간을 추적합니다.
    setPlayedSecondsSet(prevSet => new Set(prevSet).add(Math.floor(playedSeconds)));
    setPlayed(played); // played 상태 업데이트

    
  };
  
  const handleSeekChange = (value) => {
    // RcSlider에서는 0에서 100 사이의 값을 사용하므로, 0~1 사이의 값으로 변환합니다.
    const newPlayed = value / 100;
    setPlayed(newPlayed); // 새로 계산된 played 상태를 업데이트합니다.
    playerRef.current.seekTo(newPlayed); // ReactPlayer의 재생 위치를 변경합니다.
  };

  //------------------------------볼륨
  const handleVolumeChange = (value) => {
    const newVolume = value / 100; // RcSlider에서 0-100 값을 사용하므로, 0-1 사이의 값으로 변환
    setVolume(newVolume);
   
  };

  return (
    <div className="VideoPlay">

      <div className="box_header">

      </div>

      <div className="box_player">
        <ReactPlayer
          ref={playerRef}
          // url="https://mathautogen.i234.me/test.mp4"
          url={fullUrl}
          playing
          controls={false} // 내장 컨트롤 비활성화
          volume={volume} // 볼륨 상태를 ReactPlayer에 전달
          onDuration={setDuration}
          onProgress={handleProgress}
          onEnded={handleEnded}
          width="100%"
          height="100%"
          config={{ file: { 
            attributes: {
              controlsList: 'nodownload'
            }
          }}}
        />


<RcSlider
      min={0}
      max={100} // rc-slider 사용 시, 0에서 1 대신 0에서 100까지의 범위를 사용
      value={played * 100} // 0~1 사이의 값에서 0~100 사이의 값으로 변환
      onChange={handleSeekChange}
      trackStyle={{ backgroundColor: '#00c53b', height: 5 }}
      handleStyle={{
        borderColor: '#00c53b',
        height: 15,
        width: 15,
        marginLeft: -7,
        marginTop: -6,
        backgroundColor: '#00c53b',
      }}
      railStyle={{ backgroundColor: 'lightgrey', height: 5 }}
    />


 {/* 볼륨 조절 슬라이더 */}
 <RcSlider
          min={0}
          max={100}
          value={volume * 100} // 0-1 사이의 볼륨 값을 0-100 사이의 값으로 변환
          onChange={handleVolumeChange} // 볼륨 조절 시 호출될 함수
          trackStyle={{ backgroundColor: 'blue', height: 10 }}
          handleStyle={{
            borderColor: 'blue',
            height: 20,
            width: 20,
            marginLeft: -10,
            marginTop: -7,
            backgroundColor: 'blue',
          }}
          railStyle={{ backgroundColor: 'lightgrey', height: 10 }}
        />

        <div>시청 진행률: {progress.toFixed(2)}%</div>
        
      </div>
    </div>
  );
};

export default VideoPlay;
