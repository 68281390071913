import "./markingStudyQue.scss"; 
// import Button from "react-bootstrap/Button";
import React, { useEffect, useState, useRef  } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import fnc from '../mymod/commFunction';
// import Cookies from 'js-cookie';
// import { Prev } from "react-bootstrap/esm/PageItem";

function MarkingStudyQue({ 
  list, 
  selectedRow,  
  imgSize, 
  isRowClicked, 
  imgViewState, setImgViewState,
  setIsShowList, 
  setNo, 
  setInfo, 
  setIsRowClicked, 
  fncPrev,
  fncNext, }) {

  const location = useLocation();
  const dapBar = location.state?.dapBar;
  const markingBar = location.state?.markingBar;
  const oxBar = location.state?.oxBar;

  const [imgUrlComm, setImgUrlComm] = useState(null);
  const [imgUrlQue, setImgUrlQue] = useState(null);
  const [imgUrlSol, setImgUrlSol] = useState(null);

  const [fileNameComm, setFileNameComm] = useState(null);
  const [fileNameQue, setFileNameQue] = useState(null);
  const [fileNameSol, setFileNameSol] = useState(null);
  const [comment, setComment] = useState(null);

  const [dapBarArray, setDapBarArray] = useState([]);
  const [markingBarArray, setMarkingBarArray] = useState([]);
  const [dap, setDap] = useState(null);
  const [markingValue, setMarkingValue] = useState(null);

  const [animationClass, setAnimationClass] = useState('');
  const prevNextRef = useRef(selectedRow);
  const [startX, setStartX] = useState(null);


  useEffect(() => {
    if (dapBar) {
      const splitArray = dapBar.split('|');
      setDapBarArray(splitArray);
    }
    if (markingBar) {
      const splitArray = markingBar.split('|');
      setMarkingBarArray(splitArray);
    }
  }, []); 
  


  useEffect(() => {
    fncHandleCurrent();
    fncCloseList();
    setIsRowClicked(false);
    setImgViewState('문제');
  }, [isRowClicked, list]);// 이건 or이다. 둘 중 하나만 만족하면 실행

  
  function fncHandleCurrent() {
    if (list && selectedRow !== null) {
      const currentIndex = selectedRow - 1; // 배열 인덱스는 0부터 시작하므로 -1을 합니다.
      fncSelectRowIdx(currentIndex);
    }
  }


function fncSelectRowIdx(index) {

  if (index < 0) return false;

  const folder = list[index].폴더;
  const fileNameComm = list[index].공통지문;
  const fileNameQue = list[index].fileName;
  const fileNameSol = '풀이_' + fileNameQue;
  const comment = list[index].코멘트;

  let selectDap = dapBarArray[index] || '';
  let selectMarkingValue = markingBarArray[index] || '';  // 이 부분을 수정

  fncShowImgQue(folder, fileNameComm, fileNameQue, fileNameSol, comment);  

  setNo(list[index].no + '번');
  let OX = list[index].OX
  let 정답율 = list[index].정답율;

  setInfo({ OX: OX, incorrectRate: 정답율,  dap: selectDap, markingValue: selectMarkingValue, cnt: list.length });
  setDap(selectDap);
  setMarkingValue(selectMarkingValue);  // 상태 업데이트 함수를 사용하여 값을 설정
}

function fncCloseList() {
  setIsShowList(false);
}


const handlePrevNext = (direction) => {
  // console.log(direction, '--------------------------direction');
  setAnimationClass(direction > 0 ? 'slide-in-left' : 'slide-in-right');
  // 애니메이션이 완료되면 클래스를 제거
  setTimeout(() => setAnimationClass(''), 500);
};


// 터치 시작 지점의 x 좌표를 저장합니다.
const handleTouchStart = (e) => {
  setStartX(e.touches[0].clientX);
}

// 터치 종료 지점에서의 x 좌표와 시작 지점의 x 좌표를 비교하여 슬라이드를 감지합니다.
const handleTouchEnd = (e) => {
  const endX = e.changedTouches[0].clientX;
  if (startX - endX > 100) { // 왼쪽으로 50px 이상 슬라이드한 경우
    slideToLeftFunction();
  } else if (endX - startX > 100) { // 오른쪽으로 50px 이상 슬라이드한 경우
    slideToRightFunction();
  }
}

const slideToLeftFunction = () => {
  console.log("왼쪽으로 슬라이드!");
  fncNext();
}

const slideToRightFunction = () => {
  console.log("오른쪽으로 슬라이드!");
    fncPrev();
}

function fncShowImgQue(folder,fileNameComm, fileNameQue, fileNameSol, comment) {
  const rnd = Math.floor(Math.random() * 1000); // 0부터 999까지의 랜덤 숫자 생성
  folder = folder.replace(/\\/g, "/");
  fileNameComm=fileNameComm.replace('.hwp','.gif');
  fileNameQue = fileNameQue + ".gif";
  fileNameSol = '풀이_' + fileNameQue;

  const acaCode = fnc.getCookie('acaCode');
  const imageWebPath = fnc.getCookie('imageWebPath');
  const imageWebPath_acaCode_folder = `${imageWebPath}/autogenEditImage/${acaCode}/${folder}`;

  const imgPathComm= `${imageWebPath_acaCode_folder}/${fileNameComm}?rnd=${rnd}`;
  const imgPathQue = `${imageWebPath_acaCode_folder}/${fileNameQue}?rnd=${rnd}`;
  const imgPathSol = `${imageWebPath_acaCode_folder}/풀이_${fileNameQue}?rnd=${rnd}`;

  // alert(imgPathSol)
  setFileNameComm(fileNameComm);
  setFileNameQue(fileNameQue);
  setComment(comment);

  setImgUrlComm(imgPathComm);
  setImgUrlQue(imgPathQue);
  setImgUrlSol(imgPathSol);
  
  fncCloseList();

 
  const direction = Number(prevNextRef.current) - Number(selectedRow)
  handlePrevNext(direction);
  prevNextRef.current = selectedRow;

 
}

function fncCloseList() {
  setIsShowList(false);
}

// const handlePrevNext = (direction) => {
//   setAnimationClass(direction < 0 ? 'slide-in-left' : 'slide-in-right');
//   // 애니메이션이 완료되면 클래스를 제거
//   setTimeout(() => setAnimationClass(''), 500);
// };


  return (
    // <div className="MarkingStudyQue"  onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
    <div className="markingStudyQue">
      <div className="main">

      <div className={`main_inner ${animationClass}`}>
            { fileNameComm && imgViewState == '문제'  &&  <img className="img-comm"  src={imgUrlComm}  style={{ width: `${imgSize}px` }} alt="공통지문 이미지" />} 
            { fileNameQue && imgViewState == '문제'  &&  <img className="img-que"  src={imgUrlQue}  style={{ width: `${imgSize}px` }} alt="문제 이미지" />}
            { fileNameQue && imgViewState == '풀이'  &&  <img className="img-sol"  src={imgUrlSol}  style={{ width: `${imgSize}px` }} alt="풀이 이미지" />}      
            { comment && <label id="lblComment">{comment}</label> }

            <div className="box-dap">
                { imgViewState === '정답' && <label>[정답] {dap}</label>}
                { imgViewState === '정답' && <label>[마킹] {markingValue}</label>}
            </div>
        </div>
      </div>
    </div>
  );
}

export default MarkingStudyQue;
