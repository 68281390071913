import './ManagementSt_homework_evalue.scss';
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import fnc from '../mymod/commFunction';
import { useDispatch, useSelector } from 'react-redux';
import ComboboxMui from '../comm/ComboboxMui';
import { Button } from '@mui/material';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Checkbox from '@mui/material/Checkbox';
import {setSelectedDate} from  '../reduxToolkit/reduxSt';
import Dtpicker2 from '../comm/Dtpicker2';

const ManagementSt_homework_evalue = () => {
    const navigate = useNavigate(); 
    const [list, setList] = useState([]);
    const selectedDateString = useSelector(state => state.reduxStore_st.selectedDate);
    const [startDate, setStartDate] = useState(new Date(selectedDateString)); // 초기화 시에만 사용

    const selectedClassCode = useSelector(state => state.reduxStore_st.selectedClassCode);
    const classCode = selectedClassCode ? selectedClassCode.classCode : '';//selectedClassCode 이거 객체로 사용

    const [isSelectAll, setIsSelectAll] = useState(false);//체크박스
    // const [selectAll, setSelectAll] = useState(false);//체크박스
    const [isRefresh, setIsRefresh] = useState(false);
    const dispatch = useDispatch();

  
    useEffect(() => {
      const lessonDate = fnc.formatDate(startDate);
      fncSelect_stList(classCode, lessonDate);
      dispatch(setSelectedDate(startDate.toISOString())); 
    }, [classCode, startDate, isRefresh]);

   
       
  
  function fncSelect_stList(classCode, lessonDate) {
    fnc.sendAxios('/select/manageMentSt/homework_evalue', { classCode, lessonDate }, (res) => {
      if (res) {  
            setList(getRecords_stList(res));
      } else {
            console.log(res,"managementStRoutes/select/manageMentSt/homework_evalue");
      }
    });
  }


function getRecords_stList(res) {
    let records = [];
    let initialOptionList = {}; // optionList 초기화 객체
    for (let i = 0; i < res.length; i++) { 
        const sno = res[i]["sno"];  
        const classCode = res[i]["classCode"];  
        const className = res[i]["className"];  
        const gangsaCode = res[i]["gangsaCode"]; 
        const gangsaName = res[i]["gangsaName"]; 
        const stCode = res[i]["stCode"];  
        const stName = res[i]["stName"];     
        const school = res[i]["school"];  
        const hp= res[i]["hp"];      
        const parentHp= res[i]["parentHp"];  
        const evaluation= res[i]["evaluation"];         
        const lessonDate= res[i]["lessonDate"];  
        const homework= res[i]["homework"];   
        const isChecked = false;
        
        records.push({ sno, classCode, className, gangsaCode, gangsaName, stCode, stName, school, hp, parentHp, 
          evaluation, lessonDate, homework, isChecked });
    }

    return records;
  }



//--------------------------------------------------저장

function onSaveEvaluation() {
  const checkedItems = list.filter(item => item.isChecked); // 체크된 항목 필터링
 
  if (checkedItems.length === 0) {
    alert ('대상을 체크하세요!');
    return; // 체크된 항목이 없으면 종료
  }

  const rtn = window.confirm(`${checkedItems.length}명의 평가를 저장하시겠습니까?`);
  if (!rtn) return;

  let completed = 0; // 완료된 삭제 작업을 추적
  const total = checkedItems.length;

  checkedItems.forEach(item => {
    const sno = item.sno;
    const evaluation = item.evaluation;
    fncUpdate_evaluation(sno, evaluation, () => {
       completed++;
      if (completed === total) {
        setIsRefresh(!isRefresh);
      }
    });
  });
}

function fncUpdate_evaluation (sno, evaluation, callback) {
  fnc.sendAxios('/update/manageMentSt/homework/evaluation', { sno, evaluation }, (res) => {
    if (res) {  
      callback();
    } else {
          console.log(res,"managementSt_attend/update/manageMentSt/homework/evaluation");
    }
  });
}

//------------------------------------------------삭제
function onDeleteEvaluation() {
  const checkedItems = list.filter(item => item.isChecked); // 체크된 항목 필터링
 
  if (checkedItems.length === 0) {
    alert ('대상을 체크하세요!');
    return; // 체크된 항목이 없으면 종료
  }

  const rtn = window.confirm(`${checkedItems.length}명의 평가를 삭제하시겠습니까?`);
  if (!rtn) return;

  let completed = 0; // 완료된 삭제 작업을 추적
  const total = checkedItems.length;

  checkedItems.forEach(item => {
    const sno = item.sno;
    fncDelete_evaluation(sno, () => {
       completed++;
      if (completed === total) {
        setIsRefresh(!isRefresh);
      }
    });
  });
}


function fncDelete_evaluation (sno, callback) {
  fnc.sendAxios('/delete/manageMentSt/homework/evaluation', { sno }, (res) => {
    if (res) {    
        callback(); // 삭제가 성공하면 콜백 호출
    } else {
        console.log(res,"managementSt_attend/delete/manageMentSt/homework/evaluation");
    }
  });
}

  //===================================================
  return (
   <div className="ManagementSt_homework_evalue">
        <div className="wrap-main">
          <section className="section-main">
            <table className="table-hong table-main">
              <thead>
                <tr>
                  <th>{list.length}</th>      
                  <th>성명</th>
                  <th>HP</th>
                  <th>과제</th>
                  <th>평가</th>
                  <th> <Checkbox checked={isSelectAll} onChange={handleSelectAllChange} /></th>
                </tr>
              </thead>

              <tbody>
                  {list && list.map((item, index) => (
  
                      <tr key={index} >  
                        <td>{index+1}</td>      
                        <td>{item.stName}</td>
                        <td>{item.hp}</td>
                        <td className='homework'>{item.homework}</td>
                        <td className={
                          item.evaluation == '불이행' ? 'attendRed':
                          item.evaluation == '이행' ? 'attendGreen': 
                          item.evaluation == '과제없음' ? 'attendBlue':''
                        } onClick={() => onClickAttend(item, index)}>{item.evaluation}
                        </td>
                        {/* <td onClick={() => onClickDelete(item, index)}>
                          <i class="fa-regular fa-trash-can"></i>
                        </td> */}

                        <td>
                            <Checkbox
                                checked={item.isChecked}
                                onChange={(event) => handleCheckboxChange(event, index)}
                            />
                        </td>
                      
                      </tr>
                  ))}
            
                </tbody>
            </table>
          </section>

          <section className='section-menu'>
                  <label>수업일</label>
                  <article className='article-dtp'>
                      <Dtpicker2 selectedDate={startDate} onDateChange={(date) => setStartDate(date)} />
                  </article>

                  <Button className='btn-menu btnSave' onClick={onAllAttend}>
                    이행
                  </Button>

                  <Button className='btn-menu btnSave' onClick={onAllAbsence}>
                    불이행
                  </Button>

                  <Button className='btn-menu btnSave' onClick={onAllHomeworkNone}>
                    없음
                  </Button>

                  <Button className='btn-menu btnSave' onClick={onSaveEvaluation}>
                    <i class="fa-regular fa-save"></i>&thinsp;저장
                  </Button> 

                  <Button className='btn-menu btnSave' onClick={onDeleteEvaluation}>
                    <i class="fa-regular fa-trash-can"></i>&thinsp;삭제
                  </Button> 
                  

          </section>

        </div>


      </div>
  );

  //===========================================================================
  
function onClickAttend(item, index) {//출결 변경

  const newList = [...list];
  
  switch(newList[index].evaluation) {
    case '':
      newList[index].evaluation = '이행';
      break;
    case '이행':
      newList[index].evaluation = '불이행';
      break;
    case '불이행':
        newList[index].evaluation = '과제없음';
        break;
    case '과제없음':
      newList[index].evaluation = '이행';
      break;

    default:
      console.log(`Unknown status: ${newList[index].checkedynSt}`);
  }
  setList(newList);
}


//--------전체출석

function onAllAttend() {
  const newList = list.map(item => ({
    ...item, evaluation: '이행'
  }));
  setList(newList);
}

function onAllAbsence() {
  const newList = list.map(item => ({
    ...item, evaluation: '불이행'
  }));
  setList(newList);
}


function onAllHomeworkNone() {
  const newList = list.map(item => ({
    ...item, evaluation: '과제없음'
  }));
  setList(newList);
}

function onAllClear() {
  const newList = list.map(item => ({
    ...item, evaluation: ''
  }));
  setList(newList);
}

 //---------------------------checkbox
 function handleCheckboxChange(event, index) {
    const isChecked = event.target.checked;
    const newList = [...list];
    newList[index].isChecked = isChecked;
    setList(newList);

    // 모두 체크되었는지 확인하여 헤더 체크박스 상태 업데이트
    const allChecked = newList.every(item => item.isChecked);
    setIsSelectAll(allChecked);
  }

  function handleSelectAllChange() {
      const newList = list.map(item => ({...item, isChecked: !isSelectAll }));
      setList(newList);
      setIsSelectAll(!isSelectAll);
  }//checkbox END



}

export default ManagementSt_homework_evalue;
