import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import './PaginatedItems.scss';
import Header from '../Header';
// import BoardList from './BoardList';
import BoardListFaq from './BoardListFaq';
// import BoardDetail from './BoardDetail';
// import GalleryList from '../Gallery/GalleryList';
// import VideoList from '../Video/VideoList';
// import * as S from '../theme_hong';
// import * as S from '../theme';
// import SubNav from './Nav';

import { useSelector, useDispatch } from 'react-redux';
import { setInitialPage } from "../reduxToolkit/reduxVideo";
import { useNavigate } from 'react-router-dom';
import BoardListQna from './BoardListQna';
// import Footer from '../Footer';


function PaginatedItems({ items, itemsPerPage }) {

    const dispatch = useDispatch();

    const [itemOffset, setItemOffset] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    
    const [isNotice, setIsNotice] = useState(false);
    const [isBoard, setIsBoard] = useState(false);
    const [isFaq, setIsFaq] = useState(false);
    const [isGallery, setIsGallery] = useState(false);
    const [isVideo, setIsVideo] = useState(false);
    const [isLibray, setIsLibray] = useState(false);

    const endOffset = itemOffset + itemsPerPage;
    const currentItems = items.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(items.length / itemsPerPage);
    const navigate = useNavigate();

    const handlePageClick = (event) => {
        const newOffset = event.selected * itemsPerPage;
        setItemOffset(newOffset);
        dispatch(setInitialPage( event.selected));//paginate button number

    };

    const initialPage = useSelector(state => state.reduxStore_video.initialPage);
    const boardTitle = useSelector(state => state.reduxStore_video.boardTitle);
    const boardKind = useSelector(state => state.reduxStore_video.boardKind);

  
    useEffect(() => {
    //  console.log(boardKind)
        setIsNotice(boardTitle.value === 'notice');
        // setIsBoard(boardTitle.value === 'board');
        // setIsFaq(boardTitle.value === 'faq');
        // setIsGallery(boardTitle.value === 'gallery');//boardTitle.text, boardTitle.value 두 개다.
        // setIsVideo(boardTitle.value === 'video');
        // setIsLibray(boardTitle.value === 'libray');
    }, [boardTitle]);


    return (
        <>


        <div className={`paginate-container`}>
       
            <div className="ConTaIN">
            {/* <Header onBackClick={()=>{navigate('/menu')}} title={boardTitle.text} /> */}
                {/* <S.SubVisual className='subVisual'> */}
                    <div>
                        {/* <h1>{boardTitle.textEng}</h1> */}
                    </div>
                    {/* <SubNav/> */}
                {/* </S.SubVisual> */}

                {/* <BoardListFaq currentItems={currentItems} /> */}
                <BoardListQna currentItems={currentItems} />

                {/* { (!isNotice || !isBoard || !isLibray) && isFaq && !isGallery && !isVideo && <BoardListFaq currentItems={currentItems} /> }      */}
     
                {/* { (isNotice || isBoard || isLibray) && !isFaq && !isGallery && !isVideo && <BoardList currentItems={currentItems}  /> }
                { (!isNotice || !isBoard || !isLibray) && !isFaq && isGallery && !isVideo && <GalleryList currentItems={currentItems}  /> }
                { (!isNotice || !isBoard || !isLibray) && !isFaq && !isGallery && isVideo && <VideoList currentItems={currentItems}  /> } */}

          
          
            </div>

            <ReactPaginate
                initialPage={initialPage}
                breakLabel={null}
                nextLabel={<i className="fas fa-chevron-right"></i>}
                onPageChange={handlePageClick}
                pageRangeDisplayed={4}
                marginPagesDisplayed={null}
                pageCount={pageCount}
                previousLabel={<i className="fas fa-chevron-left"></i>}
                renderOnZeroPageCount={null}
                containerClassName="pagination"
                activeClassName="active"
            />
        </div>

   
    </>

    );
}

export default PaginatedItems;
