import React, {useEffect} from 'react';
import './DisplayLogo.css';
// import imgLogo from '../images/gamja/logo-kr-traspent.png';
const DisplayLogo = ({imgLogo, width='15rem', height='15rem', top='50%', backgroundColor='transparent' }) => { 

  
  return (
    <div className='DisplayLogo'>
        <div className="box_img" style={{width, height, top, backgroundColor}}>
            <img src={imgLogo} className='img' alt="Logo" />
        </div>

    </div>

  );
}

export default DisplayLogo;
