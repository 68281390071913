import styled from  "styled-components";



// 테마 객체

export const them ={
    bgColor:"#010306",
    dashBg:"#E9EDF8",
    textColor:"#f4f6f5",
    accentColor:"0,59,115",
    accentColor01:"31,59,113",
    accentColor02:"241,220,124",
    accentColor03:"96,131,92",
    itemColor1:"0,151,151",
    itemColor2:"0,115,181",
    itemColor3:"77,94,154",
    opacity: 0.05,
    opacity1: 10,
    opacity2: 0.2,
    opacity4: 0.4,

    //** radius */
    column5:"0.3125rem",
    column10:"0.625rem",
    column15:"0.9375rem",
    column20:"1.25rem",
    column25:"1.5625rem",
    column30:"1.875rem",
    column40:"2.5rem",

   
}

export const Container = styled.div`
    position: relative;
    //max-width: 1680px; //디자이너코드
    max-width: 1500px;
    margin: 0 auto;
    height: auto;
    min-height: 60rem;
    padding: 0 2rem;
`
//내코드
export const ContainerAuto = styled.div`
    position: relative;
    //max-width: 1680px; //디자이너코드
    max-width: 87.5rem;
    margin: 0 auto;
    padding: 10rem 1.25rem;
    height: auto;   
`

export const Section = styled.div`
   margin-top: 0rem;
    height: calc(100vh - 0rem); 
    position: relative;
    padding: 0;
    width: 100vw;
    max-width: 80rem;
    margin: 0 auto;
    border: 1px solid #ddd;

  
    

`



export const Modal_fullScreen = styled.div`
    position:fixed;
    top: 0rem;
    left: 0px;
    width: 100%;//스크롤 생기면 부모가 왼쪽으로 이동한다.
    height: calc(100vh - 0rem); 
    z-index: 10;
    background-color: ${(props) => props.backgroundColor || 'rgba(1, 1, 1, 0.1)'};
    display:flex;
    justify-content:center;
    /* props 사용법 */
    /* <S.Modal_fullScreen backgroundColor='white'> */
    
`;

export default them