import './ManagementSt_attend.scss';
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import fnc from '../mymod/commFunction';
import { useDispatch, useSelector } from 'react-redux';
import ComboboxMui from '../comm/ComboboxMui';
import { Button } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {setSelectedDate, setSelectedTab} from  '../reduxToolkit/reduxSt';
import Dtpicker2 from '../comm/Dtpicker2';

const ManagementSt_attend = () => {
    const navigate = useNavigate(); 
    const [optionData, setOptionData] = useState([]);
    const [list, setList] = useState([]);
    const [isRefresh, setIsRefresh] = useState(false);

    const selectedClassCode = useSelector(state => state.reduxStore_st.selectedClassCode);

    const selectedDateString = useSelector(state => state.reduxStore_st.selectedDate);
    const attendReason = useSelector(state => state.reduxStore_st.attendReason);

    const [startDate, setStartDate] = useState(new Date(selectedDateString)); // 초기화 시에만 사용

    const classCode = selectedClassCode ? selectedClassCode.classCode : '';//selectedClassCode 이거 객체로 사용
    const className = selectedClassCode ? selectedClassCode.className : '';
    const gangsaCode = selectedClassCode ? selectedClassCode.gangsaCode : '';
    const gangsaName = selectedClassCode ? selectedClassCode.gangsaName : '';

    const dispatch = useDispatch();
   
    useEffect(()=> {
      setOptionData(attendReason);
    }, []);

    useEffect(() => {
      if(startDate){
      const lessonDate = fnc.formatDate(startDate);
      fncSelect_stList(classCode, lessonDate);

      dispatch(setSelectedDate(startDate.toISOString())); // 상태 업데이트
      }
  }, [classCode, startDate, isRefresh]); // startDate가 변경될 때만 호출


  // function onMoveDay(currDate, days) {
  //   setStartDate(fnc.moveDay(currDate, days));
  
  // }
  

  function fncSelect_attend_reason() {
    const title = '결석사유';

    fnc.sendAxios('/select/manageMentSt/attend_reason_setCombo', { title }, (res) => {
      if (res) {  
        setOptionData(getRecords_attendReason(res));
      } else {
            console.log(res,"managementStRoutes/select/manageMentSt/attend_reason");
      }
    });
  }

  function getRecords_attendReason(res) {
    let records = [];
    // res가 배열인지 확인
    if (!Array.isArray(res)) { return records; }
   
    records.push({ value: '', label: '결석사유를 선택하세요!' });
    res.forEach(item => {
      const value = item["value"];  
      const label = item["label"];                
      records.push({ value, label });
    });
   
    return records;
  }
 
  
    // const optionData = [
    //   { value: '', label: '사유를 선택를 선택하세요' },
    //   { value: '병가', label: '병가' },
    //   { value: '개인사유', label: '개인사유' },
    //   { value: '기타', label: '기타' },
    // ];

  
    const handleOptionChange = (event, index) => {
      // setOptionList({ ...optionList, [index]: event.target.value,  });
      const newList = [...list];
      newList[index].reason = event.target.value;
      setList(newList);
    };

       
  
  function fncSelect_stList(classCode, lessonDate) {
    fnc.sendAxios('/select/manageMentSt/class_stList_attend', { classCode, lessonDate }, (res) => {
      if (res) {  
            setList(getRecords_stList(res));
            // setOptionList({});  // optionList를 빈 객체로 초기화
      } else {
            console.log(res,"managementStRoutes/select/manageMentSt/class_stList_attend");
      }
    });
  }


function getRecords_stList(res) {
    let records = [];
    for (let i = 0; i < res.length; i++) { 
        const classCode = res[i]["classCode"];  
        const className = res[i]["className"];  
        const gangsaCode = res[i]["gangsaCode"]; 
        const gangsaName = res[i]["gangsaName"]; 
        const stCode = res[i]["stCode"];  
        const stName = res[i]["stName"];     
        const school = res[i]["school"];  
        const hp= res[i]["hp"];      
        const parentHp= res[i]["parentHp"];  
        const attend= res[i]["attend"];      
        const reason= res[i]["reason"];      
        const achievement= res[i]["achievement"];//성취도
        const diligence= res[i]["diligence"];//성실도  
        const progress= res[i]["progress"];//진행도

        const lessonDate= res[i]["lessonDate"];            
        records.push({ classCode, className, gangsaCode, gangsaName, stCode, stName, school, hp, parentHp, 
          attend, reason, achievement, diligence, progress, lessonDate });
    }
    return records;
  }


  //------------------------------------------------------------저장
  function onSaveAttend() {
    const cnt = list.filter(item => item.attend.trim() !=='').length;
    const rtn = window.confirm(`${cnt}명의 출결을 저장하시겠습니까?`);
    if (!rtn) return;

    const total =  list.length;
    let completed = 0; // 완료된 삭제 작업을 추적

    for ( let k = 0; k < list.length; k++) {

      const acaCode = fnc.getCookie('acaCode');
      const stCode = list[k].stCode;
      const lessonDate = fnc.formatDate(startDate);
      const classCode = list[k].classCode;
      const className = list[k].className;
      const gangsaCode = list[k].gangsaCode;
      const gangsaName = list[k].gangsaName;
      const attend = list[k].attend;
      // const reason = optionList[k] || '';
      const reason = list[k].reason || '';

      const achievement = list[k].achievement || '';
      const diligence = list[k].diligence || '';
      const progress = list[k].progress || '';

      if (attend) {
        fncSave_attend (acaCode, stCode, lessonDate, classCode, className, gangsaCode, gangsaName, 
          attend, reason, achievement, diligence, progress,
          () => {
            completed++;
            if (completed === total) {
              setIsRefresh(!isRefresh);
              alert('완료되었습니다.');
            }
          
          }
        );
      }
     
    }
  }


  function fncSave_attend (acaCode, stCode, lessonDate, classCode, className,  gangsaCode, gangsaName, 
    attend, reason, achievement, diligence, progress, callback) {
    fnc.sendAxios('/save/manageMentSt/attend', 
      { acaCode, stCode, lessonDate,  classCode, className,  gangsaCode, gangsaName, 
        attend, reason, achievement, diligence, progress }, (res) => {
      if (res) {  
            callback();
      } else {
            console.log(res,"select/manageMentSt/class_stList");
      }
    });
  }


  //===================================================
  return (
   <div className="ManagementSt_attend">
        <div className="wrap-main">
          <section className="section-main">
            <table className="table-hong table-main">
              <thead>
                <tr>
                  <th>{list.length}</th>
                  <th>성명</th>
                  <th>HP</th>
                  <th>출결</th>
                  <th>결석사유</th>
                </tr>
              </thead>

              <tbody>
                  {list && list.map((item, index) => (
  
                      <tr key={index} >  
                        <td>{index+1}</td>
                        <td>{item.stName}</td>
                        <td>{item.hp} </td>
                        <td className={
                          item.attend == '결석' ? 'attendRed':
                          item.attend == '인결' ? 'attendPink': 
                          item.attend == '조퇴' ? 'attendGreen': 
                          item.attend == '휴강' ? 'attendPink':
                          item.attend == '지각' ? 'attendBlue':''
                        } 
                          onClick={() => onClickAttend(item, index)}>{item.attend}
                        </td>

                        <td>
                           <ComboboxMui
                              label=""
                              value={item.reason || ''}
                              onChange={(event) => handleOptionChange(event, index)}
                              options={optionData}
                            />

                        </td>
 
                      </tr>
                  ))}
            
                </tbody>
            </table>
          </section>

          <section className='section-menu'>
                  <label>수업일</label>

                   <article className='article-dtp'>
                      <Dtpicker2 selectedDate={startDate} onDateChange={(date) => setStartDate(date)} />
                  </article>

                  <Button className='btn-menu' onClick={()=> onAllCheck('출석')}>전체출석</Button>
                  <Button className='btn-menu' onClick={()=> onAllCheck('결석')}>결석</Button>
                  <Button className='btn-menu' onClick={()=> onAllCheck('휴강')}>휴강</Button>
                  <Tooltip title="삭제는 반별 출결 조회에서 하세요" placement="top-start">
                  <Button className='btn-menu' onClick={()=> onAllCheck('')}>cls</Button>
                  </Tooltip>
                  <Button className='btn-menu btnSave' onClick={onSaveAttend}>
                  <i class="fa-solid fa-save"></i> &nbsp;저장
                  </Button>

          </section>

        </div>


      </div>
  );

//===========================================================================
  
function onClickAttend(item, index) {//출결 변경

  const newList = [...list];
  
  switch(newList[index].attend) {
    case '':
      newList[index].attend = '출석';
      break;
    case '출석':
      newList[index].attend = '결석';
      break;
    case '결석':
        newList[index].attend = '지각';
        break;
    case '지각':
      newList[index].attend = '인결';
      break;

    case '인결':
        newList[index].attend = '조퇴';
      break;

    case '조퇴':
        newList[index].attend = '휴강';
      break;

    case '휴강':
        newList[index].attend = '출석';
      break;
   
    default:
      console.log(`Unknown status: ${newList[index].checkedynSt}`);
  }
  setList(newList);
}


//--------전체출석


function onAllCheck(result) {
  const newList = list.map(item => ({
    ...item, attend: result
  }));
  setList(newList);
}


}

export default ManagementSt_attend;
