
import "./MemoCanvasApp2.scss"; 
import * as S from '../scss/theme';
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import DrawNormal2 from "../drawSvg2/DrawNormal";
import DrawMenu2 from '../drawSvg2/DrawMenu';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { setIsShowList, setIsCommBoxVisible, setKind, setSelectedRow, setSelectedRowOld } from "../reduxToolkit/redux";

import fnc from '../mymod/commFunction';

// const acaCode = fnc.getCookie('acaCode');
//const imageWebPath = fnc.getCookie('imageWebPath');//이걸로 했더니 간헐적으로 문제가 안보여서 바꿔봄
// const imageWebPath = process.env.REACT_APP_imageWebPath;//mathautogen


const imageWebPath = 'https://mathautogen.i234.me';


const MemoCanvasApp = ({
  height,
  isLoadImgComplete, 
  setIsLoadImgComplete,
  list,
}) => {


  const [allowTouchMove, setAllowTouchMove] = useState(true);
  const [imgPathQue, setImgPathQue] = useState(null);
  const [imgSize, setImgSize] = useState(null);
  const [isClear, setIsClear] = useState(false);

  const dispatch = useDispatch();
  const isShowList = useSelector(state => state.reduxStore.isShowList);
  const isCommBoxVisible = useSelector(state => state.reduxStore.isCommBoxVisible);
  // const list = useSelector(state => state.reduxStore.list);
  const selectedRow = useSelector(state => state.reduxStore.selectedRow);
  const selectedRowOld = useSelector(state => state.reduxStore.selectedRowOld);
  const kind = useSelector(state => state.reduxStore.kind);

  
  const fileName_comm = list[selectedRow-1]?.['공통지문'];

  useEffect(() => {
    if (list.length > 0 && kind ) {
      fncMakeSvg(kind);
    }
    // list를 redux로 하면 두번 실행돼서 props로 전달 받음
  }, [list, kind, selectedRow]);//list 추가

  const fncMakeSvg = (kind) => {
    const acaCode = fnc.getCookie('acaCode');//getCookie 위(전역)으로 가면 안 돼 (완전 개고생 중요)
    //쿠키말고 로컬스토리지 이용하자: webview에서 로그인 후 아이디 등 저장이 한 번에 안돼

    const url = `${imageWebPath}/autogenEditImage/${acaCode}`;
    const folder = list[selectedRow-1]?.['폴더'].replace(/\\/g, '/');
    const fileNameComm = fileName_comm?.replace('.hwp', '');
    const fileName = list[selectedRow-1]?.['fileName'];
       
    const imgSizeQue = list[selectedRow-1]?.['imgSize'];
    const imgSizeSol = list[selectedRow-1]?.['imgSizeSol'];
    const imgSizeComm = list[selectedRow-1]?.['imgSizeComm'];

    let imgPath;
    const rnd = Math.floor(Math.random() * 1000); // 0부터 999까지의 랜덤 숫자 생성
    
    if (kind === 'sol') {
      imgPath = `${url}/${folder}/풀이_${fileName}.gif?rnd=${rnd}`;
      setImgSize(imgSizeSol);
      dispatch(setKind('sol'));
    } else if (kind === 'comm') {
      imgPath = `${url}/${folder}/${fileNameComm}.gif?rnd=${rnd}`;
      setImgSize(imgSizeComm);
      dispatch(setKind('comm'));
    } else {
      imgPath = `${url}/${folder}/${fileName}.gif?rnd=${rnd}`;
      setImgSize(imgSizeQue);
      dispatch(setKind('que'));
    }
    setImgPathQue(imgPath);

    // console.log(imgPath, ' imgPath', kind);//---왜 여길 저장해야 이미지가 나오는가?
  };


  const toggleBox_comm = () => {
 
    if (isCommBoxVisible){
      dispatch(setSelectedRowOld(-100))
      fncMakeSvg('que');
    }else {
      dispatch(setSelectedRowOld(100))
      fncMakeSvg('comm');
    }
    dispatch(setIsCommBoxVisible(!isCommBoxVisible));
  };

  //=======================================================================
  return (
    <div className="MemoCanvasApp2">

     <div className="box-que">
        {fileName_comm && (
          // <div className='box_comm' >
            <button className={isCommBoxVisible ? 'btnToggleComm_que' : 'btnToggleComm_comm'} 
                    onClick={toggleBox_comm}> {isCommBoxVisible ? '문\n제' : '지\n문'}
            </button>
          // </div>
        )}

    
        {(imgPathQue) && (
          <DrawNormal2
            key={imgPathQue}
            imgUrlQue={{ imgPathQue: imgPathQue, selectedList: list[selectedRow - 1] }}
            setAllowTouchMove={setAllowTouchMove}
            height={height}
            imgSize={imgSize}
            isLoadImgComplete={isLoadImgComplete} 
            setIsLoadImgComplete={setIsLoadImgComplete}
            isClear={isClear}
          />
        )}
      </div>

      <DrawMenu2 />

    </div>
  );
};

export default React.memo(MemoCanvasApp);
