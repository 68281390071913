
import React, { useState, useRef, useMemo, useEffect } from 'react';
import './BoardWriteText.scss';
import Header from '../Header';
import Button from '@mui/material/Button';

import { useNavigate } from 'react-router-dom';
import axios from "axios";
import Cookies from 'js-cookie'; 
import fnc from '../mymod/commFunction';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedList, setInitialPage } from "../reduxToolkit/reduxVideo";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { setIsrefresh } from  '../reduxToolkit/reduxSt';

const BoardWriteText = ({ setIsNoticeGrade, lessonWeek, code, codeName, kind }) => {
    
    const isRefresh = useSelector(state => state.reduxStore_st.isRefresh);
    const svrUrl = process.env.REACT_APP_SERVER_URL;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [sno, setSno] = useState('');
    const [content, setContent] = useState('');
    const [title, setTitle] = useState('');
    const titleInputRef = useRef(null); 
    const [list, setList] = useState([]);


    useEffect(() => {

      if (titleInputRef.current) {
        titleInputRef.current.focus();
      }

      fncSelect_list_allimjang_notice( lessonWeek, code);

    }, []);


    
    function fncSelect_list_allimjang_notice( lessonWeek, code) {

      fnc.sendAxios('/select/managementSt/allimjang_notice', { lessonWeek, code }, (res) => {
        if (res) {  
              setSno(res[0].sno);
              setTitle(res[0].title);
              setContent(res[0].content);

        } else {
              console.log(res,"managementStRoutes/select/managementSt/allimjang_notice");
        }
      });
    }

      function fncSaveAllimjangNotice() {
        const rtn = window.confirm(`[${title}]을 저장하시겠습니까?`);
        if(!rtn) return null;

        fnc.sendAxios('/save/managementSt/allimjang_notice', { lessonWeek, code, codeName, kind, title, content }, (res) => {       
          if (res) {
              dispatch(setIsrefresh(!isRefresh));
              onPrev();
          } else {
            console.log("속성이 obj 내에 없습니다.");
          }
        });
      }
      
  
      function fncDeleteNotice() {
        const rtn = window.confirm(`[${title}]을 삭제하시겠습니까?`);
        if(!rtn) return null;

        fnc.sendAxios('/delete/managementSt/allimjang_notice', { sno }, (res) => {       
          if (res) {
            dispatch(setIsrefresh(!isRefresh));
            onPrev();
          } else {
            console.log("/ManagementStRoutes/delete/managementSt/allimjang_notice");
          }
        });
      }
     
      function onPrev() {
        setIsNoticeGrade(false);
      }
    //=========================================================================
    return (
      <div className="BoardWriteText">
        <Header  onBackClick={onPrev} title={`${kind}(${codeName}) 공지사항`} drection={1} />

        <div className="box_jodit">
          <div className="formGroup">
            <input
              className="titleInput"
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="제목을 입력하세요"
              ref={titleInputRef}
            />
          </div>


          <div className="box-textArea">
            <textarea
              name="postContent"
              id="postContent"
              rows="10"
              cols="50"
              placeholder="여기에 게시글을 작성하세요..."
              value={content}
              onChange={(event) => setContent(event.target.value)}
            ></textarea>
          </div>

          <div className="box_btnMenu">
            
            <Button
              className="btnPrevSave"
              variant="contained"
              color="error"
              onClick={fncSaveAllimjangNotice}
            >
              저장하기
            </Button>

            <Button
              className="btnPrevSave"
              variant="contained"
              onClick={fncDeleteNotice}
            >
              삭제하기
            </Button>
          </div>
        </div>
      </div>
    );
};

export default BoardWriteText;
