import { useNavigate } from 'react-router-dom';
import './Comment.scss';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import fnc from '../mymod/commFunction';
import CommentWrite from './CommentWrite';
import { UseSelector, useDispatch, useSelector } from 'react-redux';
import { setIsAdmin } from '../reduxToolkit/reduxVideo';
import BoardNotify from './BoardNotify';

const Comment = ({parentCode}) => {

const navigate = useNavigate();
const dispatch = useDispatch();
const [commentList, setCommentList] = useState('');
const stCode = fnc.getCookie('stCode');
const isAdmin = useSelector(state => state.reduxStore_video.isAdmin);
const isAnoymous = useSelector(state => state.reduxStore_video.isAnoymous);//익명 게시판
const [isModal, setIsModal] = useState(false);
const [selectedItem, setSelectedItem] = useState({});


useEffect(() => {
    fncSelectComment();
}, [parentCode]);

function onSelectedComment(){

    navigate('/menu');
}

function fncSelectComment() {

    fnc.sendAxios('/board_comment', { parentCode}, (res) => {
        if (res) {
            setCommentList(res);
        } else {
            console.log('속성이 obj 내에 없습니다.');
        }
    });
}

function onDelete(item) {
    const rtn = window.confirm('댓글을 삭제하시겠습니까?');
    if(!rtn) return;
    const code = item.code;
     fnc.sendAxios('/delete_board_comment', { code}, (res) => {
        if (res) {
            fncSelectComment();
        } else {
            console.log('속성이 obj 내에 없습니다.');
        }
    });
}

function onNotify(item) {
  // navigate('/boardNotify');
  setSelectedItem(item);
  setIsModal(true);
}
    //========================================
    return (
      <div className="Comment">
        <div className="box_comment">
          댓글 {commentList.length}
        </div>

        <CommentWrite parentCode={parentCode} onCommentAdded={fncSelectComment} />

        <div className="box_commentList">
          {commentList &&
            commentList.map((item) => (
              <div className="box_row" key={item.sno}>
                <div className="box-content">

                  <div className="box_topMenu">
                    <div className="box_date">
                      <div className="date">{item.date.replaceAll('-','.')}</div>
                      {/* <div className="name">{item.stName}</div> */}
                      <span className="name"> { isAnoymous ? '****' : item.stName}</span> 
                    </div>

                    <div className="menu">
                    {(stCode !== item.stCode || isAdmin) && <button className="btnDelete" onClick={() => {onNotify(item)}}> 
                      신고
                      </button>}

                      {(stCode === item.stCode || isAdmin) && <button className="btnDelete" onClick={() => {onDelete(item)}}> 
                      <i className="fas fa-trash"></i>
                      </button>}
                    </div>
                  </div>

                  <div>{item.content}</div>


                </div>

              
              </div>
            ))}

        {isModal && <div className='box_modal'>
                  <BoardNotify setIsModal={setIsModal} item = {selectedItem}/>
        </div> }   


        </div>

         
        
      </div>
    );
}

export default Comment