import './VideoGroupTopMenu.scss';
// import Button from '@mui/material/Button';
import Header from '../Header';
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedVideoGroupList, setSelectedKwamokCode, setSelectedStudyStepCode } from "../reduxToolkit/reduxVideo";
import fnc from '../mymod/commFunction';

const VideoGroupTopMenu = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [listStudySetp, setListStudyStep] = useState([]);
    const [listKwamok, setListKwamok] = useState([]);

    const thumbUrl= process.env.REACT_APP_THUMBNAIL_URL;

    const selectedKwamokCode = useSelector(state => state.reduxStore_video.selectedKwamokCode);
    const selectedStudyStepCode = useSelector(state => state.reduxStore_video.selectedStudyStepCode);

    useEffect(()=>{
        fncSelectStudyStep();
    }, []);

    

    function fncSelectStudyStep() {   
        fnc.sendAxios('/selectLearningPhase', { }, (res) => {
          if (res) {   
            setListStudyStep(res);   
          } else {
            console.log("속성이 obj 내에 없습니다.");
          }
        });
      }

      function fncSelectKwamokList(parentCode) {   
        fnc.sendAxios('/selectKwamok', { parentCode }, (res) => {
          if (res) {   
            // console.log(res);
            setListKwamok(res);   
          } else {
            console.log("속성이 obj 내에 없습니다.");
          }
        });
      }
  
      function onSelectKwamok(item) {
        fncSelectKwamokList(item.studyStepCode);
        dispatch(setSelectedStudyStepCode(item.studyStepCode));
        dispatch(setSelectedKwamokCode(''));
      }

      function onSelectVideoGroup(item) {
        dispatch(setSelectedKwamokCode(item.kwamokCode));
      }

//===============================================
return(
    <div className="VideoGroupTopMenu">
   
            <div className="wrap_btnKwamok" >
                {listStudySetp.map(item => (
                    <div className={`box_btnKwamok ${selectedStudyStepCode === item.studyStepCode ? 'active' : ''}`} key={item.studyStepCode}>                             
                        <div className="btnKwamok" onClick={() => { onSelectKwamok(item) }}>
                            {item.studyStep}
                        </div>
                    </div>
                ))}
            </div>

            {listKwamok.length > 0 && <div className="wrap_btnKwamok" >
                {listKwamok.map(item => (
                    <div className={`box_btnKwamok ${selectedKwamokCode === item.kwamokCode ? 'active' : ''}`} key={item.kwamokCode}>                             
                        <div className="btnKwamok" onClick={() => { onSelectVideoGroup(item) }}>
                            {item.kwamok}
                        </div>
                    </div>
                ))}
            </div>}
            
    </div>    

    );
  };
  

export default VideoGroupTopMenu;