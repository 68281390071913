import './ReservationMain.scss';
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import fnc from '../mymod/commFunction';
import { useDispatch, useSelector } from 'react-redux';
import ComboboxMui from '../comm/ComboboxMui';
import { Button } from '@mui/material';

import "react-datepicker/dist/react-datepicker.css";
import Checkbox from '@mui/material/Checkbox';
import { ko } from 'date-fns/locale';
import { format } from 'date-fns';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Dtpicker2 from '../comm/Dtpicker2';
import DatePicker from "react-datepicker";


const ReservationMain = () => {
    const navigate = useNavigate(); 
    const [list, setList] = useState([]);
    const [optionList, setOptionList] = useState({});
    const [startDate, setStartDate] = useState(new Date());

    const selectedClassCode = useSelector(state => state.reduxStore_st.selectedClassCode);
    const classCode = selectedClassCode ? selectedClassCode.classCode : '';//selectedClassCode 이거 객체로 사용
    const className = selectedClassCode ? selectedClassCode.className : '';
    const gangsaCode = selectedClassCode ? selectedClassCode.gangsaCode : '';
    const gangsaName = selectedClassCode ? selectedClassCode.gangsaName : '';

    const [selectedTime, setSelectedTime] = useState(null);
    const [duration, setDuration] = useState('');
    const stCode = fnc.getCookie('stCode');

    useEffect(() => {
      fncSelect_stList(classCode);
    }, [classCode, startDate]);

  //-----------------------------------------------------------selectList   
  function fncSelect_stList(classCode) {
    fnc.sendAxios('/select/managementSt_reservation/reservationListByClass_st', { classCode, stCode }, (res) => {
      if (res) {  
            setList(getRecords_stList(res));
      } else {
            console.log(res,"select/manageMentSt/class_stList");
      }
    });
  }


function getRecords_stList(res) {
    let records = [];
    for (let i = 0; i < res.length; i++) { 
        const sno = res[i]["sno"];  
        const classCode = res[i]["classCode"];  
        const className = res[i]["className"];  
        const gangsaCode = res[i]["gangsaCode"]; 
        const gangsaName = res[i]["gangsaName"]; 
        const stCode = res[i]["stCode"];  
        const stName = res[i]["stName"];         
        const reservationDate= res[i]["reservationDate"];    
        const reservationHour= res[i]["reservationHour"]; 
        const reservationTime= res[i]["reservationTime"];    
        const confirm= res[i]["confirm"];             

        records.push({ sno, classCode, className, gangsaCode, gangsaName, stCode, stName, 
          reservationDate, reservationHour, reservationTime, confirm });
    }
    return records;
  }

  //-----------------------------------------------------저장
  function onAddReservation() {

    if(!selectedTime) {
      alert('수업 시각을 선택하세요!');
      return;
    }

    if(!duration) {
      alert('시간을 선택하세요!');
      return;
    }

    const reservationDate = fnc.formatDate(startDate);
    const reservationHour = fnc.formatToAmPmTime(selectedTime);
    // const reservationHour = fnc.formatToTime(selectedTime);//지우지 말 것
    const reservationTime = duration;

    const rtn = window.confirm(`${reservationDate}(${reservationHour})에 \n예약을 추가하시겠습니까?`);
    if (!rtn) return;

    fncSave_reservation(stCode, classCode, className, gangsaCode, gangsaName, reservationDate, 
      reservationHour, reservationTime);
  }


  function fncSave_reservation(stCode, classCode, className, gangsaCode, gangsaName, reservationDate, 
      reservationHour, reservationTime) {
    
    fnc.sendAxios('/save/managementSt_reservation/reservation', 
    {   stCode, classCode, className, gangsaCode, gangsaName, reservationDate, 
        reservationHour, reservationTime
    }, (res) => {
        if (res) {  
          if (res.success == 'exist') {
            alert('이미 존재합니다. 삭제하고 하세요');
          } else {
            fncSelect_stList(classCode);
          }      
        } else {
            console.log(res, "select/manageMentSt/class_stList");
        }
    });
  }


  //--------------------------------------------------삭제
  function onDeleteReservation(item) {
    if (item.confirm == 'Y') {
      alert('승인된 항목은 삭제하실 수 없습니다.');
      return;
    }
    const rtn = window.confirm(`${item.reservationDate}(${item.reservationHour})의 \n예약을 삭제하시겠습니까?`);
    if (!rtn) return;
    fncDelete_reservation (item.sno);
  }

   
  function fncDelete_reservation (sno) {
    fnc.sendAxios('/delete/managementSt_reservation/reservation', 
      { sno }, (res) => {
      if (res) {  
        fncSelect_stList(classCode);
      } else {
            console.log(res,"select/manageMentSt/class_stList");
      }
    });
  }


  //--------------------------------------------------------
  const handleTimeChange = (time) => {
    if (time instanceof Date && !isNaN(time)) {
      setSelectedTime(time); // Date 객체를 유지
    } else {
      console.error("Invalid time value selected:", time);
      setSelectedTime(null);
    }
  };
  
  const handleChange = (event) => {
    setDuration(event.target.value);
  };
  //===================================================
  return (
   <div className="ReservationMain">
      <div className="wrap-main">
          <section className="section-main">
            <table className="table-hong table-main">
              <thead>
                <tr>
                  <th>{list.length}</th>
                  {/* <th>반명</th> */}
                  <th>성명</th>
                  <th>예약일</th>
                  <th>시각</th>
                  <th>시간(분)</th>
                  <th>삭제</th>
                  <th>승인</th>
                </tr>
              </thead>

              <tbody>
                  {list && list.map((item, index) => (
  
                      <tr key={index} >  
                        <td>{index+1}</td>
                        {/* <td>{item.className}</td> */}
                        <td>{item.stName}</td>
                        <td>{item.reservationDate}</td>
                        <td>{item.reservationHour}</td>
                        <td>{item.reservationTime}</td>
                      
                        <td>
                          <Button className='btn-menu btnDelete' onClick={() => onDeleteReservation(item)}>
                            <i class="fa-regular fa-trash-can"></i>
                          </Button>
                        </td>

                        <td>{item.confirm}</td>
 
                      </tr>
                  ))}
            
                </tbody>
            </table>
          </section>

          <section className='section-menu'>
                  <span className='reservationDay'>예약일</span>

                  <article className='article-dtp'>
                    <Dtpicker2 selectedDate={startDate} onDateChange={(date) => setStartDate(date)} />
                  </article>
                  

                  <article className="article-timePicker">
                    <DatePicker
                        selected={selectedTime}
                        onChange={handleTimeChange}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15} // 시간을 15분 간격으로 설정
                        timeCaption="Time"
                        dateFormat="aa h:mm" // 오전/오후 한글 형식
                        locale={ko} // 로케일을 한국어로 설정
                        className="timePicker"
                        placeholderText="시각 선택"
                    />
                  </article>

                  <article className="article-time">
                    <Select
                        labelId="duration-select-label"
                        id="duration-select"
                        value={duration}
                        onChange={handleChange}
                        label="Duration"
                        className="duration"
                        >
                        <MenuItem value={60}>60분</MenuItem>
                        <MenuItem value={120}>120분</MenuItem>
                    </Select>
                </article>
                <article className='article-menu'>
                    <Button className='btn-menu btnSave' onClick={() => onAddReservation()}>
                      <i class="fa-regular fa-floppy-disk"></i> &nbsp;저장
                      </Button>
                </article>
               

          </section>
        </div>

        
      </div>
  );

  //=============================================
  

}

export default ReservationMain;
