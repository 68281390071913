import styles from "./markingFastApp.css"; //css는 Top에 와야..
import axios from "axios";
import Button from "react-bootstrap/Button";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
// import { animated, useSpring } from "react-spring";
import Header from "../Header";

import MarkingFastTable from "./MarkingFastTable";
import MarkingFastMenu from "./MarkingFastMenu";
import MarkingMenuSpecial from "../marking/MarkingMenuSpecial";
import MarkingSetting from "../marking/MarkingSetting";
import Cookies from 'js-cookie';

const svrUrl = process.env.REACT_APP_SERVER_URL;

function MarkingFastApp() {
  const navigate = useNavigate(); 
  const location = useLocation();
  const testNm = location.state?.testNm;
  const munCnt= location.state?.munCnt;
  const [list, setList] = useState(null);
  const [selectedRow, setSelectedRow] = useState(1);
  const [isShowList, setIsShowList] = useState(true);
  const [no, setNo] = useState("1번");
  const [info, setInfo] = useState(''); 
  const [imageSize, setImageSize] = useState(300);
  const [showMarkingSetting, setShowMarkingSetting] = useState(false)
  const [chkNextMoonje, setChkNextMoonje] = useState(() => Cookies.get('chkNextMoonje'));
  const [imgSize, setImgSize] = useState(300);

  const [markingList, setMarkingList] = useState(() => {
    const initialState = {};
    for (let i = 1; i <= munCnt; i++) {
      initialState[i] = '';
    }
    return initialState;
  });

  const handleCloseSetting = () => {
    setShowMarkingSetting(false); 
  }
  
    
  function fncTestClose() {
    const confirmed = window.confirm('[빠른마킹]을 종료하시겠습니까?');
    if (confirmed) {
      navigate(-1);//이전으로 가기
    }
  }

    
 
//========================================================================
  return (
    <div className="MarkingFastApp">
      <div className="header-container">
        <Header onBackClick={() => fncTestClose()} title={testNm} />
      </div>

      <MarkingFastTable
       selectedRow={selectedRow}
       setSelectedRow={setSelectedRow}
       list={list}
       setList={setList}
       markingList={markingList}
       setMarkingList={setMarkingList}

      />


      <MarkingFastMenu     
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          setNo={setNo}
          setInfo={setInfo}
          isShowList={isShowList}
          setIsShowList={setIsShowList}
          list={list}
          imgSize={imgSize}
          setImgSize={setImgSize}
          markingList={markingList}
          setMarkingList={setMarkingList}
        />
   
     
      
    </div>
  );
}

export default MarkingFastApp;
