import React, { useEffect, useState } from 'react';
import './LikeButton.scss';
import fnc from '../mymod/commFunction';
import { useSelector, useDispatch } from 'react-redux';
import {setIsCurrentItemRefresh, setIsCurrentItemRefresh_faq} from '../reduxToolkit/reduxVideo';

function LikeButton({code, totalCnt, stLikeCnt}) {
    const [count, setCount] = useState(totalCnt);
    const [isActive, setIsActive] = useState(stLikeCnt);
    const stCode = fnc.getCookie('stCode');

    const dispatch = useDispatch();
    const isCurrentItemRefresh = useSelector(state => state.reduxStore_video.isCurrentItemRefresh);

    const toggleLike = () => {
        const newIsActive = !isActive;
        setIsActive(newIsActive);
        setCount(prevCount => newIsActive ? Number(prevCount) + 1 : Number(prevCount) - 1);
        fncInserBoard_like(newIsActive);
    };


    function fncInserBoard_like(isActive) {

        let isActiveInt = isActive ? 1 : 0;
        fnc.sendAxios('/insert_board_like', { userId: stCode, contentId: code, isActiveInt}, (response) => {       
          if (response) {
            dispatch(setIsCurrentItemRefresh(!isCurrentItemRefresh));
            
          } else {
            console.log("속성이 obj 내에 없습니다.");
          }
        });
      }


    return (
        <div className='LikeButton'>
            <button className={`like-button ${isActive ? 'active' : ''}`} onClick={toggleLike}>
                <span className="like-icon fas fa-heart"></span>
                <span className="like-count">{count}</span>
            </button>

        </div>
     
    );
}

export default LikeButton;
