import './ManagementSt_coaching_attend.scss';
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import fnc from '../mymod/commFunction';
import { useDispatch, useSelector } from 'react-redux';
import ComboboxMui from '../comm/ComboboxMui';
import { Button } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import "react-datepicker/dist/react-datepicker.css";
import {setSelectedDate} from  '../reduxToolkit/reduxSt';
import Dtpicker2 from '../comm/Dtpicker2';

const ManagementSt_coaching_attend = () => {
    const navigate = useNavigate(); 
    const [optionData, setOptionData] = useState([]);
    const [list, setList] = useState([]);
    const [optionList, setOptionList] = useState({});
    // const [startDate, setStartDate] = useState(new Date());
    const selectedDateString = useSelector(state => state.reduxStore_st.selectedDate);
    const [startDate, setStartDate] = useState(new Date(selectedDateString)); // 초기화 시에만 사용
    const [isRefresh, setIsRefresh] = useState(false);
    const selectedClassCode = useSelector(state => state.reduxStore_st.selectedClassCode);
    const attendReason = useSelector(state => state.reduxStore_st.attendReason);

    const classCode = selectedClassCode ? selectedClassCode.classCode : '';//selectedClassCode 이거 객체로 사용
    const className = selectedClassCode ? selectedClassCode.className : '';
    const gangsaCode = selectedClassCode ? selectedClassCode.gangsaCode : '';
    const gangsaName = selectedClassCode ? selectedClassCode.gangsaName : '';

    const dispatch = useDispatch();
    
    useEffect(()=> {
        setOptionData(attendReason);
    }, []);

    useEffect(() => {
      const reservationDate = fnc.formatDate(startDate);
      fncSelect_stList(classCode, reservationDate);
      dispatch(setSelectedDate(startDate.toISOString())); // 상태 업데이트
    }, [classCode, startDate, isRefresh]);

   
  
    // const optionData = [
    //   { value: '', label: '사유를 선택를 선택하세요' },
    //   { value: '병가', label: '병가' },
    //   { value: '개인사유', label: '개인사유' },
    //   { value: '기타', label: '기타' },
    // ];

  
    const handleOptionChange = (event, index) => {
      setOptionList({ ...optionList, [index]: event.target.value,  });
    };

  
    // function fncSelect_attend_reason() {
    //   fnc.sendAxios('/select/manageMentSt/attend_reason', {  }, (res) => {
    //     if (res) {  
    //       setOptionData(res);
    //     } else {
    //           console.log(res,"managementStRoutes/select/manageMentSt/attend_reason");
    //     }
    //   });
    // }     

      
    // function fncSelect_attend_reason() {
    //   const title = '결석사유';

    //   fnc.sendAxios('/select/manageMentSt/attend_reason_setCombo', { title }, (res) => {
    //     if (res) {  
    //         setOptionData(getRecords_attendReason(res));
    //     } else {
    //         console.log(res,"managementStRoutes/select/manageMentSt/attend_reason");
    //     }
    //   });
    // }

    // function getRecords_attendReason(res) {
    //   let records = [];
    //   // res가 배열인지 확인
    //   if (!Array.isArray(res)) { return records; }
    
    //   records.push({ value: '', label: '결석사유를 선택하세요!' });
    //   res.forEach(item => {
    //     const value = item["value"];  
    //     const label = item["label"];                
    //     records.push({ value, label });
    //   });
    
    //   return records;
    // }
    
  
  function fncSelect_stList(classCode, reservationDate) {

    fnc.sendAxios('/select/manageMentSt/class_stList_reservaion', { classCode, reservationDate }, (res) => {
      if (res) {  
            setList(getRecords_stList(res));
      } else {
            console.log(res,"select/manageMentSt/class_stList");
      }
    });
  }


function getRecords_stList(res) {
    let records = [];
    let initialOptionList = {}; // optionList 초기화 객체
    for (let i = 0; i < res.length; i++) { 
        const sno = res[i]["sno"];  
        const classCode = res[i]["classCode"];  
        const className = res[i]["className"];  
        const gangsaCode = res[i]["gangsaCode"]; 
        const gangsaName = res[i]["gangsaName"]; 
        const stCode = res[i]["stCode"];  
        const stName = res[i]["stName"];     
        const school = res[i]["school"];  
        const hp= res[i]["hp"];      
        const parentHp= res[i]["parentHp"];  
        const attend= res[i]["attend"];   
        const reason= res[i]["reason"];    
        const achievement= res[i]["achievement"];//성취도
        const diligence= res[i]["diligence"];//성실도  
        const progress= res[i]["progress"];//진행도
        // optionList에 reason 값을 추가
        initialOptionList[i] = reason || '';


        records.push({ sno, classCode, className, gangsaCode, gangsaName, stCode, stName, school, hp, parentHp, 
         attend, reason, achievement, diligence, progress });
    }
        //optionList를 초기화
        setOptionList(initialOptionList);

    return records;
  }


  
  function onSaveAttend_reservation() {
    const cnt = list.filter(item => item.attend.trim() !=='').length;
    const rtn = window.confirm(`출결을 저장하시겠습니까?`);
    if (!rtn) return;

    const total =  list.length;
    let completed = 0; // 완료된 삭제 작업을 추적

    for ( let k = 0; k < list.length; k++) {

      const sno = list[k].sno;
      const attend = list[k].attend;
      const reason = optionList[k] || '';

      const achievement = list[k].achievement || '';
      const diligence = list[k].diligence || '' ;
      const progress = list[k].progress || '';


      // optionList에 reason 값을 추가

      //-------------------------------------------
      const acaCode = fnc.getCookie('acaCode');
      const stCode = list[k].stCode;
      const lessonDate = fnc.formatDate(startDate);
      const classCode = list[k].classCode;
      const className = list[k].className;
      const gangsaCode = list[k].gangsaCode;
      const gangsaName = list[k].gangsaName;


      if (attend) {//정규반 출결 테이블
        fncSave_attend (acaCode, stCode, lessonDate, classCode, className, gangsaCode, gangsaName, 
          attend, reason, achievement, diligence, progress,
            () => {                 
                completed++;
                if (completed === total) {
                    setIsRefresh(!isRefresh);
                    alert('완료되었습니다.');
                }
            }
        );
      }


      // if (attend) {//코칭 테이블에 업데이트 (이건 없어도 될 것 같은데.)
      //   fncSave_attend_reservation (sno, attend, reason);
      // }
     
    }
  }


  
  function fncSave_attend (acaCode, stCode, lessonDate, classCode, className,  gangsaCode, gangsaName, 
    attend, reason, achievement, diligence, progress, callback) {
    fnc.sendAxios('/save/manageMentSt/attend', 
      { acaCode, stCode, lessonDate,  classCode, className,  gangsaCode, gangsaName, 
         attend, reason, achievement, diligence, progress }, (res) => {
      if (res) {  
            callback();
      } else {
            console.log(res,"select/manageMentSt/class_stList");
      }
    });
  }


  function onRefresh() {
    const reservationDate = fnc.formatDate(startDate);
    fncSelect_stList(classCode, reservationDate);
  }

    
  function onMoveDay(currDate, days) {

    setStartDate(fnc.moveDay(currDate, days));
  }

  const handleInputChange = (event, index, field) => {
      // 3글자 이상 입력하지 못하게 설정
    if (event.target.value <= 100) {
      const newList = [...list];
      newList[index] = {...newList[index], [field]: event.target.value};
      setList(newList);
    }
  };
  

  //===================================================
  return (
   <div className="ManagementSt_coaching_attend">
        <div className="wrap-main">
          <section className="section-main">
            <table className="table-hong table-main">
              <thead>
                <tr>
                  <th>{list.length}</th>
                  <th>성명</th>
                  <th>HP</th>
                  <th>출결</th>
                  <th>결석사유</th>
                  <th>성취도</th>
                  <th>성실도</th>
                  <th>진행도</th>
                </tr>
              </thead>

              <tbody>
                  {list && list.map((item, index) => (
  
                      <tr key={index} >  
                        <td>{index+1}</td>
                        <td>{item.stName}</td>
                        <td>{item.hp} </td>
                        <td className={
                          item.attend == '결석' ? 'attendRed':
                          item.attend == '인결' ? 'attendPink': 
                          item.attend == '조퇴' ? 'attendGreen': 
                          item.attend == '지각' ? 'attendBlue':''
                        } 
                          onClick={() => onClickAttend(item, index)}>{item.attend}
                        </td>

                        <td>
                            <ComboboxMui
                              label=''
                              value={optionList[index] || ''}
                              onChange={(event) => handleOptionChange(event, index)}
                              options={optionData}
                            />
                        </td>
                                            
                        {/* 성취도 입력란 */}
                        <td>
                          <input
                            className='achievement'
                            type="number"
                            value={list[index]?.achievement || ''}
                            onChange={(e) => handleInputChange(e, index, 'achievement')}
                          />
                        </td>

                        {/* 성실도 입력란 */}
                        <td>
                          <input
                            className='achievement'
                            type="number"                   
                            value={list[index]?.diligence || ''}
                            onChange={(e) => handleInputChange(e, index, 'diligence')}
                          />
                        </td>

                        {/* 진행도 입력란 */}
                        <td>
                          <input
                            className='achievement'
                            type="number"
                            value={list[index]?.progress || ''}
                            onChange={(e) => handleInputChange(e, index, 'progress')}
                          />
                        </td>
 
                      </tr>
                  ))}
            
                </tbody>
            </table>
          </section>

          <section className='section-menu'>
                  <label>수업일</label>
                  <Dtpicker2 selectedDate={startDate} onDateChange={(date) => setStartDate(date)} />

                  <Button className='btn-menu' onClick={onAllAttend}><i class="fa-solid fa-border-all"></i>출석</Button>
                  <Button className='btn-menu' onClick={onAllAbsence}><i class="fa-solid fa-border-all"></i>결석</Button>
                  
                  <Tooltip title="삭제는 반별 출결 조회에서 하세요" placement="top-start">
                    <Button className='btn-menu' onClick={onAllClear}><i class="fa-solid fa-eraser"></i>cls</Button>
                  </Tooltip>
                  <Button className='btn-menu btnRefresh' onClick={onRefresh}><i class="fa-solid fa-arrows-rotate"></i>새로</Button>
                  <Button className='btn-menu btnSave' onClick={onSaveAttend_reservation}><i class="fa-solid fa-floppy-disk"></i>저장</Button>

          </section>

        </div>


      </div>
  );

//===========================================================================

  
function onClickAttend(item, index) {//출결 변경

  const newList = [...list];
  
  switch(newList[index].attend) {
    case '':
      newList[index].attend = '출석';
      break;
    case '출석':
      newList[index].attend = '결석';
      break;
    case '결석':
        newList[index].attend = '지각';
        break;
    case '지각':
      newList[index].attend = '인결';
      break;

    case '인결':
        newList[index].attend = '조퇴';
      break;

    case '조퇴':
        newList[index].attend = '출석';
      break;
   
    default:
      console.log(`Unknown status: ${newList[index].checkedynSt}`);
  }
  setList(newList);
}


//--------전체출석

  function onAllAttend() {
    const newList = list.map(item => ({
      ...item, attend: '출석'
    }));
    setList(newList);
  }

  function onAllAbsence() {
    const newList = list.map(item => ({
      ...item, attend: '결석'
    }));
    setList(newList);
  }

  function onAllClear() {
    const newList = list.map(item => ({
      ...item, attend: ''
    }));
    setList(newList);
  }
}

export default ManagementSt_coaching_attend;
