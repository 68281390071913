import './ManagementSt_coaching.scss';
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import fnc from '../mymod/commFunction';
import { useDispatch, useSelector } from 'react-redux';
import ComboboxMui from '../comm/ComboboxMui';
import { Button } from '@mui/material';

import "react-datepicker/dist/react-datepicker.css";
import {setSelectedDate} from  '../reduxToolkit/reduxSt';
import Dtpicker2 from '../comm/Dtpicker2';

const ManagementSt_coaching = () => {
    const navigate = useNavigate(); 
    const [list, setList] = useState([]);
    const [optionList, setOptionList] = useState({});
    // const [startDate, setStartDate] = useState(new Date());
    const selectedDateString = useSelector(state => state.reduxStore_st.selectedDate);
    const [startDate, setStartDate] = useState(new Date(selectedDateString)); // 초기화 시에만 사용

    const selectedClassCode = useSelector(state => state.reduxStore_st.selectedClassCode);
    const classCode = selectedClassCode ? selectedClassCode.classCode : '';//selectedClassCode 이거 객체로 사용
    const className = selectedClassCode ? selectedClassCode.className : '';
    const gangsaCode = selectedClassCode ? selectedClassCode.gangsaCode : '';
    const gangsaName = selectedClassCode ? selectedClassCode.gangsaName : '';

    const [selectedTime, setSelectedTime] = useState(null);
    const [duration, setDuration] = useState('');
    const stCode = fnc.getCookie('stCode');
    const dispatch = useDispatch();

    useEffect(() => {
      fncSelect_stList(classCode);
      dispatch(setSelectedDate(startDate.toISOString())); // 상태 업데이트
    }, [classCode, startDate]);

  //-----------------------------------------------------------selectList   
  function fncSelect_stList(classCode) {
    const reservationDate = fnc.formatDate(startDate);

    fnc.sendAxios('/select/managementSt_reservation/reservationListByClass', { classCode, reservationDate }, (res) => {
      if (res) {  
        console.log(res);
            setList(getRecords_stList(res));
      } else {
            console.log(res,"select/manageMentSt/class_stList");
      }
    });
  }


function getRecords_stList(res) {
    let records = [];
    for (let i = 0; i < res.length; i++) { 
        const sno = res[i]["sno"];  
        const classCode = res[i]["classCode"];  
        const className = res[i]["className"];  
        const gangsaCode = res[i]["gangsaCode"]; 
        const gangsaName = res[i]["gangsaName"]; 
        const stCode = res[i]["stCode"];  
        const stName = res[i]["stName"];         
        const reservationDate= res[i]["reservationDate"];    
        const reservationHour= res[i]["reservationHour"]; 
        const reservationTime= res[i]["reservationTime"];    
        const confirm= res[i]["confirm"];    
        const weekDay = fnc.getWeekDay(reservationDate);      

        records.push({ sno, classCode, className, gangsaCode, gangsaName, stCode, stName, 
          reservationDate, weekDay, reservationHour, reservationTime, confirm });
    }
    return records;
  }

  //-----------------------------------------------------저장
  function onAddReservation() {

    if(!selectedTime) {
      alert('수업 시각을 선택하세요!');
      return;
    }

    if(!duration) {
      alert('시간을 선택하세요!');
      return;
    }

    const reservationDate = fnc.formatDate(startDate);
    const reservationHour = fnc.formatToAmPmTime(selectedTime);
    // const reservationHour = fnc.formatToTime(selectedTime);//지우지 말 것
    const reservationTime = duration;

    const rtn = window.confirm(`${reservationDate}(${reservationHour})에 \n예약을 추가하시겠습니까?`);
    if (!rtn) return;

    fncSave_reservation(stCode, classCode, className, gangsaCode, gangsaName, reservationDate, 
      reservationHour, reservationTime);
  }


  function fncSave_reservation(stCode, classCode, className, gangsaCode, gangsaName, reservationDate, 
      reservationHour, reservationTime) {
    
    fnc.sendAxios('/save/managementSt_reservation/reservation', 
    {   stCode, classCode, className, gangsaCode, gangsaName, reservationDate, 
        reservationHour, reservationTime
    }, (res) => {
        if (res) {  
          if (res.success == 'exist') {
            alert('이미 존재합니다. 삭제하고 하세요');
          } else {
            fncSelect_stList(classCode);
          }      
        } else {
            console.log(res, "select/manageMentSt/class_stList");
        }
    });
  }


  //--------------------------------------------------삭제
  function onDeleteReservation(item) {
    const stName = item.stName;
    if (item.confirm == 'Y') {
      const rtn = window.confirm(`승인 완료된 학생입니다. \n${stName}을 삭제하시겠습니까?`);
      if (!rtn) return;
    }

    const rtn = window.confirm(`${item.reservationDate}(${item.reservationHour})의 \n예약을 삭제하시겠습니까?`);
    if (!rtn) return;
    fncDelete_reservation (item.sno);
  }

   
  function fncDelete_reservation (sno) {
    fnc.sendAxios('/delete/managementSt_reservation/reservation', 
      { sno }, (res) => {
      if (res) {  
        fncSelect_stList(classCode);
      } else {
            console.log(res,"select/manageMentSt/class_stList");
      }
    });
  }


  //--------------------------------------------------------
  const handleTimeChange = (time) => {
    if (time instanceof Date && !isNaN(time)) {
      setSelectedTime(time); // Date 객체를 유지
    } else {
      console.error("Invalid time value selected:", time);
      setSelectedTime(null);
    }
  };
  
  const handleChange = (event) => {
    setDuration(event.target.value);
  };

  //--------------------------------------------------승인
  function onClickConfirmed(item, index) {//승인 변경

    const stName = item.stName;
    if (item.confirm == 'Y') {
      const rtn = window.confirm(`승인 완료된 학생입니다. \n${stName}을 수정하시겠습니까?`);
      if (!rtn) return;
    }

    const newList = [...list];
    newList[index].confirmed = newList[index].confirm || ''; // 기본 값 설정
    switch (newList[index].confirmed) {
      case '':
        newList[index].confirm = 'Y';
        fncUpdate_confirmed(item.sno, 'Y')
        break;
      case 'Y':
        newList[index].confirm = '';
        fncUpdate_confirmed(item.sno, '')
        break;
      default:
        console.log(`Unknown status: ${newList[index].confirm}`);
    }
    
    setList(newList);
  }

  
  function fncUpdate_confirmed(sno, value) {//출결 확정

    fnc.sendAxios('/update/managementSt_reservation/confirm', { sno, value }, (res) => {
      if (res) { 
        console.log(res, 'res');
      } else {
        console.log(res,"update/managementSt_reservation/confirm");
      }
    });
  }

  //-----------------------------datepicker
    
  function onMoveDay(currDate, days) {

    setStartDate(fnc.moveDay(currDate, days));
  }

  //===================================================
  return (
   <div className="ManagementSt_coaching">
        <div className="wrap-main">
          <section className="section-main">
            <table className="table-hong table-main">
              <thead>
                <tr>
                  <th>{list.length}</th>
                  {/* <th>반명</th> */}
                  
                  <th>예약일</th>
                  
                  <th>시각</th>
                  <th>시간(분)</th>
                  <th>성명</th>
                  <th>삭제</th>
                  <th>승인</th>
                </tr>
              </thead>

              <tbody>
                  {list && list.map((item, index) => (
  
                      <tr key={index} >  
                        <td>{index+1}</td>
                        {/* <td>{item.className}</td> */}
                        
                        <td>{item.reservationDate} ({item.weekDay})</td>
                        
                        <td>{item.reservationHour}</td>
                        <td>{item.reservationTime}</td>
                        <td>{item.stName}</td>
                      
                        <td>
                          <Button className='btn-menu btnDelete' onClick={() => onDeleteReservation(item)}>
                            <i class="fa-regular fa-trash-can"></i>
                          </Button>
                        </td>

                        <td onClick={() => onClickConfirmed(item, index)}>{item.confirm}</td>
 
                      </tr>
                  ))}
            
                </tbody>
            </table>
          </section>

          <section className='section-menu'>
                  <label>예약일</label>
                
                  <article className='article-dtp'>
                    <Dtpicker2 selectedDate={startDate} onDateChange={(date) => setStartDate(date)} />
                  </article>

          </section>

        </div>


      </div>
  );

  //=============================================
  

}

export default ManagementSt_coaching;
