import styles from "./markingFastMenu.css"; 
import axios from "axios";
import Button from "react-bootstrap/Button";
import React, { useEffect, useState, useRef  } from "react";
import MarkingMenuSpecial from "../marking/MarkingMenuSpecial";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Cookies from 'js-cookie';
import fnc from '../mymod/commFunction';

function MarkingFastMenu({ selectedRow,setSelectedRow, setNo, setInfo, isShowList, 
  setIsShowList, list, imgSize, setImgSize, markingList, setMarkingList  }) { 

  const location = useLocation();
  const testCode = location.state?.testCode;
  const munCnt= location.state?.munCnt;

  const [inputValue, setInputValue] = useState(''); 
  const [isShowSpecial, setIsShowSpecial] = useState(false);

  const navigate = useNavigate();
  const svrUrl = process.env.REACT_APP_SERVER_URL;

  useEffect(() => {
    const savedSize = Cookies.get('imgSize');
    if (savedSize) {
      setImgSize(Number(savedSize));
    }
  }, []);


  function fncTestClose() {
    const confirmed = window.confirm('시험을 종료하시겠습니까?');
    if (confirmed) {
      navigate("/markinglist");
    }
  }

  function fncShowList() {
    setIsShowList(!isShowList);
  }

  function fncCloseList() {
    setIsShowList(false);
  }


  function fncPrev() {
    if (list && selectedRow !== null) {
      const currentIndex = selectedRow - 1;  // 배열 인덱스는 0부터 시작하므로 -1을 합니다.
      if (currentIndex > 0) {
        const nextItem = list[currentIndex - 1];
        fncSelectRow(nextItem);
      } else {
        alert("처음입니다.");
      }
    }
  }

  function fncNext() {
      if (list && selectedRow !== null) {
        const nextIndex = selectedRow; // item.no가 인덱스 + 1 이므로
        if (nextIndex < list.length) {
          const nextItem = list[nextIndex];
          fncSelectRow(nextItem);
        } else {
          alert('마지막입니다!');
        }
      }
    }

    function fncHandleMarking(no, value) {
      setMarkingList((prevMarking) => {
        const updatedMarking = { ...prevMarking, [no]: value };
        localStorage.setItem(testCode, JSON.stringify(updatedMarking)); // fncSaveMarkingList(); 비동기라 한 탠포 늦게된다.
        return updatedMarking;
      });
      fncCloseList();
      let chkNextMoonje = Cookies.get("chkNextMoonje");
      fncSelectRow(list[no - 1]); //-1 해줘야 (고생)
      if (chkNextMoonje == 1) {
        fncNext();
      }
    }


  function fncInsertMarkingValue() {
    fncHandleMarking(selectedRow, inputValue);
    setInputValue('');  // 입력 완료 후 input 값을 초기화
  }

  function fncInputValueChange(e) {
    setInputValue(e.target.value);
  }
    
  function fncSelectRow(item) {
    if (!item) return false;
    setSelectedRow(item.no);
    setNo(item.no + '번');
    setInfo(markingList[item.no]);
    
  }

 function fncSubmit() {
    const rtn = window.confirm('시험을 제출하시겠습니까?');
    if (!rtn) return false;

    let oxArr = [];
    let dapArr = [];
    let markingArr= [];
    let Ocnt = 0;
    let Xcnt = 0;
    //const 변수는 선언과 동시에 초기화해야 합니다.
    list.forEach((item) => {
      const submittedAnswer = markingList[item.no];
      const correctAnswer = item.정답;
      
      // 배열을 문자열로 변환하여 비교
      if (JSON.stringify(submittedAnswer) === JSON.stringify(correctAnswer)) {
        oxArr.push('O');
        Ocnt++;
      } else {
        oxArr.push('X');
        Xcnt++;
      }       
      dapArr.push(correctAnswer);// 정답도 저장
      markingArr.push(submittedAnswer)
});

    const dapBar= dapArr.join('|');
    const markingBar= markingArr.join('|');
    const oxBar= oxArr.join('|');
    let score = (Ocnt / dapArr.length) * 100;
    score = Math.round(score * 100) / 100;
   
    fncInsert_te_marking(score, Ocnt, Xcnt, dapBar, markingBar, oxBar);
    // fncUpdateTest(); //그냥 테스트 해 봄

  }

  function fncInsert_te_marking(점수, 맞은개수, 틀린개수, 정답, 마킹, OX) {
    const 시험지코드 = testCode;
    const 학생고유번호 = fnc.getCookie('stCode');

    sendAxios("/insert_te_Marking", { 시험지코드, 학생고유번호, 점수, 맞은개수, 틀린개수, 정답, 마킹, OX }, (response) => {
      if (response) {
        console.log(response);
        let result = response.result;
        if (result === 'success') {
          alert('완료되었습니다.');
          navigate('/markinglist');
        }
        
      } else {
        console.log("속성이 obj 내에 없습니다.");
      }
    });
  }

    
  const sendAxios = async (url, data, callback) => {//이건 하나만 있으면 됨

    try {
      const response = await axios.post(`${svrUrl}${url}`, data);
      let jsonObj = response.data;
      callback(jsonObj);
    } catch (error) {
      console.error("Axios 요청 중 오류가 발생했습니다:", error);
    }
  };



  function fncUpdateTest() {//테스트해 본 거 다 지우도록
    let sno, LastName;
    sno='2';
    LastName="홍대화'3";
    sendAxios("/updateTest", { sno, LastName }, (response) => {
      if (response) {
        console.log(response);
      } else {
        console.log("속성이 obj 내에 없습니다.");
      }
    });
  }



  const fncResetMarkingList = () => {
    let rtn = window.confirm('마킹을 초기화하시겠습니까?');
    if (!rtn) return false;
    const newState = {};
    for (let i = 1; i <= munCnt; i++) {
      newState[i] = '';
    }
    setMarkingList(newState);
  };

  const fncSaveMarkingList = () => {
    localStorage.setItem(testCode, JSON.stringify(markingList));//localStorage에 저장
  }

 const fncResoreMarkingList = () => {//마킹 복원하기
  let rtn = window.confirm('이전 마킹을 가져오시겠습니까?');
  if (!rtn) return false;

   const storedMarkingList = JSON.parse(localStorage.getItem(testCode));
   setMarkingList(storedMarkingList);
   if (storedMarkingList) {
     setMarkingList(storedMarkingList);
    //  alert("완료되었습니다.");
     fncShowList();
   } else {
     alert("저장된 데이터가 없습니다.");
   }
 }
  
  return (
    <div className="MarkingMenu">
       
      <div className="wrap-footer">
          

          <div className="box-footer">
            <button className="btn btn_number " onClick={() => fncHandleMarking(selectedRow,'1')}>1</button>
            <button className="btn btn_number" onClick={() => fncHandleMarking(selectedRow,'2')}>2</button>
            <button className="btn btn_number" onClick={() => fncHandleMarking(selectedRow,'3')}>3</button>
            <button className="btn btn_number" onClick={() => fncHandleMarking(selectedRow,'4')}>4</button>
            <button className="btn btn_number" onClick={() => fncHandleMarking(selectedRow,'5')}>5</button>
            <button className="btn btn_number" onClick={() => fncHandleMarking(selectedRow,'?')}>?</button>
            <button className="btn btn_submit" onClick={() => {fncSubmit();}}>제출</button>
          </div>

          <div className="box-special-footer">
            <input type="text" className="txtmarking" value={inputValue} onChange={fncInputValueChange} autoComplete="off" />
            <button className="btn_special_footer" onClick={() => {setInputValue('')}}>cls</button>
      
            <button className="btn_special_footer" onClick={fncInsertMarkingValue}>넣기</button>
            {false && <button className="btn_special_footer" onClick={() => {setIsShowSpecial(true)}}>※</button>}
            <button className="btn_special_footer" onClick={() => {fncResetMarkingList();}}>리셋</button>
            <button className="btn_special_footer" onClick={() => {fncResoreMarkingList();}}>복원</button>
            
          </div>
      </div>


      { isShowSpecial && (
        <MarkingMenuSpecial 
          setInputValue={setInputValue} 
          isShowSpecial={isShowSpecial}
          setIsShowSpecial={setIsShowSpecial}
        />
      )}


    </div>
  );
}

export default MarkingFastMenu;
