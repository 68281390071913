import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function useWarnBeforeLeaving(shouldWarn) {
  const location = useLocation();

  useEffect(() => {
    if (!shouldWarn) {
      return;
    }

    const handleBeforeUnload = (event) => {
      const message = 'Are you sure you want to leave? Changes you made may not be saved.';
      event.returnValue = message;
      return message;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [shouldWarn, location.pathname]);
}

export default useWarnBeforeLeaving;
