
import * as S from '../scss/theme';
import React, { useEffect, useState, useRef } from 'react';
import PaginatedItems from './PaginatedItems';
import axios from "axios";
import Cookies from 'js-cookie'; 
import fnc from '../mymod/commFunction';
import Button from '@mui/material/Button';

import { useSelector, useDispatch } from 'react-redux';
import { setBoardTitle } from "../reduxToolkit/reduxVideo";
import Header from '../Header';
import { useNavigate } from 'react-router-dom';


  const Qna = () => {

    const svrUrl = process.env.REACT_APP_SERVER_URL;
    const [list, setList] = useState(null);
    const [items, setItems] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isCurrentItemRefresh = useSelector(state => state.reduxStore_video.isCurrentItemRefresh);
    const selectedVideoList = useSelector(state => state.reduxStore_video.selectedVideoList);
    
    useEffect(() => {
      fncSelectList();
      // alert(selectedVideoList.code)
    }, [isCurrentItemRefresh]);
  
    function onPrev() {
      navigate('/menu');
    }
    
      function fncSelectList() {   
        const stCode = fnc.getCookie('stCode');
        const kind ='qna';
        fnc.sendAxios('/boardList_qna', { stCode, kind, videoCode:selectedVideoList.code }, (response) => {

          if (response) {
            dispatch(setBoardTitle({text:'질문 게시판', value: kind, textEng:'Q&A'}));
            setItems(response);
            // console.log(response, '--- response11');

          } else {
            console.log("속성이 obj 내에 없습니다.");
          }
        });
      }
  
  
      //-------------------------------------------------------------------------
  
      return (
        <div className="Qna">
          <S.Section>
          {/* <Header onBackClick={onPrev} title='질문 게시판' />   */}
          {/* <Header onBackClick={() => toggleClassNameQna(false)} title='질문 게시판' /> */}
          {/* Header가 여기있어야 깜빡임이 없다.*/}

          {/* 24-09-12 수정 */}
         {/* { items && <PaginatedItems items={items} itemsPerPage={20} /> }    */}

         {Array.isArray(items) && items.length > 0 && (
          <PaginatedItems items={items} itemsPerPage={20} />
          )}

          </S.Section>
        </div>
      );

}

export default Qna;
