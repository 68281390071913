import './Dtpicker2.scss'; // 토요일, 일요일 색상 지정 CSS 파일
import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { registerLocale } from 'react-datepicker';
import ko from 'date-fns/locale/ko';
import 'react-datepicker/dist/react-datepicker.css';
import { Button } from '@mui/material';
import fnc from '../mymod/commFunction';

// 한국어 로케일 등록
registerLocale('ko', ko);

const Dtpicker2 = ({ selectedDate, onDateChange }) => {
  const [startDate, setStartDate] = useState(selectedDate || null);

  useEffect(() => {
    setStartDate(selectedDate);
  }, [selectedDate]);

  const handleDateChange = (date) => {
    setStartDate(date);
    onDateChange(date);
  };

  const handleTodayClick = () => {
    const today = new Date();
    handleDateChange(today);
  };

  const onMoveDay = (currDate, days) => {
    const newDate = fnc.moveDay(currDate, days);
    handleDateChange(newDate);
  };

  const getDayClass = (date) => {
    const day = date.getDay();
    if (day === 6) return 'react-datepicker__day--saturday'; // 토요일
    if (day === 0) return 'react-datepicker__day--sunday';   // 일요일
    return '';
  };

  //=====================================================

  return (
    <section className='section-dtpiker2'>

      <Button className='btnNextPrev' onClick={() => onMoveDay(startDate, -1)}>
        <i className="fa-solid fa-chevron-right fa-rotate-180"></i>
      </Button>

    <article className='article-dtpiker2'>
      <DatePicker
        selected={startDate}
        onChange={handleDateChange}
        dateFormat="yyyy-MM-dd"
        className="datePicker2"
        enableTabLoop={false}
        calendarStartDay={1} // 달력이 월요일부터 시작하도록 설정
        locale="ko"
        // onKeyDown={(e) => e.preventDefault()} // 키보드 입력 방지
        // onFocus={(e) => e.target.blur()} // 포커스를 제거하여 직접 입력 방지

        dayClassName={(date) => {
          return getDayClass(date); // 요일에 따라 클래스를 반환
        }}
      
      />
      <p className={`week ${getDayClass(startDate)}`}>{fnc.getWeekDay(startDate)}</p>

      </article>

      <Button className='btnNextPrev' onClick={handleTodayClick}>
        <i className="fa-solid fa-pause"></i>
      </Button>

      <Button className='btnNextPrev' onClick={() => onMoveDay(startDate, 1)}>
        <i className="fa-solid fa-chevron-right"></i>
      </Button>

    </section>
  );
};

export default Dtpicker2;
