import React, { useState } from 'react';

import './BoardListFaq.scss';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedList, setInitialPage } from "../reduxToolkit/reduxVideo";
import * as Accordion from '@radix-ui/react-accordion';
import LikeButton from './LikeButton';
import Views from './Views';
import Comment from './Comment';
import fnc from '../mymod/commFunction';
import Header from '../Header';

const BoardListFaq = ({currentItems, setModalOpen, setSelectedItem}) => {
//Faq는 사용 안 하는 듯 Qna 컨포넌트로 통합된 듯
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const stCode = fnc.getCookie('stCode');
    const boardTitle = useSelector(state => state.reduxStore_video.boardTitle);
    const isAdmin = useSelector(state => state.reduxStore_video.isAdmin);
    const [viewCnt, setViewCnt] = useState(0);
    const [expandedValue, setExpandedValue] = useState(null);//펼쳐졌는지 검사

    const onBoardWriteText = () => {
        navigate('/boardWriteText'); 
      };

      const onDeleteClick = (item) => {
        dispatch(setSelectedList({ 
            sno:item.sno, 
            title:item.title, 
            category:item.category,
            content:item.content, 
            date:item.date,
            kind:item.kind
          }));

        navigate('/boardUpdateDeleteText'); 
      };

      function onClickHeader(value, index){//value=item.sno임, 접힐 때는 null이 나옴
        if (value && value !== expandedValue) {
          setViewCnt(currentItems[index].viewCnt + 1);
          setExpandedValue(value);
          fncInsert_views(value, index);
        } else {
          setExpandedValue(null);
        }
      }

      

      function fncInsert_views(parentCode, index) {
        fnc.sendAxios('/insert_board_view',{parentCode},(res) => {
          if(res){
            currentItems[index].viewCnt = (currentItems[index].viewCnt || 0) + 1;
          }
        
        });
      }
    //=====================================================================================
    //사용 안 하는 듯 (BoardListQna.js사용)
    return (
      <div className="BoardListFaq">

        {
          <div className="BoardList">
            {true && (
              <div className="box_write">
                <Button
                  variant=""
                  startIcon={<i className="fas fa-edit"></i>}
                  onClick={onBoardWriteText}
                >
                  글쓰기
                </Button>
              </div>
            )}

            <Accordion.Root type="single" collapsible className="AccordionRoot" > 
              {currentItems.map((item, index) => (
                <Accordion.Item
                  key={item.sno}
                  value={item.code}
                  className="AccordionItem"
                  
                  onClick={() => onClickHeader(item.code, index)}
                >
                  <Accordion.Header className="AccordionHeader">
                    <Accordion.Trigger
                     
                      className="AccordionTrigger"
                    >
                      {item.title}
                      <i className="fas fa-chevron-up AccordionIcon"></i>
                    </Accordion.Trigger>
                  </Accordion.Header>
                  <Accordion.Content className="AccordionContent" >
                    <div className="box-content">
                      <div className="box_topMenu">
                        <div className="box_date">
                          <div className="date">{item.date.replaceAll('-','.')}</div>
                          <div className="name">{item.stName}</div>
                          <div className="viewCnt">조회수 {viewCnt}</div>
                        </div>
                        <div className="menu">
                          {(stCode === item.stCode || isAdmin) && (
                            <button
                              onClick={() => onDeleteClick(item)}
                              className="btnDelete"
                            >
                              Modify | Delete
                            </button>
                          )}
                        </div>
                      </div>

                      <div className="content">
                        {item.content}
                        <LikeButton
                          code={item.code}
                          totalCnt={item.likeCnt}
                          stLikeCnt={item.likeCnt}
                        />
            
                        <Comment parentCode={item.code} />
                      </div>
                    </div>
                  </Accordion.Content>
                </Accordion.Item>
              ))}
            </Accordion.Root>
          </div>
        }
      </div>
    );
  };
  
export default BoardListFaq;
