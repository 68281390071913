// import  './App.css';

import React, { useEffect } from 'react';
import { Routes, Route } from "react-router-dom";
import Login from "./login/Login.js";
import Menu from './Menu.js';
import MarkingList from "./marking/MarkingList.js";
import MarkingList2 from "./marking2/MarkingList2.js";

import MarkingSt from "./marking/MarkingSt.js";
import MarkingApp from "./marking/MarkingApp.js";
import MarkingApp2 from "./marking2/MarkingApp2.js";
import MarkingStudyApp from "./markingStudy/MarkingStudyApp.js";
import MarkingStudyApp2 from "./markingStudy2/MarkingStudyApp2.js";
import MarkingFastApp from "./markingFast/MarkingFastApp.js"
import SvgDraw from './drawSvg/draw.js';
import SvgDrawMouse from './drawSvg/drawMouse.js';


import VideoGroup from "./video/VideoGroup.js";
import VideoList from "./video/VideoList.js";
import VideoPlay from "./video/VideoPlay.js";
import VideoPlay2 from "./video/VideoPlay2.js";
import VideoReact from "./video/VideoReact.js";
import VideoStudyList from "./video/VideoStudyList.js";
import PrivacyPolicy from "./comm/PrivacyPolicy.js";

import BoardListFaq from "./BoardDetailWrite/BoardListFaq.js";
import Faq from "./BoardDetailWrite/Faq.js";
import BoardUpdateDeleteText from "./BoardDetailWrite/BoardUpdateDeleteText.js";
import BoardWriteText from "./BoardDetailWrite/BoardWriteText.js";
import BoardNotify from "./BoardDetailWrite/BoardNotify.js";
import ManagementStApp from './managementSt/ManagementStApp.js';
import ManagementSt_attend from './managementSt/ManagementSt_attend.js';
import ReservationApp from './managementSt_reservation/ReservationApp.js';
import ManagementSt_smsApp from './managementSt/ManagementSt_smsApp.js';



import { useSelector, useDispatch } from 'react-redux';
import { setVersion} from "./reduxToolkit/reduxVideo";


function App() {

  const dispatch = useDispatch();

  
  window.AppVersion_ios = (version) => {
    if (version){
      dispatch(setVersion(version));
    }
  };

  
  window.AppVersion = (version) => {
    // alert(version);
    // dispatch(setVersion(version));
    if (version){
      dispatch(setVersion(version));
    }
  };


  
  //-------------------------------------------------------------최신 업데이트
  useEffect(() => {//잘 안 되면 build 폴더를 지우고 추가해봐(앞으로 빼서 그런지 되긴 한다.)

    const currentVersion = localStorage.getItem('updateVersion');
    const newVersion = '1.0.14';

    if (currentVersion !== newVersion) {
      localStorage.setItem('updateVersion', newVersion); 
      window.location.reload(true); // 강제 새로고침, 사파리는 어떤지 확인 필요
    }
  }, []);

//이 앱은 노치가 필요 없어 global.css에서 
  return (
    <div className="App">
      <Routes>     
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/markinglist" element={<MarkingList />} />
        <Route path="/markinglist2" element={<MarkingList2 />} />

        <Route path="/markingst" element={<MarkingSt />} />
        <Route path='/markingapp' element={<MarkingApp />}/>
        <Route path='/markingapp2' element={<MarkingApp2 />}/>

        <Route path='/markingfastapp' element={<MarkingFastApp />} />
        <Route path='/markingstudyapp' element={<MarkingStudyApp />} />
        <Route path='/markingstudyapp2' element={<MarkingStudyApp2 />} />
        <Route path='/svgdraw' element={<SvgDraw />} />
        <Route path='/svgdrawmouse' element={<SvgDrawMouse />} />

        <Route path="/videoplay" element={<VideoPlay />} />
        <Route path="/videoplay2" element={<VideoPlay2 />} />
        <Route path="/videoList" element={<VideoList />} />
        <Route path="/videoReact" element={<VideoReact />} />

        <Route path="/videoGroup" element={<VideoGroup />} />
        <Route path='/videoStudyList' element={<VideoStudyList />} />
        <Route path='/privacyPolicy' element={<PrivacyPolicy />} />

        <Route path='/faq' element={<Faq />} />
        <Route path='/boardListFaq' element={<BoardListFaq />} />
        <Route path='/boardUpdateDeleteText' element={<BoardUpdateDeleteText />} />
        <Route path='/boardWriteText' element={<BoardWriteText />} />
        <Route path='/boardNotify' element={<BoardNotify />} />
        <Route path='/*' element={<Login />} />
        <Route path='/managementSt' element={<ManagementStApp />} />
        <Route path='/ManagementSt_attend' element={<ManagementSt_attend />} />
        <Route path='/reservationApp' element={<ReservationApp />} />
        <Route path='/managementSt_sms' element={<ManagementSt_smsApp />} />
        
        
        
      </Routes>
      {/* <div className='global-margin-bottom-scroll'/> */}

    </div>
  );
}

export default App;
