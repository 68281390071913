// import './DrawNormal';
import './DrawMenu.scss';
import React, { useEffect, useState, useRef, useCallback  } from 'react';

import redux, {setIsShowList, setCurrentColor, setHighlightMode, setMode, setUndo, setRedo, setClear, 
  setPrev, setNext, } from "../reduxToolkit/redux";
import { useDispatch, useSelector } from "react-redux";

const DrawMenu = ({ }) => {
  const DRAW_MODE = "draw";
  const ERASE_MODE = "erase";
  const [activeButton, setActiveButton] = useState("btnBlack");
  const dispatch = useDispatch();
  const currentColor = useSelector(state => state.reduxStore.currentColor);

  useEffect(() => {
      setActiveButton(currentColor);  
  }, [currentColor]);

  const handleButtonPress = useCallback((color, buttonId) => {
    setActiveButton(buttonId);
    dispatch(setCurrentColor(color));
    dispatch(setMode(DRAW_MODE));

   
    if (color === "yellow" || color === "greenyellow") {
      dispatch(setHighlightMode(true));
    } else {
      dispatch(setHighlightMode(false));
    }


  }, []);

  function handleButtonPressList(color) {
    if (color === 'list') {
      dispatch(setIsShowList(true));
    }
  }

  function handleEraseClick() {
    dispatch(setMode(ERASE_MODE));
    setActiveButton("eraser-active"); // activeButton을 'eraser-active'로 설정
  }

  function onUndo() {
    dispatch(setUndo(true));
  }

  function onRedo() {
    dispatch(setRedo(true));
  }

  function onClear() {//DrawNormal.js에서 실행 함
    dispatch(setClear(true));
  }

  function onNext() {
    dispatch(setNext(true));
  }

  function onPrev() {
    dispatch(setPrev(true));
  }

  
  //===============================================================================
    return (
    <div className='DrawMenu'>
 
      <div className='box-menu'>
        
        <input  type="button" 
          className={'btnList'} value={''} onClick={() => handleButtonPressList("list", "save")} //setIsShowList(사용안함) 
        />

        <input  type="button" //btnActive로 하면 배경 엑티브 색이 나온다.
          className={`btnColor btnBlack ${activeButton === 'black' ? 'btnActive2' : ''}`}   
          onClick={() => handleButtonPress("black", "black")} 
        />
        
        <input type="button" 
          className={`btnColor btnRed ${activeButton === 'red' ? 'btnActive2' : ''}`}  
          onClick={() => handleButtonPress("red", "red")} 
        />

        <input 
          className={`btnColor btnBlue ${activeButton === 'blue' ? 'btnActive2' : ''}`}
          type="button" 
          onClick={() => handleButtonPress("blue", "blue")} 
        />

      <input 
          className={`btnColor btnGreen ${activeButton === 'green' ? 'btnActive2' : ''}`}
          type="button" 
          onClick={() => {handleButtonPress("green", "green"); }} 
        />

        <input 
          className={`btnColor btnYellow ${activeButton === 'yellow' ? 'btnYellow-active' : ''}`}
          type="button" 
          onClick={() => {handleButtonPress("yellow", "yellow"); }} 
        />

       
         <input 
          className={`btnColor btnGreenyellow ${activeButton === 'greenyellow' ? 'btnGreenyellow-active' : ''}`}
          type="button" 
          onClick={() => {handleButtonPress("greenyellow", "greenyellow"); }} 
        />
         <input type="button" 
          className={`btnEraser ${activeButton === 'eraser-active' ? 'btnEraser-active' : 'btnEraser'}`}  
          onClick={() => handleEraseClick()} 
        />
        <input type="button" className="btnUndo" onClick={onUndo} />
        <input type="button" className="btnRedo" onClick={onRedo} />
        <input type="button" className="btnClear" onClick={onClear} />
          {/* <input type="button" className='btnZoomIn' onClick={zoomIn} value="Zoom In"/>
          <input type="button" className='btnZoomOut' onClick={zoomOut} value="Zoom Out"/> */}
       </div>
      </div>
  );
}

export default DrawMenu;
