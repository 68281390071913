import React, { useRef, useEffect, useState } from 'react';
import { createEraserCircle, updateEraserCircle, removeEraserCircle } from './drawFnc';
import './draw.css';
const DRAW_MODE = 'draw';
const ERASE_MODE = 'erase';


const getMidPoint = (point1, point2) => ({
  x: (point1.x + point2.x) / 2,
  y: (point1.y + point2.y) / 2,
});

const SvgDraw = () => {
  const svgRef = useRef(null);
  const points = useRef([]);
  const [mode, setMode] = useState(DRAW_MODE);
  const colorRef = useRef("black");
  const highlightModeRef = useRef(false);
  const [currentColor, setCurrentColor] = useState('black');
  const [activeButton, setActiveButton] = useState('btnBlack');
  const drawnPaths = useRef([]); // 그려진 경로를 저장할 배열
  const removedPaths = useRef([]); // 지워진 경로를 저장하기 위한 배열
  const [viewBox, setViewBox] = useState({ x: 0, y: 0, width: 800, height: 400 });
  const [imgWidth, setImgWidth] = useState(500);


  let currentPath;

  const deletePath = (x, y) => {
    const svg = svgRef.current;
    const paths = svg.getElementsByTagName("path");

    for (const path of paths) { 
      const pathData = path.getAttribute("d");
      const pathDataArray = pathData.split(" ");
  
      for (let i = 1; i < pathDataArray.length - 1; i++) {
        const lineData = pathDataArray[i].split(",");
        const lineX = parseFloat(lineData[0]);
        const lineY = parseFloat(lineData[1]);
  
        if (Math.abs(lineX - x) < 15 && Math.abs(lineY - y) < 15) {
          removedPaths.current.push(path);  // 지운 path를 removedPaths 배열에 추가
          svg.removeChild(path);   
          return;  // Return here if you want to remove only one path
        }
      }
    }
  };

  const handleDrawing = (clientX, clientY, type) => {

    const svg = svgRef.current;
    const pt = svg.createSVGPoint();
    pt.x = clientX;
    pt.y = clientY;
    const svgP = pt.matrixTransform(svg.getScreenCTM().inverse());

    const { x, y } = svgP;

    if (mode === ERASE_MODE) {
      updateEraserCircle(x, y);  // 원 위치 업데이트
      deletePath(x, y);
      if (type === 'start') {
        createEraserCircle(x, y, svg);
      }
      return;
    }

    if (type === 'start') {
      points.current = [{ x, y }];
      currentPath = document.createElementNS('http://www.w3.org/2000/svg', 'path');
        
      // Set attributes for the path
      let strokeWidth = 2;
      let strokeOpacity = 1;
      if (highlightModeRef.current) {
        strokeWidth = 15;
        strokeOpacity = 0.5;
      }
      currentPath.setAttribute('stroke-width', strokeWidth);
      currentPath.setAttribute('stroke-opacity', strokeOpacity);
      currentPath.setAttribute('stroke', colorRef.current);
      currentPath.setAttribute('fill', 'none');
      currentPath.setAttribute('d', `M ${x},${y}`);

         // 블렌드 모드 적용
    if (colorRef.current === "yellow" || colorRef.current === "greenyellow") {
      currentPath.style.mixBlendMode = 'multiply';
    }

      svg.appendChild(currentPath);
      drawnPaths.current.push(currentPath); // 그려진 경로를 drawnPaths 배열에 저장

   
    } else if (type === 'move') {

      points.current.push({ x, y });
      if (points.current.length < 3) {
        return;
      }

      const [point1, point2, point3] = points.current.slice(-3);
      const midPoint1 = getMidPoint(point1, point2);
      const midPoint2 = getMidPoint(point2, point3);
      const d = currentPath.getAttribute('d') || ''; // 'd' 속성이 null일 경우 대비
      const curve = ` C ${midPoint1.x},${midPoint1.y} ${point2.x},${point2.y} ${midPoint2.x},${midPoint2.y}`;
      currentPath.setAttribute('d', `${d} ${curve}`);

    }

  };
 
 
  useEffect(() => {
    const svg = svgRef.current;
    const touchStart = (e) => {
     
      if (!e.touches || e.touches.length === 0 || e.touches[0].radiusX !== 0) {
        return; // Ignore if it's not a pen
      }

      if (e.touches[0].radiusX === 0) {  // Replace with your condition for pen
        e.preventDefault();
        const { clientX, clientY } = e.touches[0];
        handleDrawing(clientX, clientY, 'start');
      }
    };

    const touchMove = (e) => {
      if (!e.touches || e.touches.length === 0 || e.touches[0].radiusX !== 0) {
        return; // Ignore if it's not a pen
      }

      if (e.touches[0].radiusX === 0) {  // Replace with your condition for pen
        e.preventDefault();
        const { clientX, clientY } = e.touches[0];
        handleDrawing(clientX, clientY, 'move');
      }
    };

    const touchEnd = () => {
      points.current = [];
      removeEraserCircle(svgRef.current);  // 원 제거
    };

    svg.addEventListener('touchstart', touchStart);
    svg.addEventListener('touchmove', touchMove);
    svg.addEventListener('touchend', touchEnd);

    return () => {
      svg.removeEventListener('touchstart', touchStart);
      svg.removeEventListener('touchmove', touchMove);
      svg.removeEventListener('touchend', touchEnd);
    };
  }, [mode]);

//-----------------------------------------changeColor
  const changeColorAndMode = (newColor) => {
    if (mode !== DRAW_MODE) {
      setMode(DRAW_MODE);
    }
    colorRef.current = newColor;
  };

  const handleButtonPress = (color, buttonId) => {
    setCurrentColor(color);
    setActiveButton(buttonId);
    changeColorAndMode(color);

    if (color ==='yellow' || color ==='greenyellow') {
      highlightModeRef.current=true;
    }else {
      highlightModeRef.current=false;
    }
    
  };

  const handleEraseClick = () => {
    setMode(ERASE_MODE);  // 여기에 ERASE_MODE를 설정하는 코드를 추가해야 합니다.
    setActiveButton('eraser-active'); // activeButton을 'eraser-active'로 설정
  };

  const undo = () => {
    const svg = svgRef.current;
    const paths = svg.querySelectorAll('path');
    if (paths.length > 0) {
      const latestPath = paths[paths.length - 1];
      removedPaths.current.push(latestPath);
      svg.removeChild(latestPath);
    }
  };

  const redo = () => {
    const svg = svgRef.current;
    if (removedPaths.current.length > 0) {
      const latestRemovedPath = removedPaths.current.pop();
      svg.appendChild(latestRemovedPath);
    }
  };

   // clearCanvas 함수 추가
   const clear = () => {
    const rtn = window.confirm('메모 전체를 지우시겠습니까? \n(배경 내용은 유지됩니다.)');
    if (!rtn) return false;
    const svg = svgRef.current;
    const paths = svg.getElementsByTagName('path');
    while (paths.length > 0) {
      svg.removeChild(paths[0]);
    }
  };
  //-------------------------------------------------------------------------------확대축소
  const zoomIn = () => {
    setImgWidth(prevWidth => prevWidth + 50); // Zoom In by increasing imgWidth by 50 each time.

  };
  
  const zoomOut = () => {
    setImgWidth(prevWidth => Math.max(prevWidth - 50, 100)); // Zoom Out by decreasing imgWidth by 50 each time, but not less than 100.

  };


  useEffect(() => {
    const svg = svgRef.current;
    const imgUrl = 'https://mathautogen.i234.me/autogenEditImage/0000/문제은행/고등부/MAG_내신_미적1/MAG_IJK_0538.gif';
  
    // 이미지 요소 생성
    const img = document.createElementNS('http://www.w3.org/2000/svg', 'image');
    // 이미지 속성 설정
    img.setAttributeNS(null, 'x', '0');
    img.setAttributeNS(null, 'y', '0');
    img.setAttributeNS(null, 'width',`${imgWidth}`);
  
    img.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', imgUrl);
    // SVG에 이미지 요소 추가
    svg.appendChild(img);
  },  [imgWidth]);
  
  
  //#######################################################################################
  return (
    <div className='draw'>
      <div className='box-svg'>
        {/* <svg ref={svgRef} style={{ border: '1px solid black', width: 800, height: 400 }}></svg> */}
       
       <svg 
          ref={svgRef} 
          viewBox={`0 0 ${imgWidth} 800`}
          style={{ border: '1px solid black', width: {imgWidth}, height: 800 }}
       >
       </svg>
      </div>

      <div className='box-menu'>

       

        <input  type="button" 
          className={`btnColor btnBlack ${activeButton === 'btnBlack' ? 'btnActive' : ''}`}   
          onClick={() => handleButtonPress("black", "btnBlack")} 
        />
        <input type="button" 
          className={`btnColor btnRed ${activeButton === 'btnRed' ? 'btnActive' : ''}`}  
          onClick={() => handleButtonPress("red", "btnRed")} 
        />

        <input 
          className={`btnColor btnBlue ${activeButton === 'btnBlue' ? 'btnActive' : ''}`}
          type="button" 
          onClick={() => handleButtonPress("blue", "btnBlue")} 
        />

        <input 
          className={`btnColor btnYellow ${activeButton === 'btnYellow' ? 'btnYellow-active' : ''}`}
          type="button" 
          onClick={() => {handleButtonPress("yellow", "btnYellow"); }} 
        />
         <input 
          className={`btnColor btnGreenyellow ${activeButton === 'Greenyellow' ? 'btnGreenyellow-active' : ''}`}
          type="button" 
          onClick={() => {handleButtonPress("greenyellow", "Greenyellow"); }} 
        />
         <input type="button" 
          className={`btnEraser ${activeButton === 'eraser-active' ? 'btnEraser-active' : 'btnEraser'}`}  
          onClick={() => handleEraseClick()} 
        />
          <input type="button" className='btnUndo' onClick={undo}/>
          <input type="button" className='btnRedo' onClick={redo}/>
          <input type="button" className='btnClear' onClick={clear}/>
          <input type="button" className='btnZoomIn' onClick={zoomIn} value="Zoom In"/>
          <input type="button" className='btnZoomOut' onClick={zoomOut} value="Zoom Out"/>

      </div>
    </div>
  );
};

export default SvgDraw;
