import { useNavigate } from 'react-router-dom';
import './CommentWrite.scss';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import fnc from '../mymod/commFunction';
import { Item } from '@radix-ui/react-accordion';
import { Button } from '@mui/material';



const CommentWrite = ({parentCode, onCommentAdded}) => {
const stCode = fnc.getCookie('stCode');
const navigate = useNavigate();
const [comment, setComment] = useState('');
const [isFocused, setIsFocused] = useState(false); // 포커스 상태를 추적하기 위한 상태

// useEffect(() => {
//     fncSelectComment(parentCode);
// }, [parentCode]);

function fncSelectComment(parentCode) {
    fnc.sendAxios('/board_comment', { parentCode}, (res) => {
        if (res) {
            console.log(res,'----------------------------');
        } else {
            console.log('속성이 obj 내에 없습니다.');
        }
    });
}

const handleInputChange = (event) => {
  setComment(event.target.value);
};


const handleFocus = () => {
  setIsFocused(true);  // input에 포커스가 될 때 isFocused를 true로 설정
};

const handleBlur = () => {
  setIsFocused(false);  // input에서 포커스가 해제될 때 isFocused를 false로 설정
};

function saveComment() {
  if (!comment) {
    alert('댓글을 입력하세요!'); 
    return;
  }
  const rtn =window.confirm('댓글을 등록하시겠습니까?');//계속 버튼 누르는 것 방지
  if(!rtn) return;

  const code = fnc.fncSirialNum('C');

  fnc.sendAxios('/insert_board_comment', { parentCode, content: comment, code, writer: stCode}, (res) => {
    if (res) {
      // fncSelectComment(parentCode);
      onCommentAdded();
      setComment('');//댓글 초기화 계속 버튼 누르는 것 방지
    } else {
        console.log('속성이 obj 내에 없습니다.');
    }
});

}

    //========================================
    return (
      <div className="CommentWrite">
        {!isFocused && (
          <div className="box_comment_write">
            <input
              type="text"
              placeholder="댓글을 입력해주세요"
              className="comment_input"
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
            <Button className="submit_button1">등록</Button>
          </div>
        )}

        {isFocused && (
          <>

            <textarea
              placeholder="댓글을 입력해주세요"
              className="comment_textarea"
              value={comment}
              onChange={handleInputChange}
              rows="5"
            />
    
            <div className="box_comment_write">
              <div className="comment_input"></div>
              <Button type="submit" className="submit_button1" onClick={saveComment}> 등록 </Button>
            </div>
          </>
        )}
      </div>
    );
}

export default CommentWrite