import './VideoStudyList_all.scss';
import * as S from '../scss/theme';
import Header from '../Header';
import Button from '@mui/material/Button';

import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedVideoList } from "../reduxToolkit/reduxVideo";
import fnc from '../mymod/commFunction';
import logo from '../images/gamja/logo-traspent.png'
import ProgressBar from '../comm/ProgressBar';
import imgLogo from '../images/gamja/logo-kr-traspent.png';
import DisplayLogo from '../comm/DisplayLogo';

const VideoStudyList_all = ({setIsModal}) => {
    const selectedVideoList = useSelector(state => state.reduxStore_video.selectedVideoList);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [items, setItems] = useState([]);
    const [totalTime, setTotalTime] = useState('');
    const stCode = fnc.getCookie('stCode');
    const acaCode = fnc.getCookie('acaCode');

    useEffect(()=>{
      const parentCode=selectedVideoList.code;
      selectVideoStudyList(parentCode);
    }, []);

    function selectVideoStudyList(parentCode) {   
        fnc.sendAxios('/selectVideoStudyList_all', {acaCode, parentCode}, (res) => {
          if (res) {   
            setItems(res);   
          } else {
            console.log("속성이 obj 내에 없습니다.");
          }
        });
      }


//===============================================
return(
    <div className="VideoStudyList_all">
      <S.Section>
      <Header onBackClick={() => {setIsModal(false)}} title="학습내역" />
        <div className="card-container">
                {!!items.length && items.map(item => (
                    <div className="card" key={item.sno}>
                        <div className='box_icon_content'>
                              <div className="card-icon">
                                <img src={logo} className='img22'></img>
                              </div>                     
                              
                              <div className="card-content">
                                 
                                  <div className='box_date'>
                                      <div className='date1'>
                                        
                                        <span className='stName'>{item.stName}</span>
                                        <span className='sDate'>{item.sDate}</span>
                                      </div>
                                      <div className='date2'>{`
                                      ${Math.floor(item.listenTime / 60)}분
                                      /
                                      ${Math.floor(item.totalTime / 60)}분 
                                     `} </div>
                                  </div>
                                  
                                  <div className='title'>{item.title}</div>           
                                  <ProgressBar progress={item.listenPercent} />
                              </div>                              
                        </div>
                    </div>
                ))}

                <div className='global-margin-bottom-scroll'/>
        </div>

        {!items.length && <DisplayLogo imgLogo = {imgLogo} width="15rem" height="15rem" />}
        </S.Section>
    </div>
)};

export default VideoStudyList_all;