import "./markingStudyApp.scss"; 
import axios from "axios";
import Button from "react-bootstrap/Button";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Header from "../Header";
import MarkingStudyQue from "./MarkingStudyQue";
import MarkingTitleBar from "./MarkingTitleBarStudy";
import MarkingSetting from "../marking/MarkingSetting";
import MarkingStudyTable from "./MarkingStudyTable";
import MarkingStudyMenu from "./MarkingStudyMenu";
import Cookies from "js-cookie";

const svrUrl = process.env.REACT_APP_SERVER_URL;

function MarkingStudyApp() {
  const navigate = useNavigate();
  const location = useLocation();
  const testNm = location.state?.testNm;

  const [list, setList] = useState(null);
  const [selectedRow, setSelectedRow] = useState(0);
  const [isShowList, setIsShowList] = useState(true);
  const [no, setNo] = useState("1번");
  const [info, setInfo] = useState("");
  const [showMarkingSetting, setShowMarkingSetting] = useState(false);
  const [imgSize, setImgSize] = useState(300);
  const [selectKind, setSelectKind] = useState("전체");
  const [isRowClicked, setIsRowClicked] = useState(false);
  const [imgViewState, setImgViewState] = useState("문제"); // 기본 상태로 '문제' 설정
  const [showModal, setShowModal] = useState(false);
  const [slideHandlePrevNext, setSlideHandlePrevNext] = useState(null);
 

  const markingMenuRef = useRef();//MarkingMenu의 버튼 클릭 함수 참조


  function fncHandleNext() {
    if (list && selectedRow !== null) {
      const nextIndex = selectedRow;
      if (nextIndex < list.length) {
        const nextItem = list[nextIndex];
        setSelectedRow(nextItem.no);
      } else {
        alert("마지막입니다!");
      }
    }
  }

  const handleCloseSetting = () => {
    setShowMarkingSetting(false);
  };

  function fncTestClose() {
    const confirmed = window.confirm("시험을 종료하시겠습니까?");
    if (confirmed) {
      navigate("/markinglist");
    }
  }


  function fncPrev() {
    if (list && selectedRow !== null) {
      const currentIndex = selectedRow - 1;  // 배열 인덱스는 0부터 시작하므로 -1을 합니다.
  
      if (currentIndex > 0) {
        const prevIndex = currentIndex - 1;
        fncSelectRow(prevIndex);
        setSlideHandlePrevNext(1);
      } else {
        alert("처음입니다.");
      }
    }
  }

  function fncNext() {
    if (list && selectedRow) {
      const nextIndex = selectedRow ; // item.no가 인덱스 + 1 이므로
      if (nextIndex < list.length) {
        fncSelectRow(nextIndex);
        setSlideHandlePrevNext(-1);
      } else {
        alert('마지막입니다!');
      }
    }
  }
   
  
function fncSelectRow(index) {
  if (index == null) return false;//(!index) null, undefined, 0, "", NaN, false
    setSelectedRow(index + 1);
    setIsRowClicked(true);

}


const callMarkingFunctionFromMenu_fncNumberMarking = (no, value) => {
  markingMenuRef.current.fncNumberMarking(no, value);
}

const callMarkingFunctionFromMenu_fncInputMarking = (no, value) => {
  markingMenuRef.current.fncInputMarking(no, value);
}



  return (
    <div className="markingStudyApp">
      <div className="header-container">
        <Header onBackClick={() => fncTestClose()} title={testNm} />
      </div>


      {!isShowList && list && selectedRow && (<MarkingTitleBar 
          no={no} 
          info={info} 
          list={list} setList={setList} //추가됨
          selectedList={list[selectedRow-1]}
          testNm={testNm}
          showModal={showModal} setShowModal={setShowModal}
          fncPrev={fncPrev}
          fncNext={fncNext}
          slideHandlePrevNext={slideHandlePrevNext} setSlideHandlePrevNext={setSlideHandlePrevNext}
          setIsShowList={setIsShowList}   
          selectedRow={selectedRow}
          onMarkingNumber={callMarkingFunctionFromMenu_fncNumberMarking}
          onMarkingInput={callMarkingFunctionFromMenu_fncInputMarking}
          isStudy={true}  />
      )}


      {
        <MarkingStudyTable
          isShowList={isShowList}
          list={list} setList={setList}
          selectedRow={selectedRow} setSelectedRow={setSelectedRow}
          selectKind={selectKind}
          setIsRowClicked={setIsRowClicked}  />
      }

      {
        <MarkingStudyMenu
          isShowList={isShowList}
          list={list}
          selectedRow={selectedRow}
          imgSize={imgSize}
          //--------------------------
          setIsShowList={setIsShowList}
          setSelectedRow={setSelectedRow}
          setSelectKind={setSelectKind}
          setNo={setNo}
          setInfo={setInfo}
          setImgSize={setImgSize}
          setIsRowClicked={setIsRowClicked}
          setImgViewState={setImgViewState}  />
      }


      {list && list.length > 0 && (<MarkingStudyQue
          list={list}
          selectedRow={selectedRow}
          imgSize={imgSize}
          isRowClicked={isRowClicked}
          imgViewState={imgViewState}
          // --------------------------
          setIsShowList={setIsShowList}
          setNo={setNo}
          setInfo={setInfo}
          setIsRowClicked={setIsRowClicked}
          setImgViewState={setImgViewState}
          fncPrev={fncPrev}
          fncNext={fncNext}
        />
      )}
      
    </div>
  );
}

export default MarkingStudyApp;
