import './VideoList.scss';
import * as S from '../scss/theme';
import Header from '../Header';
import Button from '@mui/material/Button';

import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedVideoList } from "../reduxToolkit/reduxVideo";
import fnc from '../mymod/commFunction';
import logo from '../images/gamja/logo-traspent.png'
import imgLogo from '../images/gamja/logo-kr-traspent.png';
import DisplayLogo from '../comm/DisplayLogo';
import VideoStudyList from './VideoStudyList';
import VideoStudyList_all from './VideoStudyList_all';


const VideoList = () => {
    const selectedVideoGroupList = useSelector(state => state.reduxStore_video.selectedVideoGroupList);
    const authority = useSelector(state => state.reduxStore_video.authority);
    const isTecherVideoAutority = authority !== null && authority <= 9;

    const stCode = fnc.getCookie('stCode');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [items, setItems] = useState([]);
    const [isModal, setIsModal] = useState(false);
    const [isModal_all, setIsModal_all] = useState(false);
    

    useEffect(()=>{
      const kwajungCode=selectedVideoGroupList.kwajungCode;
      const parentCode=`D${kwajungCode}`;//ABCD 붙여야한다.
        fncSelectFileList(parentCode);
    }, []);

    function fncSelectFileList(parentCode) {   
        fnc.sendAxios('/selectVideoList', {stCode, parentCode}, (res) => {
          if (res) {   
            setItems(res);   
            // console.log(res);
          } else {
            console.log("속성이 obj 내에 없습니다.");
          }
        });
      }
  
      function onPlay(item) {

        const videoCode = item.code;
        const stCode = fnc.getCookie('stCode');
        // console.log(authority, 'authority');
        fncSelect_checkVideoAccess(videoCode, stCode, (res) => {
          if (res === true || isTecherVideoAutority) {
            dispatch(setSelectedVideoList(item));
            navigate('/videoReact'); 
          }else {
            alert('권한이 없습니다.');
          }
        });
      }

      function onStudyList(item) {
        const videoCode = item.code;
        const stCode = fnc.getCookie('stCode');

        fncSelect_checkVideoAccess(videoCode, stCode, (res) => {
          if (res === true || isTecherVideoAutority) {
            dispatch(setSelectedVideoList(item));
            setIsModal(true);
          }else {
            alert('권한이 없습니다.');
          }
        });
      }

      function onStudyList_all(item) {
        const videoCode = item.code;
        const stCode = fnc.getCookie('stCode');

        fncSelect_checkVideoAccess(videoCode, stCode, (res) => {
          if (res === true || isTecherVideoAutority) {
            dispatch(setSelectedVideoList(item));
            setIsModal_all(true);
          }else {
            alert('권한이 없습니다.');
          }
        });
      }

      


      function fncSelect_checkVideoAccess(videoCode, stCode, callback) {  // stCode로 프로시저에서 class와 학년 가져온다.
        fnc.sendAxios('/checkVideoAccess', {videoCode, stCode}, (res) => {
          
          const accessGranted = res && res.result === 'exist';
          callback(accessGranted);

          if (!res) {
              console.log("응답 객체가 비어있습니다.");
          }
        });
      }

//===============================================
return(
    <div className="VideoList">
      <S.Section>
      <Header onBackClick={() => navigate("/videoGroup")} title="비디오 리스트" />
      
        <div className="card-container">
                {items.map(item => (
                    <div className="card" key={item.sno}>
                        <div className='box_icon_content'>
                              <div className="card-icon">
                                <img className='imgLogo' src={logo}></img>
                              </div>                     
                              
                              <div className="card-content">
                                  <div className='title1'>{item.unit2}</div>
                                  <div className='title2'>{item.unit3}</div>

                                  {item.lastDate && <div className='studyStatus'>
                                      <div className='studyStatusDiv rate'>최고학습률 {item.listenPercent}% </div>                 
                                      <div className='studyStatusDiv cnt'>학습횟수 {item.listenCnt} </div>
                                      {/* <div className='studyStatusDiv date'>최근 {item.lastDate} </div> */}
                                      <div className='studyStatusDiv date'>최근 {item.lastDate.split('-').map((part, index) => index === 0 ? part.substring(2) : part).join('.')} </div>

                                  </div> }

                              </div> 
                             
                        </div>

                        <div className="card-buttons">
                            <Button  className='btnMenu3' onClick={()=>{onPlay(item)}}>강의보기</Button>
                            <div className='box-study-history'>
                               {isTecherVideoAutority && <Button variant='outlined' className='btnMenu4' onClick={()=>{onStudyList_all(item)}} >학생별 학습내역</Button> }
                                <Button className='btnMenu5' onClick={()=>{onStudyList(item)}} >학습내역</Button>
                            </div>
                        </div>
                    
                    </div>
                    
                ))}

          

              {!items.length && <DisplayLogo imgLogo = {imgLogo} width="10px" height="10px" />}

        </div>

        </S.Section>

        {isModal && <div className='box_modal'>
                  <div className="safe-area-container">
                      <VideoStudyList setIsModal={setIsModal}/>
                      </div>
                  </div> }    

                  {isModal_all && <div className='box_modal'>
                  <div className="safe-area-container">
                      <VideoStudyList_all setIsModal={setIsModal_all}/>
                      </div>
        </div> }    

    </div>
)};

export default VideoList;