import styles from "./markingMenuSpecial.css"; //css는 Top에 와야..
import Button from "react-bootstrap/Button";
import React, { useEffect, useState } from "react";

function MarkingMenuSpecial({ setInputValue, setIsShowSpecial }) {

  console.log("5-----------------MarkingMenuSpecial");
//   const [isShowSpecial, setIsShowSpecial] = useState(true);

  useEffect(() => {
   
  }, []);

  
const buttons = [
    '≥', '≤', '<', '>', '²', '³', '⁴', '+', '-', '×', '÷', '√', 'π', '±',
    '≠', '=', '∞', '∑', '∫', 'ω', '′', '″', 'Ø', '∈', '∋', '⊂', '⊃', '∪', '∩',
    '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '?', 'x', 'y', 'z', 'X', 'Y', 'Z',
    'a', 'b', 'c', 'A', 'B', 'C', 'α', 'β', 'γ', 'θ','닫기'
  ];

  return (
    <div className="box-btn_special">
      { buttons.map((btnValue, index) => (
        <button 
          key={index} 
          className={`btn_special ${btnValue === '닫기' ? 'btn-special-close' : ''}`}
          onClick={() => {
            if (btnValue === '닫기') {
                setIsShowSpecial(false);
              } else {
                setInputValue((prevValue) => prevValue + btnValue);
              }
          }}
            //onClick={() => { setInputValue(prevValue => prevValue + buttons[index]); }} 축약한 것
            // setInputValue 함수에 value 전달  함수를 안쓰면 렌더링 될 때만다 실행 됨
        >{btnValue}</button>
      ))}
    </div>
  );
}

export default MarkingMenuSpecial;
