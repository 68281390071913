import { cssNumber } from 'jquery';
import './Views.scss';
import React, { useState, useEffect } from 'react';
import fnc from '../mymod/commFunction';

//views는 일단 생략 faq에서는 안 맞는다. 클릭해서 들어 갈 때 맞음 ()

const Views = (code) => {

    const [viewCnt, setViewCnt] = useState(0);

    useEffect(() => {
        fncInCrementBoard_view(code);
    }, []);


    function fncInCrementBoard_view(code) {

        fnc.sendAxios('/update_board_views', { contentId: code }, (response) => {       
          if (response) {
            setViewCnt(4);
            console.log(response,'----------------------------');
          } else {
            console.log("속성이 obj 내에 없습니다.");
          }
        });
      }

//============================
    return (
        <div className='Views'>

        {viewCnt}


        </div>

    );
}

export default Views;