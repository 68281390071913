import './ManagementSt_sms.scss';

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import fnc from '../mymod/commFunction';
import { useDispatch, useSelector } from 'react-redux';
import ComboboxMui from '../comm/ComboboxMui';
import { Button } from '@mui/material';
import Dtpicker2 from '../comm/Dtpicker2';
import {setSelectedDate, setSelectedTab} from  '../reduxToolkit/reduxSt';

import CustomSpinner from '../comm/CustomSpinner';
import { toPng } from 'html-to-image';

import Checkbox from '@mui/material/Checkbox';

import ManagementSt_sms_report_attend from './ManagementSt_sms_report_attend';
import ManagementSt_sms_report_homework from './ManagementSt_sms_report_homework';
import ManagementSt_sms_report_exam from './ManagementSt_sms_report_exam';
import BoardWriteText from './BoardWriteText';
import ManagementSt_sms_report_notice from './ManagementSt_sms_report_notice';

import gamjaReportLogo  from '../images/gamja/logo-landscape.png';
import ManagementSt_sms_report_achievement from './ManagementSt_sms_report_achievement';

const ManagementSt_sms = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate(); 
    const [list, setList] = useState([]);
    const [selectedItem, setSelectedItem] = useState({});
    const [lessonWeek, setLessonWeek] = useState(null);
    const [code, setCode] = useState(null);
    const [codeName, setCodeName] = useState(null);
    const [kind, setKind] = useState(null);
    const [noticeTitle, setNoticeTitle] = useState(null);
    const [isNoticeGrade, setIsNoticeGrade] = useState(false);

    const selectedClassCode = useSelector(state => state.reduxStore_st.selectedClassCode);

    const selectedDateString = useSelector(state => state.reduxStore_st.selectedDate);
    const [startDate, setStartDate] = useState(new Date(selectedDateString)); // 초기화 시에만 사용
    const [isSelectAll, setIsSelectAll] = useState(false);//체크박스
    const [isRefresh, setIsRefresh] = useState(false);
    const [noticeKind, setNoticeKind] = useState(false);

    const classCode = selectedClassCode ? selectedClassCode.classCode : '';//selectedClassCode 이거 객체로 사용
    const className = selectedClassCode ? selectedClassCode.className : '';
    const gangsaCode = selectedClassCode ? selectedClassCode.gangsaCode : '';
    const gangsaName = selectedClassCode ? selectedClassCode.gangsaName : '';
    
    // console.log(selectedClassCode, ' selectedClassCode');

    const dispatch = useDispatch();
    const captureRef = useRef();

   

    useEffect(() => {
      if(startDate){
      const lessonDate = fnc.formatDate(startDate);
      fncSelect_stList(classCode, lessonDate);

      dispatch(setSelectedDate(startDate.toISOString())); // 상태 업데이트

      const weekNumer = getWeekNumber(startDate);
        setLessonWeek(weekNumer);
      }

     
  }, [classCode, startDate, isRefresh]); // startDate가 변경될 때만 호출


  
  function fncSelect_stList(classCode, lessonDate) {
 
    fnc.sendAxios('/select/manageMentSt/class_stList_attend', { classCode, lessonDate }, (res) => {
      if (res) {  
            setList(getRecords_stList(res));
            setIsSelectAll(false);
      } else {
            console.log(res,"managementStRoutes/select/manageMentSt/class_stList_attend");
      }
    });
  }


function getRecords_stList(res) {
    let records = [];
    // res가 배열인지 확인
    if (!Array.isArray(res)) {
      return records;
    }
    
    res.forEach(item => {
      const classCode = item["classCode"];  
      const className = item["className"];  
      const gangsaCode = item["gangsaCode"]; 
      const gangsaName = item["gangsaName"]; 
      const stCode = item["stCode"];  
      const stName = item["stName"];     
      const school = item["school"];  
      const hp= item["hp"];      
      const parentHp= item["parentHp"];  
      const attend= item["attend"];      
      const reason= item["reason"];      
      const lessonDate= item["lessonDate"];   
      const isChecked = false;
      const classGrade = item["classGrade"];  
               
      records.push({ classCode, className, gangsaCode, gangsaName, stCode, stName, school, hp, parentHp, 
        attend, reason, lessonDate, isChecked, classGrade });

    });
   
    return records;
  }



//--------------------------------------------------------------to image
  const onCreatImg = () => {
    setLoading(true); // 로딩 상태 시작
  
    if (captureRef.current === null) {
      return;
    }
  
    // 스크롤바를 숨기기 위한 스타일 변경
    const originalOverflow = captureRef.current.style.overflow;
    captureRef.current.style.overflow = 'hidden';
    
    const scale = 2; // 해상도 배율을 2배로 설정 (원하는 해상도에 맞게 조정 가능)
    const width = captureRef.current.offsetWidth * scale;
    const height = captureRef.current.offsetHeight * scale;
  
    toPng(captureRef.current, {
      skipFonts: true,
      filter: (node) => {
        // classList가 없는 요소와 custom-spinner-container 클래스를 포함하지 않은 모든 요소를 캡처에 포함
        return !node.classList || !node.classList.contains('custom-spinner-container');
      },
      canvasWidth: width,
      canvasHeight: height,
      pixelRatio: scale, // 해상도를 높이기 위해 픽셀 비율을 설정
    })
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.href = dataUrl;
        link.download =  `${selectedItem.stName}_${fnc.formatDate(startDate)}.png`;
        link.click();
      })
      .catch((err) => {
        console.error('Could not capture image', err);
      })
      .finally(() => {
        setLoading(false); // 로딩 상태 종료
        captureRef.current.style.overflow = originalOverflow; // 원래 overflow 상태로 복원
      });
  };
  

    
  function onRowClick(item) {

    if (!item) {
      return;
    }
    setSelectedItem(item);
  }

  const onCreatImgChecked = async () => {
    const checkedItems = list.filter(item => item.isChecked);
    if (checkedItems.length === 0) {
        alert("선택된 항목이 없습니다.");
        return;
    }
    setLoading(true);
    const originalOverflow = captureRef.current.style.overflow;
    captureRef.current.style.overflow = 'hidden';

    for (let i = 0; i < checkedItems.length; i++) {
        const item = checkedItems[i];
        await new Promise((resolve) => {
            setSelectedItem(item);
            setTimeout(() => {
                toPng(captureRef.current, {
                    skipFonts: true,
                    filter: (node) => !node.classList || !node.classList.contains('custom-spinner-container'),
                })
                .then((dataUrl) => {
                    const link = document.createElement('a');
                    link.href = dataUrl;
                    link.download = `${item.stName}_${fnc.formatDate(startDate)}.png`;
                    link.click();
                })
                .catch((err) => {
                    console.error('Could not capture image', err);
                })
                .finally(() => {
                    resolve(); // 이미지 생성 후 다음 항목으로 넘어가기 위해 resolve 호출
                });
            }, 500); // setTimeout으로 500ms 딜레이를 주어 리포트가 렌더링되도록 함
        });
    }

    captureRef.current.style.overflow = originalOverflow;
    setLoading(false);
};


    //----------------------------------------------몇 째주 계산
    const getWeekNumber = (date) => {
      const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
      const dayOfWeek = startOfMonth.getDay(); // 0 (일요일)부터 6 (토요일)까지의 숫자
    
      // 첫 주의 첫 번째 월요일을 계산
      const firstMonday = startOfMonth.getDate() + ((dayOfWeek === 0) ? 1 : 8 - dayOfWeek);
    
      // 날짜 차이를 계산하여 몇 번째 주인지 판단
      const diff = date.getDate() - firstMonday;
    
      if (diff < 0) {
        return `${date.getFullYear()}년 ${date.getMonth() + 1}월 첫째 주`;
      }
    
      // 주 수 계산 (0부터 시작하므로 +1)
      const weekNum = Math.floor(diff / 7) + 2; 
    
      // 주 번호를 한글로 변환
      const weekNumberInKorean = ['첫째', '둘째', '셋째', '넷째', '다섯째'][weekNum - 1];
    
      return `${date.getFullYear()}년 ${date.getMonth() + 1}월 ${weekNumberInKorean} 주`;
    };
    
        
    function onNoticeByGrade() {
      const classGrade = selectedItem.classGrade || '';
      if(!classGrade) {
        alert('학생을 선택하세요!');
        return;
      }
      setKind('학년별');
      setCode(classGrade);
      setCodeName(classGrade)
      setIsNoticeGrade(true);
    }

    function onNoticeByClass() {
      const code = selectedItem.classCode + '';//숫자타입을 문자열로 변경
      const codeName = selectedItem.className || '';
      if(!code) {
        alert('학생을 선택하세요!');
        return;
      }
      setKind('반별');
      setCode(code);
      setCodeName(codeName)
      setIsNoticeGrade(true);
    }

    function onNoticeBystudent() {
      const code = selectedItem.stCode || '';
      const codeName = selectedItem.stName || '';
      if(!code) {
        alert('학생을 선택하세요!');
        return;
      }
      setKind('학생별');
      setCode(code);
      setCodeName(codeName)
      setIsNoticeGrade(true);
    }


  //===================================================
  return (
   <div className="ManagementSt_sms">
        <div className="wrap-main" >
          <section className="section-main">

            <article className='article-table'>
                <table className="table-hong table-main">
                  <thead>
                    <tr>
                      <th>{list.length}</th>
                      <th>성명</th>                            
                      <th>
                          <Checkbox checked={isSelectAll} onChange={handleSelectAllChange} />
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                      {list && list.map((item, index) => (
      
                          <tr key={index}  onClick={() => onRowClick(item)}>  
                            <td>{index+1}</td>
                            <td>{item.stName}</td>
                           
                           <td> 
                                <Checkbox
                                  checked={item.isChecked}
                                  onChange={(event) => handleCheckboxChange(event, index)} 
                                />
                            </td>
                          </tr>
                      ))}
                
                    </tbody>
                </table>
            </article>

            <article className='article-report2'  >
              <div className='inner-report2' ref={captureRef}>

                <p className='report-title'>
                    <span>&lt;</span>{`${selectedItem.stName || ''} 학생 주간 학습현황`} <span>&gt;</span> 
                </p>

                <article className='article-weekAndLogo'>
                  <p className='lessonWeek'>{lessonWeek}</p>
                  <p><img className='gamjaReportLogo' src={gamjaReportLogo}/></p>
                </article>

                  {/* <p className='title'>정규수업 출결</p> */}
                  <ManagementSt_sms_report_attend selectedItem={selectedItem} lessonDate={startDate} kind={'정규'} />
                  {/* <p className='title'>코칭수업 출결</p> */}
                  <ManagementSt_sms_report_attend selectedItem={selectedItem} lessonDate={startDate} kind={'코칭'} />
                   {/* <p className='title'>코칭수업 성취도</p> */}
                   <ManagementSt_sms_report_achievement selectedItem={selectedItem} lessonDate={startDate} kind={'코칭'} />
             
                  {/* <p className='title'>진도 및 과제</p> */}
                  <ManagementSt_sms_report_homework selectedItem={selectedItem} lessonDate={startDate} kind={'정규'}/>
                  {/* <p className='title'>코칭 진도 및 과제</p> */}
                  <ManagementSt_sms_report_homework selectedItem={selectedItem} lessonDate={startDate} kind={'코칭'}/>
                  {/* <p className='title'>진단평가</p> */}
                  <ManagementSt_sms_report_exam selectedItem={selectedItem} lessonDate={startDate} />

      
                  <ManagementSt_sms_report_notice lessonWeek={lessonWeek} classGrade={selectedItem.classGrade}
                   kind={'학년별'}/>

                  <ManagementSt_sms_report_notice lessonWeek={lessonWeek} classGrade={selectedItem.classCode+''}
                   kind={'반별'}/>

                  <ManagementSt_sms_report_notice lessonWeek={lessonWeek} classGrade={selectedItem.stCode}
                   kind={'학생별'}/>


                  {isNoticeGrade && <BoardWriteText setIsNoticeGrade = {setIsNoticeGrade} 
                  lessonWeek={lessonWeek} code={code} codeName={codeName}
                  setIsRefresh={setIsRefresh} kind={kind}/> }
              
              </div>
            </article>

          </section>

         

          <section className='section-menu'>
                  <label>{lessonWeek}</label>

                   <article className='article-dtp'>
                        <Dtpicker2 selectedDate={startDate} onDateChange={(date) => setStartDate(date)} />
                  </article>

                  <Button className='btn-menu' onClick={onCreatImg}>
                      <i class="fa-solid fa-image"></i> &nbsp;이미지로
                  </Button>  

                  <Button className='btn-menu' onClick={onCreatImgChecked}>
                      <i class="fa-solid fa-image"></i> &nbsp;일괄저장
                  </Button>    

                  <Button className='btn-menu' onClick={onNoticeByGrade}>
                      <i class="fa-solid fa-comment-dots"></i> &nbsp;학년공지
                  </Button>       

                   <Button className='btn-menu' onClick={onNoticeByClass}>
                      <i class="fa-solid fa-comment-dots"></i> &nbsp;반별
                  </Button>     

                  <Button className='btn-menu' onClick={onNoticeBystudent}>
                      <i class="fa-solid fa-comment-dots"></i> &nbsp;학생
                  </Button>             

          </section>

        </div>

          {/* 로딩 중일 때 CustomSpinner 표시 */}
            {loading && <CustomSpinner />}

      </div>
  );

//===========================================================================



  
 //---------------------------checkbox
 function handleCheckboxChange(event, index) {
  const isChecked = event.target.checked;
  const newList = [...list];
  newList[index].isChecked = isChecked;
  setList(newList);

  // 모두 체크되었는지 확인하여 헤더 체크박스 상태 업데이트
  const allChecked = newList.every(item => item.isChecked);
  setIsSelectAll(allChecked);

}

function handleSelectAllChange() {
  const newList = list.map(item => ({...item, isChecked: !isSelectAll }));
  setList(newList);
  setIsSelectAll(!isSelectAll);
}//checkbox END


}

export default ManagementSt_sms;




