import styles from "./markingSt.css"; //css는 Top에 와야..
import axios from "axios";
import Button from "react-bootstrap/Button";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
// import { animated, useSpring } from "react-spring";
import Header from "../Header";
import MarkingQue from "./MarkingQue";
import MarkingTitleBar from "./MarkingTitleBar";
import MarkingSetting from "./MarkingSetting";

import MarkingMenu from "./MarkingMenu";
import Cookies from 'js-cookie';
import fnc from '../mymod/commFunction';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';


const svrUrl = process.env.REACT_APP_SERVER_URL;

function MarkingSt() {
  const navigate = useNavigate(); 
  const location = useLocation();
  const testCodeMun = location.state?.testCodeMun;
  const testCode = location.state?.testCode;
  const testNm = location.state?.testNm;
  const munCnt= location.state?.munCnt;
  const [list, setList] = useState(null);
  const [selectedNo, setSelectedNo] = useState(null);
  const [imgUrlComm, setImgUrlComm] = useState(null);
  const [imgUrlQue, setImgUrlQue] = useState(null);
  const [isShowList, setIsShowList] = useState(true);
  const [no, setNo] = useState("1번");
  const [info, setInfo] = useState(''); 
  const [imageSize, setImageSize] = useState(300);
  const [showMarkingSetting, setShowMarkingSetting] = useState(false)
  const [chkNextMoonje, setChkNextMoonje] = useState(() => Cookies.get('chkNextMoonje'));
  const [isListVisible, setIsListVisible] = useState(true); // 새로운 상태 변수 추가

  const [markingList, setMarkingList] = useState(() => {
    const initialState = {};
    for (let i = 1; i <= munCnt; i++) {
      initialState[i] = '';
    }
    return initialState;
  });

  console.log("1-----------------MarkingSt" );

  useEffect(() => {
    fncSelectList(); // 라우팅 시 selectClick 함수를 실행합니다. 로딩 후 실행
    const savedSize = Cookies.get('autogenst_imgsize');
    if (savedSize) {
      setImageSize(Number(savedSize));
    }
    
  }, []);

  
  const changeSize = (newSize) => {
    setImageSize(newSize);
    Cookies.set('autogenst_imgsize', newSize, { expires: 1000 });
  };

  
  

  function fncSelectList() {
  
    sendAxios("/markingStList", { testCodeMun, testCode }, (response) => {
      console.log(response);
      if (response) {
        setList(response);

        let folder = response[0].폴더;
        let fileNameComm = response[0].공통지문;
        let fileNameQue = response[0].fileName;
        setSelectedNo(response[0].no);
        
        fncShowImgQue(folder, fileNameComm, fileNameQue); 

      } else {
        console.log("속성이 obj 내에 없습니다.");
      }
    });
  }

  const sendAxios = async (url, data, callback) => {
    try {
      const response = await axios.post(`${svrUrl}${url}`, data);
      let jsonObj = response.data;
      callback(jsonObj);
    } catch (error) {
      console.error("Axios 요청 중 오류가 발생했습니다:", error);
    }
  };

  function fncSelectRow(item) {
    setSelectedNo(item.no);
    let folder = item.폴더;
    let fileNameComm = item.공통지문;
    let fileNameQue = item.fileName;

    fncShowImgQue(folder, fileNameComm, fileNameQue);  
    setNo(item.no + '번');
    setInfo(markingList[item.no]);
  }

  // 상위 컴포넌트 MarkingList에서
function fncHandleNext() {
  if (list && selectedNo !== null) {
    const currentIndex = list.findIndex(item => item.no === selectedNo);
    if (currentIndex !== -1 && currentIndex < list.length - 1) {
      const nextItem = list[currentIndex + 1];
      fncSelectRow(nextItem);
    }else {
      alert('마지막입니다!');
    } 
  }
}

function fncHandlePrev() {
  if (list && selectedNo !== null) {
    const currentIndex = list.findIndex(item => item.no === selectedNo);
    if (currentIndex > 0) {
      const nextItem = list[currentIndex - 1];
      fncSelectRow(nextItem);
    }else {
      alert("처음입니다.");
    }
  }
}

function fncHandleMarking(no, value) {
  setMarkingList(prevMarking => ({...prevMarking, [no]: value }));
  console.log(markingList);
  fncCloseList();
  if (chkNextMoonje == 1) {
   fncHandleNext() 
  }
}


function fncShowList() {
  setIsShowList(!isShowList);
}

function fncCloseList() {
  setIsShowList(false);
}

function fncShowImgQue(folder,fileNameComm, fileNameQue) {
  folder = folder.replace(/\\/g, "/");
  fileNameQue = fileNameQue + ".gif";
  let imgPathComm= `https://mathautogen.i234.me/autogenEditImage/0000/${folder}/${fileNameComm}`;
  let imgPathQue = `https://mathautogen.i234.me/autogenEditImage/0000/${folder}/${fileNameQue}`;
  setImgUrlQue(imgPathComm);
  setImgUrlQue(imgPathQue);
  fncCloseList();
}


useEffect(() => {
  console.log(markingList);
}, [markingList]);

const handleCloseSetting = () => {
  setShowMarkingSetting(false); // 상태를 false로 변경
};

//========================================================================= return
  return (
    <div className="Marking">
      <div className="header-container">
        <Header onBackClick={() => navigate("/markinglist")} title={testNm} />
      </div>

      <input type="button" className="btnSetting" onClick={() => setShowMarkingSetting(true)}/>
      
      {showMarkingSetting && <MarkingSetting 
          setChkNextMoonje={setChkNextMoonje} 
          handleClose={handleCloseSetting} />}

      {!isShowList && <MarkingTitleBar 
          no={no} info={info} />}
 
          <div className="wrap-list-markingSt" style={{display: isShowList ? 'flex' : 'none'}}>
            <table>
              <thead>
                <tr>
                  <th style={{ width: "5rem" }}>No</th>
                  <th style={{ width: "auto"}}>마킹</th>
                </tr>
              </thead>

              <tbody>
                {list &&
                  list.map((item, index) => (
                    <tr
                      className={`row-list ${selectedNo === item.no ? "selected activeRow" : ""}`}
                      key={item.no}
                      onClick={() => fncSelectRow(item)}
                    >
                      <td>{item.no}</td>
                      <td style={{textAlign: 'left', paddingLeft: '1rem'}}>{markingList[item.no] || ""}</td>
                    </tr>
                                
                  ))}
              </tbody>
            </table>
          </div>
     
     
      {/* 조건부 렌더링 */}
     {!isShowList && <MarkingQue  
        imgUrlComm={imgUrlComm} 
        imgUrlQue={imgUrlQue} 
        currentSize={imageSize}/>}

    <MarkingMenu 
        selectedNo={selectedNo} 
        isShowList={isShowList} 
        setIsShowList={setIsShowList} 
        fncHandleNext={fncHandleNext} 
        fncHandlePrev={fncHandlePrev} 
        list={list}
        markingList={markingList} 
        testCode={testCode} 
        fncHandleMarking={fncHandleMarking}
        changeSize={changeSize} 
        currentSize={imageSize}/>
        
    </div>

    

  );
}

export default MarkingSt;
