import styles from "./markingStudyTable.scss"; //css는 Top에 와야..
import axios from "axios";
import Button from "react-bootstrap/Button";
import React, { useEffect, useState, memo} from "react";
import { useLocation } from "react-router-dom";
import fnc from '../mymod/commFunction';

const svrUrl = process.env.REACT_APP_SERVER_URL;
const stCode = fnc.getCookie('stCode');

const MarkingStudyTable = (({ 
  isShowList, 
  selectedRow, 
  markingList, 
  selectKind, 
  list, setList,  
  setSelectedRow, 
  setIsRowClicked,  }) => {

  const location = useLocation();
  const testCodeMun = location.state?.testCodeMun;
  const testCode = location.state?.testCode;

  console.log("2-----------------MarkingStudyTable" );

  useEffect(() => {
    // fncSelectList(selectKind);
   
  }, []);

useEffect(() => {
  fncSelectList(selectKind);
}, [selectKind]);

  function fncSelectList(selectKind) {
//, setIsRowClicked 이거 삭제함 왜 있지?
    sendAxios("/markingStudyList", { stCode, testCodeMun, testCode, selectKind }, (response) => {
      if (response) {  
        console.log(response,'------------------------------markingStudyList');
        setList(response); 
        fncSelectRowIdx(0);//index 넣어야
      } else {
        console.log("속성이 obj 내에 없습니다.");
      }
    });
  }


function fncSelectRowIdx(index) {//Row클릭 
  if (index == null) return false;//(!index) null, undefined, 0, "", NaN, false
    setSelectedRow(index + 1);
}
  
  
  const sendAxios = async (url, data, callback) => {
    try {
      const response = await axios.post(`${svrUrl}${url}`, data);
      let jsonObj = response.data;
      callback(jsonObj);
    } catch (error) {
      console.error("Axios 요청 중 오류가 발생했습니다:", error);
    }
  };

 
  const fncTableRowClick = (index) => {
    setIsRowClicked(true);  
    fncSelectRowIdx(index);  
  }
  
//========================================================================= return
return (
  <>
    {isShowList && (
      <div className="MarkingStudyTable">
        <div className="wrap-list-markingSt">
          <table>
            <thead>
              <tr>
                
                <th style={{ width: "5rem" }}>No</th>
                <th style={{ width: "5rem" }}>OX</th>
                <th style={{ width: "5rem" }}>정답률 (%)</th>
              </tr>
            </thead>
            <tbody>
              
              {list &&
                list.map((item, index) => (
                  <tr
                  className={`row-list ${selectedRow === index+1 ? "selected activeRow" : ""}`}
                  key={item.no}
                  onClick={() => fncTableRowClick(index)}>
                  <td>{index+1}</td>
                  {/* <td>{item.no}</td> */}
                  <td style={{textAlign: 'center', paddingLeft: '1rem'}}>{item.OX || ""}</td>
                  <td style={{textAlign: 'center', paddingLeft: '1rem'}}>{item.정답율 || ""}</td>
                </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    )}
  </>
);
});

export default MarkingStudyTable;