
import React, { useState, useRef, useMemo, useEffect } from 'react';
import './BoardWriteText.scss';
import * as S from '../scss/theme';
import Header from '../Header';
import Button from '@mui/material/Button';

import { useNavigate } from 'react-router-dom';
import axios from "axios";
import Cookies from 'js-cookie'; 
import fnc from '../mymod/commFunction';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedList, setInitialPage } from "../reduxToolkit/reduxVideo";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const BoardWriteText = ({ placeholder }) => {
    
    const svrUrl = process.env.REACT_APP_SERVER_URL;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [title, setTitle] = useState('');
    const [category, setCategory] = useState('');
    const [kind, setKind] = useState('');
    const editor = useRef(null);
    const [content, setContent] = useState('');
    const [startDate, setStartDate] = useState(new Date());
    const [boardCode, setBoardCode] = useState(null);
    const [checked, setChecked] = useState(false);

    const boardTitle = useSelector(state => state.reduxStore_video.boardTitle);
    const selectedVideoList = useSelector(state => state.reduxStore_video.selectedVideoList);
    const isAdmin = useSelector(state => state.reduxStore_video.isAdmin);

    const titleInputRef = useRef(null); 
    const stCode = fnc.getCookie('stCode');

    useEffect(() => {
      // console.log(selectedVideoList, '======= selectedVideoList');
      setStartDate(fnc.fncDate());
      setCategory('');

      if (titleInputRef.current) {
        titleInputRef.current.focus();
      }

    }, []);

    function onPrev() {
     
        // navigate('/faq'); 
        navigate(-1);
    }

 


    function fncInserBoard() {

        const rtn = window.confirm(`${category} [${title}]을 저장하시겠습니까?`);
        if(!rtn) return null;
        
        const boardCode = fnc.fncSirialNum();
        setBoardCode(boardCode);//파일업로드용

        let videoCode;
        if(boardTitle.value ==='qna') {
          videoCode = selectedVideoList.code;
        } else {
          videoCode = '';
        }


        fnc.sendAxiosToken('/insert_board', { title, category, content, kind: boardTitle.value, 
                                              date:startDate, code:boardCode, writer: stCode, videoCode }, (response) => {       
          if (response) {
            dispatch(setInitialPage(0));
            onPrev();
          } else {
            console.log("속성이 obj 내에 없습니다.");
          }
        });
      }
  
      const handleChange = (event) => {
        setChecked(event.target.checked);
        if(event.target.checked){
          setCategory('공지');
        } else {
          setCategory('');
        }
        
        
      };
      
    //=========================================================================
    return (
      <div className="BoardWriteText">
        <S.Section>
        <Header
          onBackClick={() => {
            onPrev();
          }}
          title={boardTitle.text}
        />

        <div className="box_jodit">
          <div className="formGroup">
            <input
              className="titleInput"
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="제목을 입력하세요"
              ref={titleInputRef}
            />
          </div>


         { isAdmin && <div className="chk-notice">
            <label>
              <input
                type="checkbox"
                checked={checked}
                onChange={handleChange}   
              />
             <span>공지사항으로 설정</span> 
            </label>
          </div>}

          <div className="box-textArea">
            <textarea
              name="postContent"
              id="postContent"
              rows="10"
              cols="50"
              placeholder="여기에 게시글을 작성하세요..."
              value={content}
              onChange={(event) => setContent(event.target.value)}
            ></textarea>
          </div>

          <div className="box_btnMenu">
            <Button
              className="btnPrevSave h-btn-green2" onClick={onPrev} >
              목록보기
            </Button>
            <Button
              className="btnPrevSave h-btn-brown" onClick={fncInserBoard}>
              저장하기
            </Button>
          </div>
        </div>
        </S.Section>
      </div>
    );
};

export default BoardWriteText;
