import './VideoReact.scss';
import * as S from '../scss/theme';
import Header from '../Header';
import fnc from '../mymod/commFunction';
import Button from '@mui/material/Button';
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setIsAccorddionExpend } from '../reduxToolkit/reduxVideo';
import useWarnBeforeLeaving from '../comm/useWarnBeforeLeaving'; //페이지 이동시 라이프사이클

import { Player, ControlBar, ReplayControl, ForwardControl, CurrentTimeDisplay, TimeDivider,
  PlaybackRateMenuButton, VolumeMenuButton } from 'video-react';
import "video-react/dist/video-react.css"; // Import css

import thumbnail from '../images/gamja/logo-kr-traspent.png';
import Qna from '../BoardDetailWrite/Qna';

// import { disjoint, dispatch } from 'd3';

const VideoReact = () => {
  const videoUrl = process.env.REACT_APP_VIDEO_URL;
  // const {setIsAccorddionExpend} = useSelector.reduxStore_video;
  const selectedVideoList = useSelector(state => state.reduxStore_video.selectedVideoList);
    
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [fullUrl, setFullUrl] = useState(null);
  const playerRef = useRef(null);
  const [watchedSeconds, setWatchedSeconds] = useState(new Set());
  const [duration, setDuration] = useState(0);
  const [learningRate, setLearningRate] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isActive, setIsActive] = useState(false);


  useWarnBeforeLeaving(true);//이건 사이트를 새로고침하거나 X로 닫기전에 메세지 띄움

  useEffect(() => {
    const fileName = selectedVideoList.fileName;

    if (fileName && videoUrl) {
      setFullUrl(`${videoUrl}/${fileName}`);
    }
      // setFullUrl('https://gamja.i234.me/MathautogenStreaming/gamjaVideo/L24051322142200482.mp4');
   
  }, [selectedVideoList, videoUrl]);

  // useEffect(() => {
  //   console.log(fullUrl, 'fullUrl');
  // }, [fullUrl]);


  useEffect(() => {//play버튼 재생 시 안 보이게
    const handleStateChange = (state) => {
      setIsPlaying(!state.paused);
      if (!state.paused) {
        playerRef.current.manager.rootElement.classList.add("playing");
      } else {
        playerRef.current.manager.rootElement.classList.remove("playing");
      }
    };
  
    const player = playerRef.current;
    if (player && player.subscribeToStateChange) {
      player.subscribeToStateChange(handleStateChange);
    }
  
    return () => {
      if (player && player.unsubscribeFromStateChange) {
        player.unsubscribeFromStateChange(handleStateChange);
      }
    };
  }, []);//play버튼 재생 시 안 보이게
  

  useEffect(() => {
    const handleStateChange = (state) => {
      if (state.duration && state.duration !== duration) {
        setDuration(state.duration);
      }

      const currentSecond = Math.floor(state.currentTime);
      setWatchedSeconds(prevSeconds => {
        const newSeconds = new Set(prevSeconds);
        newSeconds.add(currentSecond);
        return newSeconds;
      });
    };

    const player = playerRef.current;
    if (player && player.subscribeToStateChange) {
      player.subscribeToStateChange(handleStateChange);
    }

    // Cleanup on unmount
    return () => {
      if (player && player.unsubscribeFromStateChange) {
        player.unsubscribeFromStateChange(handleStateChange);
      }
    };
  }, [duration]);

  useEffect(() => {
    if (duration > 0) {
      let rate = (watchedSeconds.size / duration) * 100;
      rate = rate > 100 ? 100 : rate;
      setLearningRate(rate);
    }
  }, [watchedSeconds, duration]);

  const handleVideoEnd = () => {
    setLearningRate(100);
  };

  function onPre() {
    const listenTime = watchedSeconds.size;

    if(listenTime > 60) {
      fncInsert_videoListenSt();

      const rtn = window.confirm('시청을 종료하시겠습니까?');
      if(!rtn) return;
    }else {
      const rtn = window.confirm('시청을 종료하시겠습니까?');
      if(!rtn) return;
      // alert(`1분 미만은 기록하지 않습니다.`);
    }
  
    if (playerRef.current) {
      playerRef.current.pause(); // 비디오 재생 멈춤
      playerRef.current.seek(0); // 비디오 시간을 0으로 초기화
    }
    
    // navigate("/videoList")
    navigate(-1);
  }

  function fncInsert_videoListenSt() {   

   const parentCode= selectedVideoList.code;
   const code= fnc.fncSirialNum('L');
   const acaCode = fnc.getCookie('acaCode');
   const stCode = fnc.getCookie('stCode');
   const listenPercent=learningRate.toFixed(0);
   const totalTime = duration;
   const listenTime = watchedSeconds.size;
   const parentName= selectedVideoList.fileName;

    fnc.sendAxios('/insert_videoListenSt', {parentCode, code, acaCode, stCode, 
      listenPercent, totalTime, listenTime, parentName }, (res) => {
      if (res) {   

      } else {
        console.log("속성이 obj 내에 없습니다.");
      }
    });

  }

 // qna className
  const toggleClassNameQna = (value) => {
    setIsActive(value);
    if(value === false) {
      dispatch(setIsAccorddionExpend(false));
    }
  };

  //==================================================
  return (
    <div className="VideoReact">
      <S.Section>
      {!isActive && <Header onBackClick={onPre} title="영상강의" />}
      <div className="wrap_player">
        <div className="box_player">
          <Player
            ref={playerRef}
            // poster={thumbnail}
            // autoPlay
            muted={false}
            playsInline
            onEnded={handleVideoEnd}
            type="video/mp4"
            loop={true}
          >
          {/* fullUrl && 안 넣으면 재생이 안 되는경우가 있음 완전 개고생 */}
          {fullUrl && <source src={fullUrl} />}
            <ControlBar>
              <ReplayControl seconds={10} order={1.1} />
              <ForwardControl seconds={10} order={1.2} />
              <CurrentTimeDisplay order={4.1} />
              <TimeDivider order={4.2} />
              <PlaybackRateMenuButton rates={[2, 1.5, 1, 0.5]} order={7.1} />
              <VolumeMenuButton />
            </ControlBar>
          </Player>
        </div>
     
      {/* 일단 죽여놓는다. */}
    
      {false && (
        <div className="box_learningRate">
          <div className="learningRate">학습률: {learningRate.toFixed(2)}%</div>
        </div>
      )}

      <div className={isActive ? "wrap_qna-full" : "wrap_qna"}>
       { isActive && <Header
          onBackClick={() => toggleClassNameQna(false)}
          title={selectedVideoList.unit2}
        />}
        <div className="box_qna" onClick={() => toggleClassNameQna(true)}>
          {/* Q&A */}
          <Qna />
        </div>
      </div>
      </div>
      </S.Section>
    </div>
  );
};

export default VideoReact;
