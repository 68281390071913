import * as S from '../scss/theme';
import React, { useEffect, useState, useRef } from 'react';
import PaginatedItems from './PaginatedItems';
import axios from "axios";
import Cookies from 'js-cookie'; 
import fnc from '../mymod/commFunction';
import Button from '@mui/material/Button';

import { useSelector, useDispatch } from 'react-redux';
import { setBoardTitle } from "../reduxToolkit/reduxVideo";
import Header from '../Header';
import { useNavigate } from 'react-router-dom';


  const Faq = () => {

    const svrUrl = process.env.REACT_APP_SERVER_URL;
    // const isCurrentItemRefresh_faq = useSelector(state => state.isCurrentItemRefresh_faq);
    const isCurrentItemRefresh = useSelector(state => state.reduxStore_video.isCurrentItemRefresh);//이거 하나로하자 어차피 다른 건 마우트 해제돼서 상관 없지 않나?
  
  
    const [list, setList] = useState(null);
    const [items, setItems] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
  
    useEffect(() => {
      fncSelectList();
    }, [isCurrentItemRefresh]);
  
    function onPrev() {
      navigate('/menu');
    }
    
      function fncSelectList() {   
        const stCode = fnc.getCookie('stCode');
        const kind ='faq';
        fnc.sendAxios('/boardList_faq', { stCode, kind }, (response) => {

          if (response) {
            dispatch(setBoardTitle({text:'게시판', value: kind, textEng:'FAQ'}));//사용 안 함
            setItems(response);

          } else {
            console.log("속성이 obj 내에 없습니다.");
          }
        });
      }
  
      // const sendAxios = async (url, data, callback) => {
      //   try {
      //     const response = await axios.post(`${svrUrl}${url}`, data);
      //     let jsonObj = response.data;
      //     callback(jsonObj);
      //   } catch (error) {
      //     console.error("Axios 요청 중 오류가 발생했습니다:", error);
      //   }
      // };
  
      //-------------------------------------------------------------------------
  
      return (
        <div className="Faq">
          <S.Section>
          <Header onBackClick={onPrev} title='게시판' />  {/* Header가 여기있어야 깜빡임이 없다.*/}
         { items && <PaginatedItems items={items} itemsPerPage={20} /> }   
         </S.Section>
        </div>
      );

}

export default Faq;
