import  "./markingMenuSpecial.css"; 

import React, { useEffect, useState } from "react";


import { useSelector, useDispatch } from 'react-redux';
import {setMarkingList, addInfo, } from "../reduxToolkit/redux";


function MarkingMenuSpecial({ setInputValue, setIsShowSpecial, selectedRow }) {

const [inputValue2, setInputValue2] = useState(''); 



  useEffect(() => {
   
  }, []);

  
const buttons = [
    '≥', '≤', '<', '>', '²', '³', '⁴', '+', '-', '×', '÷', '√', 'π', '±',
    '≠', '=', '∞', '∑', '∫', 'ω', '′', '″', 'Ø', '∈', '∋', '⊂', '⊃', '∪', '∩',
    '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '?', 'x', 'y', 'z', 'X', 'Y', 'Z',
    'a', 'b', 'c', 'A', 'B', 'C', 'α', 'β', 'γ', 'θ','닫기'
  ];


  
  // const selectedRow = useSelector(state => state.reduxStore.selectedRow);
  // const markingList = useSelector(state => state.reduxStore.markingList);
  const dispatch = useDispatch();

  function fncOnMarkingInput() {
    dispatch(setMarkingList({ no: selectedRow, value: inputValue2 }));
    setIsShowSpecial(false);
  }


  return (

    <div className="MarkingMenuSpecial2">

        <div className="box-btn_special2">
            { buttons.map((btnValue, index) => (
            <button 
                key={index} 
                className={`btn_special ${btnValue === '닫기' ? 'btn-special-close' : ''}`}
                onClick={() => {
                        if (btnValue === '닫기') {
                            setIsShowSpecial(false);
                        } else {
                            setInputValue2((prevValue) => prevValue + btnValue);
                        }
                }}>{btnValue}</button>
                //onClick={() => { setInputValue(prevValue => prevValue + buttons[index]); }} 축약한 것
                // setInputValue 함수에 value 전달  함수를 안쓰면 렌더링 될 때만다 실행 됨                    
            ))}

        </div>


        <div className="box_special_footer">
            { <input type="text" className="txtMark" value={inputValue2} onChange={(e) => setInputValue2(e.target.value)} autoComplete="off" />}
            {<button className="btnCls" onClick={() => {setInputValue2('')}}>cls</button>}
            { <button className="btnInsertMark" onClick={() => fncOnMarkingInput()}>넣기</button>} 
        </div>

    </div>
  );
}

export default MarkingMenuSpecial;
