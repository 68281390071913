
import './Menu.scss';
import * as S from './scss/theme';
import React from "react";
import { useNavigate } from "react-router-dom"; 
import Button from '@mui/material/Button';
import fnc from './mymod/commFunction';
import img_coaching from './images/gamja/design/coaching.png';
import img_test from './images/gamja/design/test.png';
import img_study from './images/gamja/design/study.png';
import img_qna from './images/gamja/design/qna.png';
import img_stManage from './images/gamja/design/stManage.png';
import img_allimjang from './images/gamja/design/allimjang.png';


import Header from "./Header";
import imgLogo from './images/gamja/logo-kr-traspent.png';
import DisplayLogo from './comm/DisplayLogo';
import { setIsAnonymous } from "./reduxToolkit/reduxVideo";
import { useDispatch, useSelector } from "react-redux";
import imgTop from './images/gamja/design/bg_menu_hp.png';
import imgTopPad from './images/gamja/design/bg_menu_pad.png';
const Menu = () => {
const navigate = useNavigate();  // Change this
const dispatch = useDispatch();
const isAuthority = useSelector(state => state.reduxStore_video.isAuthority);
const isGangsa = useSelector(state => state.reduxStore_video.isGangsa);
const stName = fnc.getCookie('stName');


  return (
    <> 

     
      <div className="Menu">
        <S.Section>
        <Header onBackClick={() => navigate("/")} title={`${stName} 학생, 안녕하세요!`} />
        <section className="section-imgTop">
          {/* <img className="imgTop" src={imgTopPad} /> */}
          <div className="imgTop"> </div>
        </section>

          <section className="section_menu">
            
            <Button
               className="btnMenu" onClick={() => {
                dispatch(setIsAnonymous(false));
                navigate("/videoGroup")}}        
            >
            <img src={img_study} alt="Coaching" className="menuIcon" />
              영상강의 듣기
            </Button>

            {!isGangsa && <div className={`tablet-disable`}>
                <Button
                   className={"btnMenu"} onClick={() => {
                    dispatch(setIsAnonymous(false));
                    navigate("/markinglist2")}}        
                >
                  <img src={img_test} alt="Coaching" className="menuIcon" />
                  TEST
                </Button>
            </div>}

            {!isGangsa && <div className={`phone-disable`}>
                <Button
                   className={`btnMenu`} onClick={() => {
                    dispatch(setIsAnonymous(false));
                    navigate("/markinglist")}}       
                >
                  <img src={img_test} alt="Coaching" className="menuIcon" />
                  TEST
                </Button>
            </div>}

          

            <Button
              variant="outlined" className={`btnMenu`} onClick={() => {
                dispatch(setIsAnonymous(false));
                navigate("/reservationApp")}}       
            >
              <img src={img_coaching} alt="Coaching" className="menuIcon" />

              코칭예약
            </Button>

           
            <Button
              variant="outlined" className="btnMenu" onClick={() => {
                dispatch(setIsAnonymous(true));
                navigate("/faq");}}        
            >
             <img src={img_qna} alt="Coaching" className="menuIcon" />
              질문 & 소통
            </Button>

            {isGangsa && <div className={`tablet-disable`}>
                <Button
                  variant="outlined" className={`btnMenu`} onClick={() => {
                    dispatch(setIsAnonymous(false));
                    navigate("/managementSt")}}       
                >
                 <img src={img_stManage} alt="Coaching" className="menuIcon" />
                  학생관리
                </Button>
            </div> }

            {isGangsa && <Button
              variant="outlined" className={`btnMenu`} onClick={() => {
                dispatch(setIsAnonymous(false));
                navigate("/managementSt_sms")}}       
            >
              <img src={img_allimjang} alt="Coaching" className="menuIcon" />

              알람장 발송 (PC전용)
            </Button>}


            {false && <DisplayLogo imgLogo = {imgLogo} width="20%" height="auto" top='60%' backgroundColor="transparent" /> }
         

            <br></br>
           
          </section>

          </S.Section>

      </div>
    </>
  );
};

export default Menu;
