import './ManagementStApp.scss';
import * as S from '../scss/theme';
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";

import Checkbox from '@mui/material/Checkbox';

import Listbox from '../comm/Listbox';
import fnc from '../mymod/commFunction';
import Header from '../Header';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import ManagementSt_attend from './ManagementSt_attend';
import ManagementSt_attend_result from './ManagementSt_attend_result';
import ManagementSt_coaching from './ManagementSt_coaching';
import ManagementSt_coaching_attend from './ManagementSt_coaching_attend';
import ManagementSt_homework_evalue from './ManagementSt_homework_evalue';

import ManagementSt_homework from './ManagementSt_homework';
import ManagementSt_coaching_homework from './ManagementSt_coaching_homework';
import ManagementSt_sms from './ManagementSt_sms';
import ManagementSt_coachingTec from './ManagementSt_coachingTec';

import { useSelector, useDispatch } from 'react-redux';
import { setSelectedClassCode, setSelectedTab, setAttendReason, setIsCoaching} from  '../reduxToolkit/reduxSt';
import TableMui from '../comm/TableMui';
import { Button } from '@mui/material';

const ManagementStApp = () => {
    const navigate = useNavigate(); 
    const dispatch = useDispatch();
    const [list, setList] = useState([]);
    const [classList, setClassList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    // const [isCoaching, setIsCoaching] = useState(false);
    const [selectedClass, setSelectedClass] = useState(null);
    
    const selectedTab = useSelector(state => state.reduxStore_st.selectedTab);
    const isCoaching = useSelector(state => state.reduxStore_st.isCoaching);


    // const items = [
    //     { key: '1', name: 'Aardvark' },
    //     { key: '2', name: 'Cat' },
    //     { key: '3', name: 'Dog' },
    //     { key: '4', name: 'Kangaroo' },
    //     { key: '5', name: 'Panda' },
    //     { key: '6', name: 'Snake' },
    // ];


    useEffect(() => {
        fncSelect_attend_reason();
        fncSelect_gangsa();
        dispatch(setSelectedClassCode({}));//하단 table 초기화
    }, []);


    
    function fncSelect_attend_reason() {
      const title = '결석사유';

      fnc.sendAxios('/select/manageMentSt/attend_reason_setCombo', { title }, (res) => {
        if (res) {  
            const records = getRecords_attendReason(res);
            dispatch(setAttendReason(records));
        } else {
            console.log(res,"managementStRoutes/select/manageMentSt/attend_reason");
        }
      });
    }

    function getRecords_attendReason(res) {
      let records = [];
      // res가 배열인지 확인
      if (!Array.isArray(res)) { return records; }
    
      records.push({ value: '', label: '결석사유를 선택하세요!' });
      res.forEach(item => {
        const value = item["value"];  
        const label = item["label"];                
        records.push({ value, label });
      });
    
      return records;
    }
    


    //-------------------------------일단 보류 (강사 로그인 필요) 지우지 말것
    // useEffect(() => {
    //   const gangsaCode = 1000;//fnc.getCookie('stCode');
    //   console.log(gangsaCode, 'gangsaCode');
      
    //   if (list.length > 0) {
    //     const item = list.find(element => element.key === parseInt(gangsaCode));
    //     if (item) {
    //       setSelectedItem(item);
    //       fncSelect_classList(item.key);
    //     } else {
    //       console.log('gangsaCode에 해당하는 항목이 리스트에 없습니다.');
    //     }
    //   }
    // }, [list]);
    
  
  const handleSelect = (item) => {//강사
    // console.log(item, 'item');
    setSelectedItem(item);
    fncSelect_classList(item.key);
    dispatch(setSelectedClassCode({}));//하단 table 초기화
  };

  const handleSelect_class = (item) => {
    dispatch(setSelectedClassCode(item));
    setSelectedClass(item);

    if(item.type == '코칭') {
      dispatch(setIsCoaching(true))
    } else {
      dispatch(setIsCoaching(false))
    }

  };


  function fncSelect_gangsa() {
    fnc.sendAxios('/select/manageMentSt/gangsa', { }, (res) => {
        if (res) {  
            setList(getRecords(res));
      } else {
            console.log(res,"select/manageMentSt/gangsa");
      }
    });
}


function getRecords(res) {
    let records = [];
    res.forEach(item => {    
      const key = item["codeTec"];  
      const name = item["nameTec"];           
      records.push({ key, name });
    });
   
    return records;
  }

  
  function fncSelect_classList(gangsaCode) {
    fnc.sendAxios('/select/manageMentSt/classList', { gangsaCode }, (res) => {
        if (res) {  
            setClassList(getRecords_classList(res));
      } else {
            console.log(res,"select/manageMentSt/classList");
      }
    });
}


function getRecords_classList(res) {
    let records = [];
    res.forEach(item => {
      const key = item["classCode"];  
      let name = item["className"];    
      const classCode = item["classCode"];  
      const className = item["className"];  
      const gangsaCode = item["gangsaCode"];  
      const gangsaName = item["gangsaName"];  
      const type = item["type"];   
      const grade = item["grade"];   
       
      name = `(${type}) ${name}`; 
      records.push({ key, name, classCode, className, gangsaCode, gangsaName, type, grade });
    });
    return records;
  }

  
function fncTestClose() {

  const confirmed = window.confirm('학생관리를 종료하시겠습니까?');
  if (!confirmed) return

  setTimeout(() => {
    navigate(-1);
  }, 200); 


}

  //===================================================
  return (
    <div className='ManagementStApp'>
<S.Section>
      <div className="header-container">
        <Header onBackClick={() => fncTestClose() } title={selectedClass?.className || '학생관리'} />
      </div>
        
      <section className='section-gangsa-class'>
       { true && <article className='article-gangsa'>
          <p className='listTitle'>담임</p>
          <Listbox items={list} onSelect={handleSelect} /> 
        </article>}

        <article className='article-class'>
          <p className='listTitle'>반명</p>
          <Listbox items={classList} onSelect={handleSelect_class} /> 
        </article>
      </section>
 
      <section className='section-main'>

      <Tabs selectedIndex={selectedTab} onSelect={(index) => dispatch(setSelectedTab(index))}>

        <TabList className='tabList'>
          {!isCoaching && <Tab>출결</Tab>}
          {isCoaching && <Tab>코칭출결</Tab> }
          {!isCoaching && <Tab>과제</Tab>}
          {isCoaching && <Tab>코칭과제</Tab> }
          {/* <Tab>발송문</Tab> 메뉴로 보냄*/}

        </TabList>

        {/* =============================================== 정규 */}
        {!isCoaching && <TabPanel>   
          <Tabs>
            <TabList className='subTabList'>
              <Tab>반별 출결 입력</Tab>
              <Tab>반별 출결 조회</Tab>
            </TabList>

            <TabPanel>
              <ManagementSt_attend />
            </TabPanel>

            <TabPanel>
              <ManagementSt_attend_result />
            </TabPanel>
          </Tabs>
        </TabPanel>}

        {/* 정규 과제 관련 탭들 */}
        {!isCoaching && <TabPanel>   
          <Tabs>
            <TabList className='subTabList'>
              <Tab>반별 과제 입력</Tab>
              <Tab>반별 과제 평가 및 조회</Tab>
            </TabList>

            <TabPanel>
              <ManagementSt_homework />
            </TabPanel>

            <TabPanel>
              <ManagementSt_homework_evalue />
            </TabPanel>
          </Tabs>
        </TabPanel>}

      {/* =============================================== 코칭 */}
        {/* 코칭 출결 관련 탭들 */}
        {isCoaching && <TabPanel>   
          <Tabs>
            <TabList className='subTabList'>
              <Tab>코칭 반별 예약</Tab>
              <Tab>코칭 반별 승인</Tab>
              <Tab>코칭 반별 출결 입력</Tab>
              <Tab>코칭 반별 출결 조회</Tab>
            </TabList>

            <TabPanel>
              <ManagementSt_coachingTec />
            </TabPanel>

            <TabPanel>
              <ManagementSt_coaching />
            </TabPanel>

            <TabPanel>
              <ManagementSt_coaching_attend />
            </TabPanel>

            <TabPanel>
              <ManagementSt_attend_result />
            </TabPanel>

          </Tabs>

        </TabPanel>}

        {/* 코칭 과제 관련 탭들 */}
        {isCoaching && <TabPanel>  
          <Tabs>
            <TabList className='subTabList'>
              <Tab>반별 코칭과제 입력</Tab>
              <Tab>반별 코칭과제 평가 및 조회</Tab>
            </TabList>

            <TabPanel>
              <ManagementSt_coaching_homework />
            </TabPanel>

            <TabPanel>
              <ManagementSt_homework_evalue />
            </TabPanel>
          </Tabs>
        </TabPanel>}
      {/* =============================================== 발송문 */}
            {/* <TabPanel>
              <ManagementSt_sms />
            </TabPanel> */}
              
      </Tabs>

      </section>


      </S.Section>
    </div>  
  );


}

export default ManagementStApp;
