import "./markingApp.scss"; //css는 Top에 와야..
import axios from "axios";

import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import Header from "../Header";
import MarkingQue from "./MarkingQue";
import MarkingTitleBar from "./MarkingTitleBar";
import MarkingSetting from "./MarkingSetting";
import MarkingTable from "./MarkingTable";
import MarkingMenu from "./MarkingMenu";
import Cookies from 'js-cookie';

const svrUrl = process.env.REACT_APP_SERVER_URL;

function MarkingApp() {
  const navigate = useNavigate(); 
  const location = useLocation();
  const testNm = location.state?.testNm;
  const munCnt= location.state?.munCnt;
  const [list, setList] = useState(null);
  const [selectedRow, setSelectedRow] = useState(0);
  const [isShowList, setIsShowList] = useState(true);
  const [no, setNo] = useState("1번");
  const [info, setInfo] = useState(''); 
  const [imageSize, setImageSize] = useState(300);
  const [showMarkingSetting, setShowMarkingSetting] = useState(false)
  const [chkNextMoonje, setChkNextMoonje] = useState(() => Cookies.get('chkNextMoonje'));
  const [imgSize, setImgSize] = useState(300);

  const [markingList, setMarkingList] = useState(() => {
    const initialState = {};
    for (let i = 1; i <= munCnt; i++) {
      initialState[i] = '';
    }
    return initialState;
  });

 
  const [showModal, setShowModal] = useState(false);
  const [slideHandlePrevNext, setSlideHandlePrevNext] = useState(null);
  const [markingNum, setMarkingNum]= useState();
  
    
  const isFirstRender = useRef(true);
  const markingMenuRef = useRef();//MarkingMenu의 버튼 클릭 함수 참조
  // const markingMenuInsertMarkRef = useRef();


  //-----------------------------------------------------------------
  useEffect(() => {//로딩 후 첫번째 문제 띄우기 (parent에서 해야 함)
  if (list && isFirstRender.current) {
    fncHandleNext();
    isFirstRender.current = true;
  }
  }, [list]);



  function fncHandleNext() {
    if (list && selectedRow !== null) {
      const nextIndex = selectedRow; 
      if (nextIndex < list.length) {
        const nextItem = list[nextIndex];
        setSelectedRow(nextItem.no);
      } else {
        alert('마지막입니다!');
      }
    }
  }

  function fncPrev() {
    if (list && selectedRow !== null) {
      const currentIndex = selectedRow - 1;  // 배열 인덱스는 0부터 시작하므로 -1을 합니다.
      if (currentIndex > 0) {
        const nextItem = list[currentIndex - 1];
        fncSelectRow(nextItem);
        setSlideHandlePrevNext(1);
      } else {
        alert("처음입니다!!");
      }
    }
  }

  function fncNext() {
  
    if (list && selectedRow !== null) {
      const nextIndex = selectedRow; // item.no가 인덱스 + 1 이므로
      if (nextIndex < list.length) {
        const nextItem = list[nextIndex];
        fncSelectRow(nextItem);
        setSlideHandlePrevNext(-1);
      } else {
        alert('마지막입니다!!');
      }
    }
  }



  function fncSelectRow(item) {
    if (!item) return false;
    setSelectedRow(item.no);
    setNo(item.no + '번');
    setInfo(markingList[item.no]);
    setMarkingNum(markingList[item.no]);
    
    
  }

const handleCloseSetting = () => {
  setShowMarkingSetting(false); 
}


function fncTestClose() {
  const confirmed = window.confirm('시험을 종료하시겠습니까?');
  if (confirmed) {
    navigate("/markinglist");
  }
}


const callMarkingFunctionFromMenu_fncNumberMarking = (no, value) => {
  markingMenuRef.current.fncNumberMarking(no, value);
}

const callMarkingFunctionFromMenu_fncInputMarking = (no, value) => {
  markingMenuRef.current.fncInputMarking(no, value);
}



  return (
    <div className="MarkingApp">
      <div className="header-container">
        <Header onBackClick={() => fncTestClose() } title={testNm} />
      </div>

      <input
        type="button"
        className="btnSetting"
        onClick={() => setShowMarkingSetting(true)}
      />
      {showMarkingSetting && ( <MarkingSetting
          setChkNextMoonje={setChkNextMoonje}
          handleClose={handleCloseSetting}
        />
      )}

      {!isShowList && list && selectedRow && ( <MarkingTitleBar 
          no={no} 
          info={info} 
          selectedList={list[selectedRow-1]}
          testNm={testNm}
          showModal={showModal} setShowModal={setShowModal}
          fncPrev={fncPrev}
          fncNext={fncNext}
          slideHandlePrevNext={slideHandlePrevNext} setSlideHandlePrevNext={setSlideHandlePrevNext}
          setIsShowList={setIsShowList}   
          selectedRow={selectedRow}
          onMarkingNumber={callMarkingFunctionFromMenu_fncNumberMarking}
          onMarkingInput={callMarkingFunctionFromMenu_fncInputMarking}
          isStudy={false}
          markingNum={markingNum} setMarkingNum={setMarkingNum}
          
          
        />
      )}

   
      {
        <MarkingTable
          isShowList={isShowList}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          list={list}
          setList={setList}
          setNo={setNo}
          setInfo={setInfo}
          markingList={markingList}
          setImgSize={setImageSize}
          markingNum={markingNum} setMarkingNum={setMarkingNum}

        />
      }


        <MarkingQue  
          list={list}
          selectedRow={selectedRow}
          imgSize={imgSize}
          setIsShowList={setIsShowList}
          fncPrev={fncPrev}
          fncNext={fncNext} />
     
        <MarkingMenu     
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          setNo={setNo}
          setInfo={setInfo}
          isShowList={isShowList} setIsShowList={setIsShowList}
          list={list}
          imgSize={imgSize}
          setImgSize={setImgSize}
          markingList={markingList}
          setMarkingList={setMarkingList}
          showModal={showModal} setShowModal={setShowModal}
          ref={markingMenuRef} 
          markingNum={markingNum} setMarkingNum={setMarkingNum}
        />
   
    </div>
  );
}

export default MarkingApp;
