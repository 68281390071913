import './BoardUpdateDeleteText.scss';
import React, { useState, useEffect, useRef, useMemo } from 'react';
// import JoditEditor from 'jodit-react';
// 

import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import Cookies from 'js-cookie'; 
import fnc from '../mymod/commFunction';
import Header from '../Header';
import { useSelector, useDispatch } from 'react-redux';
import {setIsCurrentItemRefresh, setIsCurrentItemRefresh_faq} from '../reduxToolkit/reduxVideo';


const BoardUpdateDeleteText = ({setIsModal}) => {
    
    const svrUrl = process.env.REACT_APP_SERVER_URL;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [sno, setSno] = useState('');
    const [content, setContent] = useState('');
    const [title, setTitle] = useState('');
    const [category, setCategory] = useState('');
    const [kind, setKind] = useState('');
    const [startDate, setStartDate] = useState(new Date());

    const selectedList = useSelector(state => state.reduxStore_video.selectedList);
    const isCurrentItemRefresh = useSelector(state => state.reduxStore_video.isCurrentItemRefresh);
    // const isCurrentItemRefresh_faq = useSelector(state => state.reduxStore_video.isCurrentItemRefresh_faq);

    useEffect (() => {

      setSno(selectedList.sno);
      setTitle(selectedList.title);
      setCategory(selectedList.category);
      setKind(selectedList.kind);
      setContent(selectedList.content);
      
    }, [selectedList]);


   

    function onPrev() {
     
      setIsModal(false)
    }

 

    function fncUpdateBoard() {   
        if (!window.confirm(`[${title}]을 수정하시겠습니까?`)) {
          return;
        }

        fnc.sendAxios('/Update_board', { sno, title, category, content, kind, date:startDate }, (response) => {       
          if (response) {
            dispatch(setIsCurrentItemRefresh(!isCurrentItemRefresh));
            // dispatch(setIsCurrentItemRefresh_faq(!isCurrentItemRefresh_faq));
            onPrev();
          } else {
            console.log("속성이 obj 내에 없습니다.");
          }
        });

      }
      

      function fncDeleteBoard() {

        if (!window.confirm(`[${title}]을 정말로 삭제하시겠습니까?`)) {
          return;
        }

        fnc.sendAxios('/delete_board', { sno }, (response) => {       
          if (response) {
            dispatch(setIsCurrentItemRefresh(!isCurrentItemRefresh));
            onPrev();
          } else {
            console.log("속성이 obj 내에 없습니다.");
          }
        });

      }


    //=========================================================================
    return (

    <div className='BoardUpdateDeleteText'>
    <Header onBackClick={()=>{setIsModal(false)}} title={'수정 및 삭제하기'} drection={1} />
       {true && <div className='box_jodit'>

            <div className='formGroup'>      
                    <input
                      className='titleInput'
                      type="text"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      placeholder="제목을 입력하세요"
                       />
                   
              </div>


            <div className='box-textArea'>
                <textarea 
                    name="postContent" 
                    id="postContent" 
                    rows="10" 
                    cols="50"
                    placeholder="여기에 게시글을 작성하세요..."    
                    value={content}       
                    onChange={(event) => setContent(event.target.value)}     
                ></textarea>
            </div>
       
              <div className='box_btnMenu'>
                  <Button className='btnPrevSave' variant="contained"  onClick={onPrev}>목록보기</Button>
                  <Button className='btnPrevSave' variant="contained" color="error" onClick={fncUpdateBoard}>수정하기</Button>
                  <Button className='btnPrevSave' variant="contained" color="error" onClick={fncDeleteBoard}>삭제하기</Button>
              </div>
        

        </div>

        }
      
    </div>
    );
};

export default BoardUpdateDeleteText;
