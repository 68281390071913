import React from "react";
import './PrivacyPolicy.scss';
import { Navigate, useNavigate } from "react-router-dom";


const PrivacyPolicy = () => {

    const navigate = useNavigate();

function onPrev() {
    navigate('/');
}

    return(
        <div className="PrivacyPolicy">



<div class="container">

<div className="box_title">
   <div className="back1" onClick={onPrev}></div>
   <div className="title"> 개인정보처리방침</div>
   <dvi className='back_empty'> </dvi>
</div>

<strong> ▷ 개인정보의 수집 및 이용 목적</strong>
<br/>
회원제 서비스 이용 및 제한적 본인 확인제에 따른 본인확인, 개인식별, 탈퇴회원 사용방지, 가입의사 확인, 가입 및 가입횟수 제한, 추후 법정 대리인 본인확인, 분쟁 조정을 위한 기록보존, 불만처리 등 민원처리, 고지사항 전달<br/><br/>

<strong> ▷ 수집하는 개인정보의 항목</strong><br/>

가. 필수항목 : 이름, ID(고유번호), Password(비밀번호), 닉네임, e-mail, 휴대전화, 기타 회사가 필요하다고 인정하는 사항(아이핀 회원은 생년월일,동일인식별정보(CI), 성별, 아이핀 번호)<br/>
나. 선택항목 : 주식 경력, 투자 금액<br/>
다. 서비스 이용과정에서 아래와 같은 정보들이 자동으로 생성되어 수집될 수 있습니다.<br/>
- IP Address, 쿠키, 방문 일시, 서비스 이용 기록<br/>
라. 회원정보 변경 및 각종 서비스 이용 시 아래와 같은 정보들이 수집될 수 있습니다.<br/>
- 주소, 연락처, 은행명, 계좌번호, 휴대전화번호, 전화번호 등<br/><br/>

<strong> ▷ 개인정보의 보유 및 이용기간</strong><br/>
회원의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.<br/>
가. 관련법령에 의한 정보보유 사유<br/>
상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.<br/>
- 웹사이트 방문기록<br/>
보존 이유 : 통신비밀보호법<br/>
보존 기간 : 3개월<br/>
- 본인확인에 관한 기록<br/>
보존 이유 : 정보통신망 이용촉진 및 정보보호 등에 관한 법률<br/>
보존 기간 : 6개월<br/>
- 소비자의 불만 또는 분쟁처리에 관한 기록<br/>
보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률<br/>
보존 기간 : 3년<br/>
나. 회사 내부 방침에 의한 정보보유 사유<br/>
- 회원가입, 대금결제 및 재화 등의 공급에 관한 기록<br/>
보존 이유 : 회원관리, 계약관리<br/>
보존 기간 : 대금의 완제일 또는 채권소멸 소효시간이 만료되는 시점까지<br/>
그외 보유 기간 외에 계속 보유하여야 할 필요가 있는 경우에는 회원님의 동의를 받겠습니다.<br/><br/>

<strong> ▷ 개인정보의 보유 및 이용 동의철회</strong><br/>
회원가입시 동의한 개인정보 수집, 이용, 제공에 대한 철회는 언제든지 하실 수 있습니다. 웹사이트 상의 회원탈퇴를 하시면 웹사이트 상에서 수집한 정보에 대하여 동의철회로 간주합니다.<br/>
당사 개인정보관리책임자에게 e-mail, 전화 등으로 연락하시면 웹사이트 상의 개인정보 삭제 및 필요한 조치를 하실 수 있습니다.
<br/><br/>

개인정보 관리 책임자 : 민석준<br/>
전화번호 : 010-7416-3301<br/>
</div>

        </div>
    );
}

export default PrivacyPolicy;