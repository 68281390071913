import './ManagementSt_attend_result.scss';
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import fnc from '../mymod/commFunction';
import { useDispatch, useSelector } from 'react-redux';
import ComboboxMui from '../comm/ComboboxMui';
import { Button } from '@mui/material';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Checkbox from '@mui/material/Checkbox';
import {setSelectedDate, isCoaching} from  '../reduxToolkit/reduxSt';
import Dtpicker2 from '../comm/Dtpicker2';

const ManagementSt_attend_result = () => {
    const navigate = useNavigate(); 
    const [optionData, setOptionData] = useState([]);
    const [list, setList] = useState([]);
    const [optionList, setOptionList] = useState({});
    const selectedDateString = useSelector(state => state.reduxStore_st.selectedDate);
    const [startDate, setStartDate] = useState(new Date(selectedDateString)); // 초기화 시에만 사용
    const selectedClassCode = useSelector(state => state.reduxStore_st.selectedClassCode);
    const attendReason = useSelector(state => state.reduxStore_st.attendReason);
    const isCoaching = useSelector(state => state.reduxStore_st.isCoaching);
    const classCode = selectedClassCode ? selectedClassCode.classCode : '';//selectedClassCode 이거 객체로 사용
    const className = selectedClassCode ? selectedClassCode.className : '';
    const gangsaCode = selectedClassCode ? selectedClassCode.gangsaCode : '';
    const gangsaName = selectedClassCode ? selectedClassCode.gangsaName : '';

    const [selectedItems, setSelectedItems] = useState([]);//체크박스
    const [selectAll, setSelectAll] = useState(false);//체크박스
    const [isRefresh, setIsRefresh] = useState(false);
    const dispatch = useDispatch();


    useEffect(()=> {
      setOptionData(attendReason);
    }, []);
  
    useEffect(() => {
      const lessonDate = fnc.formatDate(startDate);
      fncSelect_stList(classCode, lessonDate);
      dispatch(setSelectedDate(startDate.toISOString())); // 상태 업데이트
    }, [classCode, startDate, isRefresh]);


    
  function fncSelect_attend_reason() {
    const title = '결석사유';

    fnc.sendAxios('/select/manageMentSt/attend_reason_setCombo', { title }, (res) => {
      if (res) {  
        setOptionData(getRecords_attendReason(res));
      } else {
            console.log(res,"managementStRoutes/select/manageMentSt/attend_reason");
      }
    });
  }

  function getRecords_attendReason(res) {
    let records = [];
    // res가 배열인지 확인
    if (!Array.isArray(res)) { return records; }
   
    records.push({ value: '', label: '결석사유를 선택하세요!' });
    res.forEach(item => {
      const value = item["value"];  
      const label = item["label"];                
      records.push({ value, label });
    });
   
    return records;
  }

  // const optionData = [
  //   { value: '', label: '사유를 선택를 선택하세요' },
  //   { value: '병가', label: '병가' },
  //   { value: '개인사유', label: '개인사유' },
  //   { value: '기타', label: '기타' },
  // ];
     
  function fncSelect_stList(classCode, lessonDate) {
    fnc.sendAxios('/select/manageMentSt/attendResult', { classCode, lessonDate }, (res) => {
      if (res) {  
            setList(getRecords_stList(res));
      } else {
            console.log(res,"select/manageMentSt/class_stList");
      }
    });
  }




function getRecords_stList(res) {
    let records = [];
    let initialOptionList = {}; // optionList 초기화 객체
    for (let i = 0; i < res.length; i++) { 
        const sno = res[i]["sno"];  
        const classCode = res[i]["classCode"];  
        const className = res[i]["className"];  
        const gangsaCode = res[i]["gangsaCode"]; 
        const gangsaName = res[i]["gangsaName"]; 
        const stCode = res[i]["stCode"];  
        const stName = res[i]["stName"];     
        const school = res[i]["school"];  
        const hp= res[i]["hp"];      
        const parentHp= res[i]["parentHp"];  
        const attend= res[i]["attend"];         
        const lessonDate= res[i]["lessonDate"];    
        const reason= res[i]["reason"];    

        const achievement = res[i].achievement || '';
        const diligence = res[i].diligence || '' ;
        const progress = res[i].progress || '';
        
        // optionList에 resone 값을 추가
        initialOptionList[i] = reason || '';

        records.push({ sno, classCode, className, gangsaCode, gangsaName, stCode, stName, school, hp, parentHp, 
          attend, lessonDate, reason, achievement, diligence, progress });
    }

     //optionList를 초기화
     setOptionList(initialOptionList);


    return records;
  }


  

  //----------------------------------------수정
  function onUpdateAttend() {

    const cnt = selectedItems.length;
    if (!cnt) {
      alert('대상을 선택하세요!');
      return;
    }
    const rtn = window.confirm(`${cnt}개의 출결을 수정하시겠습니까?`);
    if (!rtn) return;

    const filteredList = list.filter(item => selectedItems.includes(item.sno));

    let completed = 0; 
    const total = cnt;

    filteredList.forEach(item => {
        const { sno, attend, reason, achievement, diligence, progress } = item;
        fncUpdate_attend(sno, attend, reason, achievement, diligence, progress, ()=> {
            completed ++;
            if (completed == total) {
              setIsRefresh(!isRefresh);
              setSelectedItems([]); // 삭제 후에는 선택된 항목과 전체 선택 상태를 초기화합니다.
              setSelectAll(false);
              // alert('완료되었습니다.');
            }
        });
    });
  }

  
  function fncUpdate_attend (sno, attend, reason, achievement, diligence, progress, callback) {
    fnc.sendAxios('/update/manageMentSt/attend', 
      { sno, attend, reason, achievement, diligence, progress }, (res) => {
      if (res) {  
           callback();
      } else {
            console.log(res,"managementStRoute/update/manageMentSt/attend");
      }
    });
  }


  function onDeleteAttend() {
    const cnt = selectedItems.length;
    if (!cnt) {
      alert('대상을 선택하세요!');
      return;
    }
    const rtn = window.confirm(`${cnt}개의 출결을 삭제하시겠습니까?`);
    if (!rtn) return;

    let completed = 0; // 완료된 삭제 작업을 추적
    const total = cnt;
 
    selectedItems.forEach(sno => {//selectedItems는 배열 [1, 22, 3]
        fncDelete_attend(sno, () => {
          completed++;
          if (completed === total) {
              setIsRefresh(!isRefresh);
              setSelectedItems([]); // 삭제 후에는 선택된 항목과 전체 선택 상태를 초기화합니다.
              setSelectAll(false);
          }

          
        });
    });
  }

  
  function fncDelete_attend (sno, callback) {
    fnc.sendAxios('/delete/manageMentSt/attend', 
      { sno }, (res) => {
      if (res) {  
        callback();
      } else {
            console.log(res,"select/manageMentSt/class_stList");
      }
    });
  }

  //---------------------------checkbox
  function handleCheckboxChange(item) {
    const isSelected = selectedItems.includes(item.sno);
    const newSelectedItems = isSelected ? selectedItems.filter(sno => sno !== item.sno) : [...selectedItems, item.sno];
    setSelectedItems(newSelectedItems);
    // 모두 체크되었는지 확인하여 헤더 체크박스 상태 업데이트
    setSelectAll(list.length > 0 && newSelectedItems.length === list.length);
  }

  function handleSelectAllChange() {
    if (selectAll) {
        setSelectedItems([]);
    } else {
        setSelectedItems(list.map(item => item.sno));
    }
    setSelectAll(!selectAll);
  }//checkbox END


const handleOptionChange = (event, index) => {//이 방식은 좋지 않아 (공부삼아 놔둠)
  setOptionList({ ...optionList, [index]: event.target.value,  });
  //--------------------------------------list에 결석사유를 동기화해야 update시 결석사유 저장된다.
  const newList = [...list];
  newList[index].reason =  event.target.value;
  setList(newList);
};



const handleInputChange = (event, index, field) => {
  // 3글자 이상 입력하지 못하게 설정
if (event.target.value <= 100) {
  const newList = [...list];
  newList[index] = {...newList[index], [field]: event.target.value};
  setList(newList);
}
};

  //===================================================
  return (
   <div className="ManagementSt_attend_result">
        <div className="wrap-main">
          <section className="section-main">
            <table className="table-hong table-main">
              <thead>
                <tr>
                  <th>{list.length}</th>
                  <th>반명</th>
                  <th>성명</th>
                  <th>출결</th>
                  <th>결석사유</th>
                  {isCoaching && <th>성취도</th>}
                  {isCoaching && <th>성실도</th>}
                  {isCoaching && <th>진행도</th>}
     
                  <th style={{width:'4rem'}}>
                      <Checkbox checked={selectAll} onChange={handleSelectAllChange} />
                  </th>
                </tr>
              </thead>

              <tbody>
                  {list && list.map((item, index) => (
  
                      <tr key={index} >  
                        <td>{index+1}</td>
                        <td>{item.className}</td>
                        <td>{item.stName}</td>
                        <td className={
                          item.attend == '결석' ? 'attendRed':
                          item.attend == '인결' ? 'attendPink': 
                          item.attend == '조퇴' ? 'attendGreen': 
                          item.attend == '지각' ? 'attendBlue':''
                        } onClick={() => onClickAttend(item, index)}>{item.attend}
                        </td>


                        <td>
                            <ComboboxMui
                              label=''
                              value={optionList[index] || ''}
                              onChange={(event) => handleOptionChange(event, index)}
                              options={optionData}
                            />
                        </td>
                       
                                             
                        {/* 성취도 입력란 */}
                        {isCoaching && <td>
                          <input
                            className='achievement'
                            type="number"
                            value={list[index]?.achievement || ''}
                            onChange={(e) => handleInputChange(e, index, 'achievement')}
                          />
                        </td>}

                        {/* 성실도 입력란 */}
                        {isCoaching && <td>
                          <input
                            className='achievement'
                            type="number"                   
                            value={list[index]?.diligence || ''}
                            onChange={(e) => handleInputChange(e, index, 'diligence')}
                          />
                        </td>}

                        {/* 진행도 입력란 */}
                        {isCoaching && <td>
                          <input
                            className='achievement'
                            type="number"
                            value={list[index]?.progress || ''}
                            onChange={(e) => handleInputChange(e, index, 'progress')}
                          />
                        </td>}
 

                        <td>
                            <Checkbox
                                checked={selectedItems.includes(item.sno)}
                                onChange={() => handleCheckboxChange(item)}
                            />
                        </td>
 
                      </tr>
                  ))}
            
                </tbody>
            </table>
          </section>

          <section className='section-menu'>
                  <label>수업일</label>
                 

                  <article className='article-dtp'>
                      <Dtpicker2 selectedDate={startDate} onDateChange={(date) => setStartDate(date)} />
                  </article>

                  <Button className='btn-menu btnSave' onClick={onUpdateAttend}>
                  <i class="fa-regular fa-pen-to-square"></i>&nbsp;수정
                  </Button>

                  <Button className='btn-menu btnSave' onClick={onDeleteAttend}>
                    <i class="fa-regular fa-trash-can"></i>&thinsp;삭제
                  </Button>


          </section>

        </div>


      </div>
  );

  //===========================================================================
  
function onClickAttend(item, index) {//출결 변경


  const newList = [...list];
  
  switch(newList[index].attend) {
    case '':
      newList[index].attend = '출석';
      break;
    case '출석':
      newList[index].attend = '결석';
      break;
    case '결석':
        newList[index].attend = '지각';
        break;
    case '지각':
      newList[index].attend = '인결';
      break;

    case '인결':
        newList[index].attend = '조퇴';
      break;

    case '조퇴':
        newList[index].attend = '휴강';
      break;

    case '휴강':
        newList[index].attend = '출석';
      break;
   
    default:
      console.log(`Unknown status: ${newList[index].checkedynSt}`);
  }
  setList(newList);
}


}

export default ManagementSt_attend_result;
