import './ManagementSt_coachingTec.scss';
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import fnc from '../mymod/commFunction';
import { useDispatch, useSelector } from 'react-redux';
import ComboboxMui from '../comm/ComboboxMui';
import { Button } from '@mui/material';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Checkbox from '@mui/material/Checkbox';
import { ko } from 'date-fns/locale';
import { format } from 'date-fns';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import {setSelectedDate} from  '../reduxToolkit/reduxSt';
import Dtpicker2 from '../comm/Dtpicker2';

const ManagementSt_coachingTec = () => {
    const navigate = useNavigate(); 
    const [list, setList] = useState([]);
    const [optionList, setOptionList] = useState({});
    // const [startDate, setStartDate] = useState(new Date());
    const selectedDateString = useSelector(state => state.reduxStore_st.selectedDate);
 
    

    const selectedClassCode = useSelector(state => state.reduxStore_st.selectedClassCode);
    const classCode = selectedClassCode ? selectedClassCode.classCode : '';//selectedClassCode 이거 객체로 사용
    const className = selectedClassCode ? selectedClassCode.className : '';
    const gangsaCode = selectedClassCode ? selectedClassCode.gangsaCode : '';
    const gangsaName = selectedClassCode ? selectedClassCode.gangsaName : '';

    const [startDate, setStartDate] = useState(new Date(selectedDateString)); // 초기화 시에만 사용
    const [selectedTime, setSelectedTime] = useState(null);
    const [duration, setDuration] = useState('');
    const stCode = fnc.getCookie('stCode');
    const dispatch = useDispatch();


    const [isSelectAll, setIsSelectAll] = useState(false);//체크박스

    useEffect(() => {
      fncSelect_stList(classCode);
      dispatch(setSelectedDate(startDate.toISOString())); // 상태 업데이트
    }, [classCode, startDate]);

  //-----------------------------------------------------------selectList   
  function fncSelect_stList(classCode) {
    const reservationDate = fnc.formatDate(startDate);

    fnc.sendAxios('/select/managementSt_reservation/reservationListByClassTec', { classCode, reservationDate }, (res) => {
      if (res) {  
        console.log(res);
            setList(getRecords_stList(res));
      } else {
            console.log(res,"ManagementSt_reservationTec/select/manageMentSt/reservationListByClassTec");
      }
    });
  }


function getRecords_stList(res) {
    let records = [];
    for (let i = 0; i < res.length; i++) { 
        const sno = res[i]["stCode"];  
        const reservationSno = res[i]["reservationSno"];  //삭제용 0인 경우는 null 원래 1부터 시작함
        const classCode = res[i]["classCode"];  
        const className = res[i]["className"];  
        const gangsaCode = res[i]["gangsaCode"]; 
        const gangsaName = res[i]["gangsaName"]; 
        const stCode = res[i]["stCode"];  
        const stName = res[i]["stName"];         
        const reservationDate= res[i]["lessonDate"];    
        const reservationHour= res[i]["reservationHour"]; 
        const reservationTime= res[i]["reservationTime"];    
        const confirm= res[i]["confirm"];   
        const changeReason= res[i]["changeReason"];  
        const weekDay = reservationDate ? `(${fnc.getWeekDay(reservationDate)})` : '';


        const isChecked = '';  

        records.push({ sno, reservationSno, classCode, className, gangsaCode, gangsaName, stCode, stName, 
          reservationDate, weekDay, reservationHour, reservationTime, confirm, changeReason, isChecked });
    }
    return records;
  }

  function onAddReservation() {
    if (!selectedTime) {
      alert('수업 시각을 선택하세요!');
      return;
    }
  
    if (!duration) {
      alert('시간을 선택하세요!');
      return;
    }
  
    const reservationDate = fnc.formatDate(startDate);
    const reservationHour = fnc.formatToAmPmTime(selectedTime);
    const reservationTime = duration;
  
    const checkedItems = list.filter(item => item.isChecked); // 체크된 항목 필터링

    if (checkedItems.length === 0) {
      alert ('대상을 체크하세요!');
      return; // 체크된 항목이 없으면 종료
    }

    const rtn = window.confirm(`${reservationDate}(${reservationHour})에 \n${checkedItems.length }명의 예약을 추가하시겠습니까?`);
    if (!rtn) return;
    
    let completed = 0; // 완료된 작업을 추적
    const total = checkedItems.length;
  
    checkedItems.forEach(item => {
      const { stCode, classCode, className, gangsaCode, gangsaName } = item;
  
      fncSave_reservationTec(stCode, classCode, className, gangsaCode, gangsaName, reservationDate,
        reservationHour, reservationTime, () => {
          completed++;
          if (completed === total) {
            fncSelect_stList(classCode); // 모든 예약이 완료된 후에 한 번만 호출
          }
        });
    });
  }
  
  function fncSave_reservationTec(stCode, classCode, className, gangsaCode, gangsaName, reservationDate,
    reservationHour, reservationTime, callback) {
  
    fnc.sendAxios('/save/managementSt_reservation/reservationTec',
      { stCode, classCode, className, gangsaCode, gangsaName, reservationDate,
        reservationHour, reservationTime
      }, (res) => {
        if (res) {
            callback(); // 예약 성공 시 콜백 호출
        } else {
          console.log(res, "select/manageMentSt/class_stList");
        }
      });
  }

  

  function onDeleteReservation() {
  
    const checkedItems = list.filter(item => item.isChecked); // 체크된 항목 필터링
    
    if (checkedItems.length === 0) {
      alert ('대상을 체크하세요!');
      return; // 체크된 항목이 없으면 종료
    }

    const rtn = window.confirm(`${checkedItems.length}명의 예약을 삭제하시겠습니까?`);
    if (!rtn) return;
  
    let completed = 0; // 완료된 삭제 작업을 추적
    const total = checkedItems.length;
  
    checkedItems.forEach(item => {
      const sno = item.reservationSno;
      fncDelete_reservation(sno, () => {
         completed++;
        if (completed === total) {
          fncSelect_stList(classCode); // 모든 삭제가 완료된 후에 한 번 호출
        }
      });
    });
  }
  
  function fncDelete_reservation(sno, callback) {
    fnc.sendAxios('/delete/managementSt_reservation/reservationTec', { sno }, (res) => {
      if (res) {  
        callback(); // 삭제가 성공하면 콜백 호출
      } else {
        console.log(res, "select/manageMentSt/class_stList");
      }
    });
  }
  

  //--------------------------------------------------승인
  function onClickConfirmed(item, index) {//승인 변경

    const stName = item.stName;
    if (item.confirm == 'Y') {
      const rtn = window.confirm(`승인 완료된 학생입니다. \n${stName}을 수정하시겠습니까?`);
      if (!rtn) return;
    }

    const newList = [...list];
    newList[index].confirmed = newList[index].confirm || ''; // 기본 값 설정
    switch (newList[index].confirmed) {
      case '':
        newList[index].confirm = 'Y';
        fncUpdate_confirmed(item.sno, 'Y')
        break;
      case 'Y':
        newList[index].confirm = '';
        fncUpdate_confirmed(item.sno, '')
        break;
      default:
        console.log(`Unknown status: ${newList[index].confirm}`);
    }
    
    setList(newList);
  }

  
  function fncUpdate_confirmed(sno, value) {//출결 확정

    fnc.sendAxios('/update/managementSt_reservation/confirm', { sno, value }, (res) => {
      if (res) { 
        console.log(res, 'res');
      } else {
        console.log(res,"update/managementSt_reservation/confirm");
      }
    });
  }

  //-----------------------------datepicker
    
  function onMoveDay(currDate, days) {

    setStartDate(fnc.moveDay(currDate, days));
  }

  
  //--------------------------------------------------------
  const handleTimeChange = (time) => {
    if (time instanceof Date && !isNaN(time)) {
      setSelectedTime(time); // Date 객체를 유지
    } else {
      setSelectedTime(null);
    }
  };
  
  const handleChange = (event) => {
    setDuration(event.target.value);
  };

  function onRowClick(item) {

    if (!item.reservationHour) {
      console.warn('reservationHour이 정의되지 않았습니다.');
      return;
    }
  
    // "오전 5:30"을 "오전"과 "5:30"으로 분리
    const [timePeriod, time] = item.reservationHour.split(' '); 
    const [hours, minutes] = time.split(':').map(Number); // "5"와 "30"을 분리하고 숫자로 변환
  
    let hours24 = hours;
  
    // 오전/오후에 따른 24시간 형식 변환
    if (timePeriod === "오후" && hours !== 12) {
      hours24 += 12; // 오후이고 12시가 아닌 경우 12를 더해 24시간 형식으로 변환
    } else if (timePeriod === "오전" && hours === 12) {
      hours24 = 0; // 오전 12시는 0시로 변환
    }
  
    // 예약 날짜와 시간을 결합하여 Date 객체 생성
    const [year, month, day] = item.reservationDate.split('-').map(Number);
    const reservationDate = new Date(year, month - 1, day, hours24, minutes);
  
    setSelectedTime(reservationDate); // DatePicker에 설정할 Date 객체
    setDuration(item.reservationTime);
  }
  
  
  //===================================================
  return (
   <div className="ManagementSt_coachingTec">
        <div className="wrap-main">
          <section className="section-main">
            <table className="table-hong table-main">
              <thead>
                <tr>
                  <th>{list.length}</th>
                  {/* <th>반명</th> */}
                 
                  <th>성명</th>
                   <th>예약일</th>
                  <th>시각</th>
                  <th>시간(분)</th>
                  {/* <th>승인</th> */}

                  <th style={{width:'4rem'}}>
                      <Checkbox checked={isSelectAll} onChange={handleSelectAllChange} />
                  </th>

                </tr>
              </thead>

              <tbody>
                  {list && list.map((item, index) => (
  
                      <tr key={index} onClick={() => onRowClick(item)}>  
                        <td>{index+1}</td>
                        <td>{item.stName}</td>
                        <td>{item.reservationDate} {item.weekDay}</td>          
                        <td>{item.reservationHour}</td>
                        <td>{item.reservationTime}</td>

                        <td>
                            <Checkbox
                                checked={item.isChecked}
                                onChange={(event) => handleCheckboxChange(event, index)}
                            />
                        </td>

                      </tr>
                  ))}
            
                </tbody>
            </table>
          </section>

              <section className='section-menu'>
                  <span className='reservationDay'>예약일</span>

                  <article className='article-dtp'>
                  <Dtpicker2 selectedDate={startDate} onDateChange={(date) => setStartDate(date)} />
                  </article>
                  
               
                  <article className="article-timePicker">
                    <DatePicker
                        selected={selectedTime}
                        onChange={handleTimeChange}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15} // 시간을 15분 간격으로 설정
                        timeCaption="Time"
                        dateFormat="aa h:mm" // 오전/오후 한글 형식
                        locale={ko} // 로케일을 한국어로 설정
                        className="timePicker"
                        placeholderText="시각 선택"
                    />
                  </article>

               

                  <article className="article-time">
                    <Select
                        labelId="duration-select-label"
                        id="duration-select"
                        value={duration}
                        onChange={handleChange}
                        label="Duration"
                        className="duration"
                        >
                        <MenuItem value={60}>60분</MenuItem>
                        <MenuItem value={120}>120분</MenuItem>
                    </Select>
                </article>

                <article className='article-menu'>

                    <Button className='btn-menu btnSave' onClick={() => onAddReservation()}>
                      <i class="fa-regular fa-floppy-disk"></i>저장
                    </Button>

                    <Button className='btn-menu btnDelet' onClick={() => onDeleteReservation()}>
                    <i class="fa-regular fa-trash-can"></i>삭제
                    </Button>

                </article>

          </section>


        </div>


      </div>
  );


  //---------------------------checkbox
  function handleCheckboxChange(event, index) {
    const isChecked = event.target.checked;
    const newList = [...list];
    newList[index].isChecked = isChecked;
    setList(newList);
  }

  function handleSelectAllChange() {
    const newList = list.map(item => ({...item, isChecked: !isSelectAll }));
    setList(newList);
    setIsSelectAll(!isSelectAll);
  }//checkbox END



}

export default ManagementSt_coachingTec;
