import CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';
import axios from "axios";
import { format } from 'date-fns';
import { ko } from 'date-fns/locale';
const svrUrl = process.env.REACT_APP_SERVER_URL;//svrUrl = 'http://localhost:8000';

function setCookie(key, value, day) {
  // let secretKey = process.env.REACT_APP_secretKey; // 이 키는 절대 노출되어서는 안됩니다!
  // const encryptedVaule = CryptoJS.AES.encrypt(value,secretKey).toString();
  // Cookies.set(key, encryptedVaule, {expires: day});

  const secretKey = process.env.REACT_APP_secretKey; // 환경변수에서 암호화 키를 가져옵니다.
  const encryptedValue = CryptoJS.AES.encrypt(value, secretKey).toString(); // 값을 암호화합니다.
  localStorage.setItem(key, encryptedValue); // 로컬 스토리지에 암호화된 값을 저장합니다.

}

function getCookie(key) {
    // let secretKey = process.env.REACT_APP_secretKey; 
    // const encryptedValue = Cookies.get(key);
    // if (!encryptedValue)  return '';
    // const bytesValue = CryptoJS.AES.decrypt(encryptedValue, secretKey);
    // const value = bytesValue.toString(CryptoJS.enc.Utf8);
    // return value;
    let secretKey = process.env.REACT_APP_secretKey; // 환경변수에서 암호화 키를 가져옵니다.
  const encryptedValue = localStorage.getItem(key); // 로컬 스토리지에서 암호화된 값을 가져옵니다.
  if (!encryptedValue) return ''; // 값이 없다면 빈 문자열을 반환합니다.

  try {
    const bytes = CryptoJS.AES.decrypt(encryptedValue, secretKey); // 암호화된 값을 복호화합니다.
    const decryptedValue = bytes.toString(CryptoJS.enc.Utf8); // 복호화된 값을 UTF-8 문자열로 변환합니다.
    return decryptedValue;
  } catch (e) {
    console.error('Decrypt error:', e); // 복호화 과정에서 오류가 발생한 경우 콘솔에 에러를 출력합니다.
    return ''; // 오류 발생 시 빈 문자열을 반환합니다.
  }
}

function getCookie_pure(key) {
  // const value = Cookies.get(key);
  // return value;
  const value = localStorage.getItem(key);
  return value;
}

function setCookie_pure(key, value) {
  localStorage.setItem(key, value);
}

function fncDecrypt(encryptedValue) {
  let secretKey = process.env.REACT_APP_secretKey; 
  
  if (!encryptedValue)  return '';
  const bytesValue = CryptoJS.AES.decrypt(encryptedValue, secretKey);
  const value = bytesValue.toString(CryptoJS.enc.Utf8);
  return value;
}

function fncRound(value, total, digit) {
  let percentage = (value / total) * 100;
  return Math.round(percentage * Math.pow(10, digit)) / Math.pow(10, digit);
}

function updateListItem(list, rowIndex, fieldName, newValue) {
  // 불변성을 유지하기 위해 배열을 복사합니다.
  const newList = [...list];
  // 주어진 인덱스가 배열 범위 내에 있는지 확인하고, 해당 요소를 업데이트합니다.
  if (rowIndex >= 0 && rowIndex < newList.length) {
      newList[rowIndex] = { ...newList[rowIndex], [fieldName]: newValue };
  }

  return newList;
}


const sendAxios = async (url, data, callback) => {
  try {
    const response = await axios.post(`${svrUrl}${url}`, data);
    let jsonObj = response.data;
    callback(jsonObj);
  } catch (error) {
    console.error("Axios 요청 중 오류가 발생했습니다:", error);
  }
};


function fncDate() {
  const currentDate = new Date();
  // 날짜를 YYYY-MM-DD 형식으로 포매팅
  const formattedDate = currentDate.toISOString().substring(0, 10);
  return formattedDate;
}


function fncSirialNum2(startValue = 'B') {//밀리초까지
 
  const uniqueTimestamp = Date.now();
  return startValue + uniqueTimestamp;
  //console.log(uniqueTimestamp); // 예: 1630567890123
  //uuid를 사용하는 방법도 있다. 
  //길이가 4개 짧아지지만 직관적이지 않다.
}

function fncSirialNum(startValue = 'B') {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 1을 더해줍니다.
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');
  const milliseconds = String(now.getMilliseconds()).padStart(3, '0');

  const uniqueTimestamp = `${year}${month}${day}${hours}${minutes}${seconds}${milliseconds}`;
  return startValue + uniqueTimestamp;
}




const sendAxiosToken = async (url, data, callback) => {
  const token = fnc.getCookie('token');
  try {
    // axios 요청에 Authorization 헤더 추가
    const response = await axios.post(`${svrUrl}${url}`, data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });

    let jsonObj = response.data;
    callback(jsonObj);

  } catch (error) {
    alert(`토큰을 확인하세요. \n(${error.message})`)
    console.error(`토큰을 확인하세요. \n(${error.message})`);
  }
};

function obscureName(name) {//홍***
  if (!name) return ''; // 이름이 없는 경우 빈 문자열 반환
  const firstChar = name[0]; // 첫 글자 추출
  const obscured = firstChar + '*'.repeat(name.length - 1); // 첫 글자를 제외하고 나머지 글자 수만큼 '*' 추가
  return obscured;
}

//날짜관련 ==============================================================
function formatDate(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

function getYear(date) {
  const year = date.getFullYear();
  return year;
}
function getMonth(date) {
  const month = String(date.getMonth() + 1).padStart(2, '0');
  return month;
}


function formatPhoneNumber(phone) {
  if (!phone) return '';
  return phone.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
}


const getWeekDay = (date) => {
  const days = ['일', '월', '화', '수', '목', '금', '토'];
  if (!(date instanceof Date)) {
    date = new Date(date);
  }
  const dayIndex = date.getDay();
  return days[dayIndex];
};


const moveDay = (currDate, days) => {
  const newDate = new Date(currDate);
  newDate.setDate(currDate.getDate() + days);
  return newDate;
};


function formatToAmPmTime(date) {
  if (!(date instanceof Date) || isNaN(date)) {
    throw new Error("Invalid date object provided");
  }
  return format(date, "aa h:mm", { locale: ko });
}

function formatToTime(date) {
  if (!(date instanceof Date) || isNaN(date)) {
    throw new Error("Invalid date object provided");
  }
  return format(date, "HH:mm", { locale: ko });
}


const getStartAndEndDateOfWeekObj = (date) => {
  // 주어진 날짜 기준으로 요일 계산 (0: 일요일, 1: 월요일, ..., 6: 토요일)
  const dayOfWeek = date.getDay();
  // 주의 시작일 (월요일)
  const startDate = new Date(date);
  startDate.setDate(date.getDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1));
  // 주의 종료일 (일요일)
  const endDate = new Date(startDate);
  endDate.setDate(startDate.getDate() + 6);
  return { startDate, endDate };
};

const fnc = { setCookie, getCookie, fncDecrypt,  fncRound, updateListItem, 
  fncDate, formatDate,
  fncSirialNum,
  sendAxios,
  sendAxiosToken,
  obscureName,formatPhoneNumber, getWeekDay, moveDay, formatToAmPmTime,formatToTime,
  getYear, getMonth, getStartAndEndDateOfWeekObj,
 }
export default fnc;
// module.exports = fnc;
// setCookie_pure, getCookie_pure,

