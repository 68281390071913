import "./markingStudyTable2.scss"; 
import { Button } from "@mui/material";
import React, { useEffect, useState, memo} from "react";
import { useSelector, useDispatch } from 'react-redux';
import { setIsShowList, setSelectedRow, setSelectedRowOld, fncResetSlide, initializeQue   } from "../reduxToolkit/redux";

const MarkingStudyTable2 = (({ 
  list2, 
  isLoadImgComplete, 
 }) => {

const dispatch = useDispatch();
const isShowList = useSelector(state => state.reduxStore.isShowList);
const selectedRow = useSelector(state => state.reduxStore.selectedRow);

function onMarkRowClick(Row) {
  if (!isLoadImgComplete) return;
    dispatch(fncResetSlide());
    dispatch(setSelectedRowOld(selectedRow));
    dispatch(setSelectedRow(Row));
    dispatch(setIsShowList(false));
  
    
}


//========================================================================= return
return (
  <div>
    {isShowList && (
      <div className="MarkingStudyTable2">
        <div className="wrap-list-markingSt">
          <table>
            <thead>
              <tr>
                {/* <th style={{ width: "2rem" }}></th> */}
                <th style={{ width: "3.5rem" }}>No</th>
                <th style={{ width: "5rem" }}>OX</th>
                <th style={{ width: "5rem" }}>정답률 (%)</th>
                {/* <th style={{ width: "5rem" }}>중요</th> */}
                {/* <th style={{ width: "5rem" }}>이유</th> */}
                {/* <th style={{ width: "5rem" }}>경과일</th> */}
              </tr>
            </thead>
            <tbody>
              {list2 && list2.map((item, index) => (
                  <tr
                  className={`row-list ${selectedRow === index+1 ? "selected activeRow" : ""}`}
                  key={item.no}
                  // onClick={() => {onMarkRowClick(item.no)}} 전체 틀림 맞음에서 no 말고 index로 해야 함
                  // 다른 곳도 index+1로 바꿔야 하나?
                  onClick={() => {onMarkRowClick(index+1)}} > 
                  <td>{index+1}</td>
                  {/* <td>{item.no}</td> */}
                  <td style={{textAlign: 'center', paddingLeft: '1rem'}}>{item.OX || ""}</td>
                  <td style={{textAlign: 'center', paddingLeft: '1rem'}}>{item.정답율 || ""}</td>
                  {/* <td style={{textAlign: 'center', paddingLeft: '1rem'}}>{item.중요 || ""}</td> */}
                  {/* <td style={{textAlign: 'center', paddingLeft: '1rem'}}>{item.기록 || ""}</td> */}
                  {/* <td style={{textAlign: 'center', paddingLeft: '1rem'}}>{item.경과일 || ""}</td> */}
                </tr>
                ))}
            </tbody>
          </table>
        </div>

        <div className="box_footer">
            <Button className="close_table" onClick={()=> dispatch(setIsShowList(false))}>닫기</Button>
        </div>


      </div>
    )}
  </div>
);
});

export default MarkingStudyTable2;