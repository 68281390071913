import './ManagementSt_coaching_homework.scss';
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import fnc from '../mymod/commFunction';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import "react-datepicker/dist/react-datepicker.css";
import {setSelectedDate} from  '../reduxToolkit/reduxSt';
import Dtpicker2 from '../comm/Dtpicker2';
import { Callbacks } from 'jquery';

const ManagementSt_coaching_homework = () => {
    const navigate = useNavigate(); 
    const [list, setList] = useState([]);
    const [optionList, setOptionList] = useState({});
    // const [startDate, setStartDate] = useState(new Date());
    const selectedDateString = useSelector(state => state.reduxStore_st.selectedDate);
    const [startDate, setStartDate] = useState(new Date(selectedDateString)); // 초기화 시에만 사용
    const [isRefresh, setIsRefresh] = useState(false);
 
    // const [progressList, setProgressList] = useState([]);
    // const [homeworkList, setHomeworkList] = useState([]);

    const selectedClassCode = useSelector(state => state.reduxStore_st.selectedClassCode);

    const classCode = selectedClassCode ? selectedClassCode.classCode : '';//selectedClassCode 이거 객체로 사용
    const className = selectedClassCode ? selectedClassCode.className : '';
    const gangsaCode = selectedClassCode ? selectedClassCode.gangsaCode : '';
    const gangsaName = selectedClassCode ? selectedClassCode.gangsaName : '';

    const dispatch = useDispatch();
    

    useEffect(() => {
      const lessonDate = fnc.formatDate(startDate);
      fncSelect_stList(classCode, lessonDate)
      dispatch(setSelectedDate(startDate.toISOString())); 
    }, [classCode, startDate, isRefresh]);

   
  
    const handleOptionChange = (event, index) => {
      setOptionList({ ...optionList, [index]: event.target.value,  });
    };

       
  
  function fncSelect_stList(classCode, lessonDate) {
    fnc.sendAxios('/select/manageMentSt/reservation/class_stList_homework', { classCode, lessonDate }, (res) => {
      if (res) {  

        const records = getRecords_stList(res);
            setList(records);
            // setOptionList({});  // optionList를 빈 객체로 초기화
            // setProgressList(new Array(records.length).fill(''));  // 학생 수에 맞게 배열 초기화
            // setHomeworkList(new Array(records.length).fill(''));  // 학생 수에 맞게 배열 초기화
      } else {
            console.log(res,"managementStRoutes/select/manageMentSt/reservation/class_stList_homework");
      }
    });
  }


function getRecords_stList(res) {
    let records = [];
    for (let i = 0; i < res.length; i++) { 
        const classCode = res[i]["classCode"];  
        const className = res[i]["className"];  
        const gangsaCode = res[i]["gangsaCode"]; 
        const gangsaName = res[i]["gangsaName"]; 
        const stCode = res[i]["stCode"];  
        const stName = res[i]["stName"];     
        const school = res[i]["school"];  
        const hp= res[i]["hp"];      
        const parentHp= res[i]["parentHp"];  
        const jindo= res[i]["jindo"];    
        const homework= res[i]["homework"];            
        records.push({ classCode, className, gangsaCode, gangsaName, stCode, stName, school, hp, parentHp, jindo, homework });
    }
    return records;
  }


//------------------------------------------------------------------저장
  function onSaveHomework() {
    const cnt = list.filter(item => item.stCode.trim() !=='').length;
    // const rtn = window.confirm(`${cnt}명의 항목을 저장하시겠습니까?`);
    const rtn = window.confirm(`진도 및 과제를 저장하시겠습니까?`);
    if (!rtn) return;

    const total =  list.length;
    let completed = 0; // 완료된 삭제 작업을 추적

    for ( let k = 0; k < list.length; k++) {

      const acaCode = fnc.getCookie('acaCode');
      const stCode = list[k].stCode;
      const lessonDate = fnc.formatDate(startDate);
      const classCode = list[k].classCode;
      const className = list[k].className;
      const gangsaCode = list[k].gangsaCode;
      const gangsaName = list[k].gangsaName;
      // const jindo = progressList[k] || '';
      // const homework = homeworkList[k] || '';
      const jindo = list[k].jindo || '';
      const homework = list[k].homework || '';

      fncSave_jindo_homework (acaCode, stCode, lessonDate, classCode, className, gangsaCode, gangsaName, jindo, homework, 
        () => {
          completed++;
          if (completed === total) {
            setIsRefresh(!isRefresh);
            alert('완료되었습니다.');
          }
        }
      );

    }

  }

  function fncSave_jindo_homework (acaCode, stCode, lessonDate, classCode, className,  gangsaCode, gangsaName,  jindo, homework, callback) {
    fnc.sendAxios('/save/manageMentSt/jindo_homework', 
      { acaCode, stCode, lessonDate,  classCode, className,  gangsaCode, gangsaName, jindo, homework }, (res) => {
      if (res) {  
            callback();
      } else {
            console.log(res,"managementStRoutes/save/manageMentSt/jindo_homework");
      }
    });
  }

//-------------------------------------------
  const handleProgressChange = (index, value) => {
    // const newProgressList = [...progressList];
    // newProgressList[index] = value;
    // setProgressList(newProgressList);

    const newList = [...list];
    newList[index].jindo = value;
    setList(newList);
  };

  const handleHomeworkChange = (index, value) => {
    // const newHomeworkList = [...homeworkList];
    // newHomeworkList[index] = value;
    // setHomeworkList(newHomeworkList);

    const newList = [...list];//얕은 복사
    newList[index].homework = value;
    setList(newList);//랜더링 해야해서 newList에 해야함

  };

  const handleClearProgress = (index) => {
    handleProgressChange(index, ''); 
  };

  const handleClearHomework = (index) => {
    handleHomeworkChange(index, ''); 
  };


  const handleAddAllProgress = () => {
    // setProgressList(pre => {//배열의 경우
    //     const firstValue = pre[0];
    //     if(!firstValue) {
    //       alert('첫 번째 학생의 진도를 먼저 입력하세요!');
    //       return pre;
    //     }
    //     return pre.map(() => firstValue);
    // });

     setList(pre => {//객체의 경우
      const firstValue = pre[0];
      if (!firstValue || !firstValue.jindo) {
          alert('첫 번째 학생의 진도를 먼저 입력하세요!');
          return pre;
      }
      return pre.map(item => ({
          ...item,
          jindo: firstValue.jindo // 첫 번째 학생의 jindo 값을 복사
      }));
  });

  };

  const handleAddAllHomework = () => {
    // setHomeworkList(pre => {//배열의 경우
    //     const firstValue = pre[0];
    //     if(!firstValue) {
    //       alert('첫 번째 학생의 과제를 먼저 입력하세요!');
    //       return pre;
    //     }
    //     return pre.map(() => firstValue);
    // });

    setList(pre => {//객체의 경우
      const firstValue = pre[0];
      if (!firstValue || !firstValue.homework) {
          alert('첫 번째 학생의 과제를 먼저 입력하세요!');
          return pre;
      }
      return pre.map(item => ({
          ...item,
          homework: firstValue.homework // 첫 번째 학생의 jindo 값을 복사
      }));
  });

  };

  function handleAddAllClear() {
    setList(pre => {
      return pre.map(item => ({
          ...item, jindo: '', homework: '' 
      }));
  });
  }


  function onMoveDay(currDate, days) {

    setStartDate(fnc.moveDay(currDate, days));
  }


  //===================================================
  return (
   <div className="ManagementSt_coaching_homework">
        <div className="wrap-main">
          <section className="section-main">
            <table className="table-hong table-main">
   
                  {list && list.map((item, index) => (
                        <section className='section-list'>
                    
                        <article className='article-stName'>
                          <div>
                            <label className='stNo'>{index+1}</label>
                            <span className='stName'>{item.stName}</span> 
                            <span className='hp'>{item.hp}</span> 
                          </div>
                          
                          {index === 0 && <div>
                            <Button className='btnCls' onClick={() => handleAddAllProgress(index)}>진도상동</Button>
                            <Button className='btnCls' onClick={() => handleAddAllHomework(index)}>과제상동</Button>
                            <Button className='btnCls' onClick={() => handleAddAllClear(index)}>clear</Button>
                          </div>}
                        
                        
                        </article>

                        <article className='article-jindo'>
                          <textarea className='jindo'  type="text" value={item.jindo}  
                          rows="2"
                          placeholder="진도를 입력하세요" spellCheck="false"
                          onChange={(e) => handleProgressChange(index, e.target.value)} />
                          <Button className='btnCls' onClick={() => handleClearProgress(index)}> <i class="fa-regular fa-trash-can"></i></Button>      
                        </article>

                        <article className='article-homework'>
                          <textarea className='homework' type="text"  value={item.homework}   
                          rows="2"
                          placeholder="과제를 입력하세요" spellCheck="false"
                          onChange={(e) => handleHomeworkChange(index, e.target.value)} />
                           <Button className='btnCls' onClick={() => handleClearHomework(index)}> <i class="fa-regular fa-trash-can"></i></Button>
                        </article>
                
                        </section>
     
                  ))}
            

            </table>
          </section>

          <section className='section-menu'>
                  <label>수업일</label>

                
                  <article className='article-dtp'>
                    <Dtpicker2 selectedDate={startDate} onDateChange={(date) => setStartDate(date)} />
                  </article>

                
                  <Button className='btn-menu btnSave' onClick={onSaveHomework}>
                    <i class="fa-solid fa-floppy-disk"></i> &nbsp;저장
                  </Button>

          </section>

        </div>


      </div>
  );

//===========================================================================
  
function onClickAttend(item, index) {//출결 변경

  }




//--------전체출석

  function onAllAttend() {
    const newList = list.map(item => ({
      ...item, attend: '출석'
    }));
    setList(newList);
  }

  function onAllAbsence() {
    const newList = list.map(item => ({
      ...item, attend: '결석'
    }));
    setList(newList);
  }

  function onAllClear() {
    const newList = list.map(item => ({
      ...item, attend: ''
    }));
    setList(newList);
  }
}

export default ManagementSt_coaching_homework;
