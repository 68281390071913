import styles from "./markingStudyMenu.scss"; 
import axios from "axios";
import React, { useEffect, useState, useRef  } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Cookies from 'js-cookie';

function MarkingStudyMenu({ 
  selectedRow, setSelectedRow, 
  setInfo, 
  isShowList, setIsShowList, 
  list, 
  imgSize, setImgSize, 
  markingList, setMarkingList, 
  setSelectKind, 
  setIsRowClicked, 
  setImgViewState,
}) { 

  const location = useLocation();
  const testCode = location.state?.testCode;
  const munCnt= location.state?.munCnt;

  const navigate = useNavigate();
  const svrUrl = process.env.REACT_APP_SERVER_URL;

  console.log("4----------------------------------------------MarkingStudyMenu");


  useEffect(() => {
    const savedSize = Cookies.get('imgSize');
    if (savedSize) {
      setImgSize(Number(savedSize));
    }
  }, []);




  function fncShowList() {
    setIsShowList(!isShowList);
  }

  function fncCloseList() {
    setIsShowList(false);
  }

  const fncZoomOut = () => {
    if (imgSize < 200) {
      alert('최소크기 입니다!');
      return;
    }
    const newSize = imgSize * 0.9;
    setImgSize(newSize);
    Cookies.set('imgSize', newSize, { expires: 1000 });
  };

  
  const fncZoomIn = () => {
    let newSize = imgSize < 200 ? 200 : imgSize;
    newSize = Math.round(imgSize * 1.1 + 0.5);  // 반올림
    setImgSize(newSize);
    Cookies.set('imgSize', newSize, { expires: 1000 });
  };

  
//--------------------------------------수정완료 no가 이가 빠져있으면 문제가 됨 index로 해야
  function fncPrev() {
    if (list && selectedRow !== null) {
      const currentIndex = selectedRow - 1;  // 배열 인덱스는 0부터 시작하므로 -1을 합니다.
  
      if (currentIndex > 0) {
        const prevIndex = currentIndex - 1;
        fncSelectRow(prevIndex);
      } else {
        alert("처음입니다.");
      }
    }
  }

  function fncNext() {
    if (list && selectedRow) {
      const nextIndex = selectedRow ; // item.no가 인덱스 + 1 이므로
      if (nextIndex < list.length) {
        fncSelectRow(nextIndex);
      } else {
        alert('마지막입니다!');
      }
    }
  }
   
  
function fncSelectRow(index) {
  if (index == null) return false;//(!index) null, undefined, 0, "", NaN, false
    setSelectedRow(index + 1);
    setIsRowClicked(true);

}
 //-------------------------------------------------------------------- 
    
  function fncTestClose() {
    const confirmed = window.confirm('학습을 종료하시겠습니까?');
    if (confirmed) {
      navigate("/markinglist");
    }
  }

  return (
    <div className="MarkingStudyMenu">
       
      <div className="wrap-footer">
          <div className="box-footer">
              {/* <input type="button" className="btn" id="list" value="목록" onClick={fncShowList} style={{ backgroundColor: '#669A00' }} /> */}
              <button className="btn-img list" onClick={fncShowList} ></button>
              <button className="btn-img prev" onClick={fncPrev}></button>
              <button className="btn-img next" onClick={fncNext}></button>
              <button className="btn-img zoomout" onClick={fncZoomOut} ></button>
              <button className="btn-img zoomin" onClick={fncZoomIn} ></button>
              <button className="btn-img close2" onClick={fncTestClose}></button>
          </div>

          <div className="box-footer">
              <button className="btn" id="btn전체문제" 
              onClick={() => {
              setSelectKind('전체'); 
              setIsRowClicked(true);
              setSelectedRow(1);
              }}>전체</button>
              <button className="btn" id="btn틀린문제" onClick={() => {setSelectKind('틀림'); setIsRowClicked(true); setSelectedRow(1);}}>틀림</button>
              <button className="btn" id="btn맞은문제" onClick={() => {setSelectKind('맞음'); setIsRowClicked(true); setSelectedRow(1);}}>맞음</button>
              
              <button className="btn" id="btn문제보기" onClick={() => {setImgViewState('문제')}}>문제</button>
              <button className="btn" id="btn풀이보기" onClick={() => {setImgViewState('풀이')}}>풀이</button>
              <button className="btn" id="btn정답보기" onClick={() => {setImgViewState('정답')}}>정답</button>
          </div>


      </div>

    </div>
  );
}

export default MarkingStudyMenu;
