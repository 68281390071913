import './Listbox.scss';
import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';

const Listbox = ({items, onSelect}) => {
  const [selectedIndex, setSelectedIndex] = React.useState(null);
 
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    if (onSelect) {
      onSelect(items[index]);  // Pass the selected item to the parent
    }
  };

//   const items = [
//     { key: '1', name: 'Aardvark' },
//     { key: '2', name: 'Cat' },
//     { key: '3', name: 'Dog' },
//     { key: '4', name: 'Kangaroo' },
//     { key: '5', name: 'Panda' },
//     { key: '6', name: 'Snake' },
//   ];

  return (
    <div className='Listbox'>
      <section className='section-list'>

        <List component="nav" aria-label="favorite animal">
            {items.map((item, index) => (
            <ListItem
                key={item.key}
                disablePadding
            >
                <ListItemButton
                selected={selectedIndex === index}
                onClick={(event) => handleListItemClick(event, index)}
                >
                <ListItemText primary={item.name} />
                </ListItemButton>
            </ListItem>
            ))}
        </List>
      </section>
    </div>
  );
}

export default Listbox;
