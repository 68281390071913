import styles from "./markingTable2.scss"; 
import axios from "axios";
import { Button } from "@mui/material";
import React, { useEffect, useState, memo} from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedRow, setSelectedRowOld, fncResetSlide, setIsShowList, } from "../reduxToolkit/redux";


const MarkingTable2 = (({ list2, isLoadImgComplete, }) => {


const dispatch = useDispatch();
const markingList = useSelector(state => state.reduxStore.markingList);
const isShowList = useSelector(state => state.reduxStore.isShowList);
const selectedRow = useSelector(state => state.reduxStore.selectedRow);//스펠링 수정

function onMarkRowClick(Row) {

      if (!isLoadImgComplete) return;//이거 중요해 없으면 중간에 여러번 중복돼서 실행되는 듯 (2주 개고생)
      dispatch(fncResetSlide());
      dispatch(setSelectedRowOld(selectedRow));
      dispatch(setSelectedRow(Row));
      dispatch(setIsShowList(false));
}

//========================================================================= return
return (
  <div>
    {isShowList && (
      <div className="MarkingTable2">
        <div className="wrap-list-markingSt">
          <table>
            <thead>
              <tr>
                <th style={{ width: "5rem" }}>No</th>
                <th style={{ width: "auto" }}>마킹</th>
              </tr>
            </thead>
            <tbody>
              {list2 && list2.map((item, index) => (

                <tr
                  className={`row-list ${selectedRow === item.no ? "selected activeRow" : ""}`}
                  key={item.no}
                  onClick={() => {onMarkRowClick(item.no)}} >
                  
                  <td>{item.no}</td>
                  
                  <td style={{textAlign: 'left', paddingLeft: '1rem'}}>{markingList[item.no] || ""}</td>
                  {/* <td style={{textAlign: 'left', paddingLeft: '1rem'}}></td> */}

                </tr>
                ))}
            </tbody>
          </table>
        </div>

        <div className="box_footer">
            <Button className="close_table" onClick={()=> dispatch(setIsShowList(false))}>닫기</Button>
        </div>


      </div>
    )}
  </div>
);
});

export default MarkingTable2;