import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Plyr from "plyr-react";
// import "plyr-react/plyr.css";

const VideoPlay2 = () => {
  const videoUrl = process.env.REACT_APP_VIDEO_URL;
  const selectedVideoList = useSelector((state) => state.reduxStore_video.selectedVideoList);
  const [fullUrl, setFullUrl] = useState('');

  useEffect(() => {
    // Redux 스토어에서 선택된 비디오 파일명을 기반으로 전체 URL 설정
    const fileName = selectedVideoList.fileName;
    setFullUrl(`${videoUrl}/${fileName}`);
  }, [selectedVideoList]);

  const videoSrc = {
    type: "video",
    sources: [
      {
        src: fullUrl,
        type: 'video/mp4', // 'type' 필드를 사용하여 비디오 파일의 MIME 타입 지정
      },
    ],
  };

  return (
    <div className="VideoPlay2">
      <h1>Video Player</h1>
      <div className="box_header"></div>
      <div className="box_player">
        {/* Plyr 컴포넌트로 비디오 소스 전달 */}
        <Plyr source={videoSrc} />
      </div>
    </div>
  );
};

export default VideoPlay2;
