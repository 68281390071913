import './VideoGroup.scss';
import * as S from '../scss/theme';
import Header from '../Header';
import VideoGroupTopMenu from './VideoGroupTopMenu';
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedVideoGroupList } from "../reduxToolkit/reduxVideo";
import fnc from '../mymod/commFunction';
import imgLogo from '../images/gamja/logo-kr-traspent.png';
import defaultImage from '../images/gamja/no-picture.jpg';
import DisplayLogo from '../comm/DisplayLogo';

const VideoGroup = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [list, setList] = useState([]);
    const thumbUrl= process.env.REACT_APP_THUMBNAIL_URL;
    const kwamokCode = useSelector(state => state.reduxStore_video.selectedKwamokCode);

    const topMenuRef = useRef(null); // VideoGroupTopMenu의 높이를 측정하기 위한 ref
    const [topMenuHeight, setTopMenuHeight] = useState(0); // VideoGroupTopMenu의 높이 상태
    const [imgSrc, setImgSrc] = useState('');


    useEffect(()=>{
        fncSelectFileList(kwamokCode);
    }, [kwamokCode]);

    useEffect(() => {
      if (topMenuRef.current) {
            setTopMenuHeight(topMenuRef.current.clientHeight); // 높이 업데이트
          }
    }, [list]); 

    function fncSelectFileList(kwamokCode) {   
        fnc.sendAxios('/selectVideoGroup', { kwamokCode}, (res) => {
          if (res) {   
            setList(getRecords_list(res));   
          } else {
            console.log("속성이 obj 내에 없습니다.");
          }
        });
      }

      function getRecords_list(res) {
        let records = [];
        if (!Array.isArray(res)) {
          return records;
        }
       
        res.forEach(item => {
          const kwajung = item["kwajung"];  
          const kwajungCode = item["kwajungCode"];  
          const sDate = item["sDate"];
          const thumbnailFileName = item["thumbnailFileName"];

          const kwajungArray = kwajung.split('@');
          const kwajung1 = (kwajungArray[0] || '').trim();
          const kwajung2 = (kwajungArray[1] || '').trim(); 
                  
          records.push({ kwajung, kwajungCode, sDate, thumbnailFileName, kwajung1, kwajung2 });
    
        });
       
        return records;
      }
  
      function onSelectVideoList(item) {
        dispatch(setSelectedVideoGroupList(item));
        navigate('/videoList');
      }

      const handleError = () => {
        setImgSrc(defaultImage);
      };

//===============================================
return(
  <div className="VideoGroup">
    <S.Section>
    <Header onBackClick={() => navigate("/menu")} title="과정 리스트" />
  
    <div ref={topMenuRef}>
        <VideoGroupTopMenu />
    </div>

    <div className='box_videoGroup' style={{ height: `calc(100% - ${topMenuHeight + 80}px)` }}>
        <div className="gallery-container">
          
        

                {list.map(item => (
                    <div key={item.kwajungCode} className="gallery-item2" onClick={() => { onSelectVideoList(item) }}>
                        <div className="gallery-item">
                            <img src={`${thumbUrl}/${item.thumbnailFileName}`} className="gallery-image" 
                            onError={(e) => e.target.src = defaultImage} alt={item.title} />
                            <div className="gallery-title">
                                {/* <div className='title'>{item.kwajung}</div> */}
                                <div className='box_date'>
                                    <div> <i className="far fa-calendar-alt"></i> {item.sDate}</div>
                                    <div className='box_views'>
                                        {/* {isAdmin && <div className='update' onClick={() => onUpdate(item)}>수정<span class="short-line"></span>삭제</div>} */}
                                    </div>
                                </div>
                            </div>
                            <div className='box-title'>
                              <div className='title1'>{item.kwajung1}</div>
                              <div className='title2'>{item.kwajung2}</div>  
                            </div>
                          

                        </div>

                      

                    </div>
                ))}
          
  
                {/* {!list.length && <DisplayLogo imgLogo = {imgLogo} width="2rem" height="2rem" />}  */}
                {/*자료 없으면 로고 띄우기 */}
                <div className='global-margin-bottom-scroll'/>
        
        </div>
      </div>
      </S.Section>
    </div>


    );
  };
  

export default VideoGroup;