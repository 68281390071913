import './draw.css';
import React, { useRef, useState, useEffect } from "react";

function SvgDraw() {
  const svgRef = useRef(null);
  const [drawing, setDrawing] = useState(false);
  const [drawingMode, setDrawingMode] = useState(true);
  const [currentColor, setCurrentColor] = useState("black");
  const [pathId, setPathId] = useState(0);
  const [timeoutID, setTimeoutID] = useState(null);
  const [eraserMode, setEraserMode] = useState(false);
  const [chkNotPen, setChkNotPen] = useState(false); // chkNotPen 변수 추가
  const [scrollHiddenMode, setScrollHiddenMode] = useState(false); // scrollHiddenMode 추가

  const HOLD_TIME = 1000;
  // let startX = null;
  // let startY = null;
  const startX = useRef(null);
  const startY = useRef(null);

  const points = useRef([]);

  useEffect(() => {
    let svg = svgRef.current;
    const paths = svg.getElementsByTagName("path");

    const draw = (x, y, type) => {
      let strokeWidth = 2;
      let strokeOpacity = 1.0;

      if (eraserMode) {
        deletePath(x, y);
        return;
      }

      if (currentColor === "yellow") {
        strokeWidth = 15;
        strokeOpacity = 0.5;
      }

      if (!drawingMode || (!drawing && type !== "start")) return;

      if (type === "start") {
        // startX = x;
        // startY = y;
        startX.current = x;
        startY.current = y;

        const newPath = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "path"
        );
        newPath.setAttribute("id", `path-${pathId}`);
        newPath.setAttribute("stroke", currentColor);
        newPath.setAttribute("stroke-width", strokeWidth);
        newPath.setAttribute("stroke-opacity", strokeOpacity);
        newPath.setAttribute("fill", "none");
        newPath.setAttribute("d", `M ${x},${y}`);
        if (currentColor === "yellow") {
          newPath.style.mixBlendMode = "multiply";
        }
        svg.appendChild(newPath);
        setPathId((prevId) => prevId + 1);

      } else if (type === "move") {

 

        // points.current.push({ x, y }); // 새로운 점을 추가
        // if (points.current.length < 3) { // 최소한 3개의 점이 필요
        //   return;
        // }

        
  // // 마지막 세 개의 점을 가져옴
  // const [point1, point2, point3] = points.current.slice(-3);
  // const midPoint1 = getMidPoint(point1, point2);
  // const midPoint2 = getMidPoint(point2, point3);

  // // 부드러운 곡선을 생성
  // const curve = `M ${midPoint1.x},${midPoint1.y} Q ${point2.x},${point2.y} ${midPoint2.x},${midPoint2.y}`;
  // const path = svgRef.current.lastChild;
  // const d = path.getAttribute("d");
  // path.setAttribute("d", `${d} ${curve}`);

  // startX.current = x;
  // startY.current = y;


    const path = svg.lastChild;
    const d = path.getAttribute("d");
    // path.setAttribute("d", `${d} L ${x},${y}`);

    const midX = (startX.current + x) / 2;
    const midY = (startY.current + y) / 2;
    const curve = ` Q ${startX.current},${startY.current} ${midX},${midY}`;

    path.setAttribute("d", `${d} ${curve}`);
    startX.current = x;
    startY.current = y;

        // clearTimeout(timeoutID);
        // const newTimeoutID = setTimeout(() => {
        //   // 보정 로직
        //   const startX_min = Math.min(startX, x);
        //   const endX_max = Math.max(startX, x);
        //   const interval = 5; // 원하는 간격
        //   let d = `M ${startX},${startY}`;
        //   for (let currentX = startX_min; currentX <= endX_max; currentX += interval) {
        //     d += ` L ${currentX},${startY}`;
        //   }
        //   path.setAttribute("d", d);
        //   setDrawing(false);
        // }, HOLD_TIME);
        // setTimeoutID(newTimeoutID);
      } else if (type === "end") {
        clearTimeout(timeoutID);
      }
    };

    
// 두 점의 중간점을 계산하는 함수
const getMidPoint = (point1, point2) => {
  return {
    x: (point1.x + point2.x) / 2,
    y: (point1.y + point2.y) / 2,
  };
};

    const deletePath = (x, y) => {
      const svg = svgRef.current;
      const paths = svg.getElementsByTagName("path");

      for (const path of paths) {
        const pathData = path.getAttribute("d");
        const pathDataArray = pathData.split(" ");

        for (let i = 1; i < pathDataArray.length - 1; i++) {
          const lineData = pathDataArray[i].split(",");
          const lineX = parseFloat(lineData[0]);
          const lineY = parseFloat(lineData[1]);

          if (Math.abs(lineX - x) < 5 && Math.abs(lineY - y) < 5) {
            svg.removeChild(path);
            break;
          }
        }
      }
    };

    const handleMouseDown = (e) => {
      if (!drawingMode) return;
      setDrawing(true);
      const { x, y } = getMousePos(e);
      draw(x, y, "start");
    };

    const handleMouseMove = (e) => {
      if (!drawing || !drawingMode) return;
      const { x, y } = getMousePos(e);
      draw(x, y, "move");
    };

    const handleMouseUp = () => {
      setDrawing(false);
    };

   

    // 터치 이벤트 핸들러
    const handleTouchStart = (e) => {
        if (!e.touches || e.touches.length === 0) {
            return;
        }
        e.preventDefault(); // 터치 스타트 이벤트에서의 기본 동작 방지
        
        const radiusX = e.targetTouches[0].radiusX;
        const isChkUsePen = !chkNotPen; // chkNotPen은 어디서 정의되는 변수인지 확인 필요
  
        if (e.target.nodeName === 'INPUT') {
          return;
        }
  
        if (radiusX !== 0 && isChkUsePen) {
          if (drawingMode) {
            setDrawingMode(false);
            setScrollHiddenMode(false); // scrollHiddenMode도 React 상태로 관리 필요
            setEraserMode(!drawingMode);
            // boxSvg.style.overflow = "scroll"; // 이 부분은 React에서 스타일을 변경하는 방식으로 수정 필요
          }
        } else if (radiusX === 0 && isChkUsePen) {
          if (!drawingMode) {
            setDrawingMode(true);
            setDrawing(true);
            setEraserMode(!drawingMode);
            setScrollHiddenMode(true); // scrollHiddenMode도 React 상태로 관리 필요
            // boxSvg.style.overflow = "hidden"; // 이 부분은 React에서 스타일을 변경하는 방식으로 수정 필요
          } else {
            // e.preventDefault(); // 중복되는 e.preventDefault() 호출이 필요한지 확인하세요.
            // fncShowMsg(); // fncShowMsg 함수도 React 컴포넌트 상태를 활용하여 수정 필요
          }
        }
  
        startDrawing(e.touches[0]); // startDrawing 함수 수정 필요
      };
  
      const handleTouchMove = (e) => {
        if (drawingMode) {
          e.preventDefault();
        }
  
        const radiusX = e.targetTouches[0].radiusX;
        const isChkUsePen = !chkNotPen; // chkNotPen은 어디서 정의되는 변수인지 확인 필요
  
        if (radiusX !== 0 && !isChkUsePen) return false;
  
        const { x, y } = getMousePos(e.touches[0]);
  
        if (eraserMode) {
          deletePath(x, y);
        } else {
          draw(x, y, "move");
        }
      };
  
      const handleTouchEnd = (e) => {
        if (drawingMode) {
          e.preventDefault();
        }
  
        clearTimeout(timeoutID); // 터치를 떼는 순간에 보정 타이머를 취소
        endDrawing(); // endDrawing 함수 호출 필요
      };
  
      // startDrawing 함수 정의
const startDrawing = (event) => {
    if (!drawingMode) {
      return; // 그리기 모드가 비활성화된 경우 함수를 종료합니다.
    }
  
    setDrawing(true);
    const { x, y } = getMousePos(event);
    draw(x, y, "start");
  };
  
// endDrawing 함수 정의
const endDrawing = () => {
    setDrawing(false);
    if (!drawingMode || !drawing) {
      return;
    }
  
    // const { x, y } = getMousePos(event);
    // draw(x, y, "end");
    setDrawing(false);
  };
        
        svg.addEventListener("mousedown", handleMouseDown);
        svg.addEventListener("mousemove", handleMouseMove);
        svg.addEventListener("mouseup", handleMouseUp);
            
        svg.addEventListener("touchstart", handleTouchStart, false);
        svg.addEventListener("touchmove", handleTouchMove, false);
        svg.addEventListener("touchend", handleTouchEnd, false);
    
      return () => {
        svg.removeEventListener("mousedown", handleMouseDown);
        svg.removeEventListener("mousemove", handleMouseMove);
        svg.removeEventListener("mouseup", handleMouseUp);
  
        // 터치 이벤트 핸들러 제거
        svg.removeEventListener("touchstart", handleTouchStart);
        svg.removeEventListener("touchmove", handleTouchMove);
        svg.removeEventListener("touchend", handleTouchEnd);
      };
    }, [drawing, drawingMode, currentColor, eraserMode, pathId, timeoutID, chkNotPen, scrollHiddenMode]);
  
    const toggleDrawingMode = () => {
      setDrawingMode(true);
      setEraserMode(false); // 그림 그리기 모드로 변경할 때 지우개 모드 비활성화
    };
  
    const toggleEraserMode = () => {
      setDrawingMode(false);
      setEraserMode(true);
    };
  
    // getMousePos 함수 정의
    // const getMousePos = (svg, event) => {
    //   const rect = svg.getBoundingClientRect();
    //   const scale = 1;
    //   return {
    //     x: (event.clientX - rect.left) / scale,
    //     y: (event.clientY - rect.top) / scale,
    //   };
    // };
  
    function getMousePos(event) {
        let svg = svgRef.current;
        const rect = svg.getBoundingClientRect();
        let x, y;
    
        if (event.touches) {
            // 터치 이벤트 처리
            x = event.touches[0].clientX - rect.left;
            y = event.touches[0].clientY - rect.top;
        } else {
            // 마우스 이벤트 처리
            x = event.clientX - rect.left;
            y = event.clientY - rect.top;
        }
    
        return {
            x: x,
            y: y,
        };
    }
    
  
    return (
      <div className='body'>
        <div className='box-svg'>
          <svg
            ref={svgRef}
            style={{ border: "1px solid black", width: 800, height: 400 }}
          ></svg>
        </div>
  
        <div className='box-memu1'>
          <button onClick={toggleDrawingMode}>Draw</button>
          <button onClick={toggleEraserMode}>Eraser</button>
        </div>
      </div>
    );
  }
  
  export default SvgDraw;
  