
import  "./markingFastTable.scss"; 
import axios from "axios";

import React, { useEffect, useState, memo} from "react";
import { useLocation } from "react-router-dom";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import fnc from '../mymod/commFunction';

const svrUrl = process.env.REACT_APP_SERVER_URL;

const MarkingFastTable = (({ 
  selectedRow, 
  setSelectedRow, 
  list,  
  setList, 
  markingList, 
  setMarkingList}) => {

  const location = useLocation();
  const testCodeMun = location.state?.testCodeMun;
  const testCode = location.state?.testCode;
  const munCnt= location.state?.munCnt;
  
  // const [listTable, setListTable] = useState(null);



  useEffect(() => {
    fncSelectList();  
    // console.log(list, 'list ==========');
  
  }, []);

  function fncSelectList() {
   
    sendAxios("/markingStList", { testCodeMun, testCode }, (response) => {
      if (response) {  
        // console.log(response);
        // setListTable(response);
        setList(response);
      } else {
        console.log("속성이 obj 내에 없습니다.");
      }
    });
  }


  

function fncSelectRow(item) {
  if (!item) return false;
    setSelectedRow(item.no);
}
  
  const sendAxios = async (url, data, callback) => {
    try {
      const response = await axios.post(`${svrUrl}${url}`, data);
      let jsonObj = response.data;
      callback(jsonObj);
    } catch (error) {
      console.error("Axios 요청 중 오류가 발생했습니다:", error);
    }
  };

  
// fncHandleMarking 함수
function fncHandleMarking(no, value) {
 value = (value === 'cls') ? '' : value; // 'cls'일 때 공백으로 설정
  setMarkingList((prevMarking) => {
    const updatedMarking = { ...prevMarking, [no]: value };
    return updatedMarking; // 이 부분이 누락되어 있어서 추가했습니다.
  });
}

//========================================================================= return
return (
  <div>
    {(
      <div className="MarkingFastTable">
        <div className="wrap-list-markingSt">
          <div className="box-table">
          <table>
            <thead>
            </thead>
            <tbody>
              {list && list.map((item, index) => (
                  <tr
                
                    className={`row-list ${
                      selectedRow === item.no ? "selected activeRow" : ""
                    }`}
                    key={item.no}
                    onClick={() => fncSelectRow(item)}
                  >
                    <td className="tdNo">{item.no}</td>
                    <td className="tdMaring" style={{ textAlign: "left", paddingLeft: "0rem" }}>
                      {markingList[item.no] || ""}
                    </td>
                    {[1, 2, 3, 4, 5, 'cls'].map((number) => (
                      <td className="tdNum" key={number}> 
                          {/* <Button className="btnNum"  onClick={() => fncHandleMarking(item.no, number)} >{number} </Button>  */}
                          <button className="btnNum"  onClick={() => fncHandleMarking(item.no, number)} >{number} </button> 
                      </td>
                    ))}

                  </tr>
                ))}
            </tbody>
          </table>
          </div>
        </div>
      </div>
    )}
  </div>
);
});

export default MarkingFastTable;